import { useCallback, useEffect, useState } from 'react'
import Content from '../../components/content'
import { Dashboard } from '../../types'
import DashboardService from '../../services/dashboard.service'
import * as Icons from '@phosphor-icons/react'
import DashboardCard from '../../components/dashboard-card'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../routes/path'
import { embedDashboard } from '@superset-ui/embedded-sdk'
import Modal from '../../components/modal'
import ProtectedComponent from '../../components/protected'
import Button from '../../components/button'

const DashboardsPage = () => {
  const navigate = useNavigate()
  const { superset_id } = useParams()
  const [dashboards, setDashboards] = useState({
    isLoading: true,
    data: Array.from({ length: 6 }) as Dashboard[]
  })

  useEffect(() => {
    DashboardService.list({ page: 0, pageSize: 999, filter: '' }).then(({ data }) => {
      setDashboards({ isLoading: false, data })
    })
  }, [])

  const getGuestToken = useCallback(async () => {
    return await DashboardService.getGuestToken(superset_id || '')
  }, [superset_id])

  useEffect(() => {
    if (superset_id) {
      embedDashboard({
        id: superset_id || '',
        supersetDomain: process.env.REACT_APP_SUPERSET_URL || '',
        mountPoint: document.getElementById('superset-container') as HTMLElement,
        fetchGuestToken: () => getGuestToken(),
        dashboardUiConfig: {
          filters: {
            visible: false,
            expanded: false
          }
        }
      })
    }
  }, [getGuestToken, superset_id])

  return (
    <Content title={'Dashboards'}>
      <div className='grid w-full grid-cols-2 gap-6'>
        {dashboards.data.map((dashboard, index) => {
          const Icon = Icons[dashboard?.icon as keyof typeof Icons] as React.FC<Icons.IconProps>

          return (
            <ProtectedComponent key={index} permission={dashboard?.permission}>
              <DashboardCard
                item={{ ...dashboard, Icon: Icon || <div /> }}
                onClick={() =>
                  navigate(ROUTES.dashboards.detail.replace(':superset_id', dashboard.superset_id))
                }
              />
            </ProtectedComponent>
          )
        })}
      </div>

      <Modal isOpen={!!superset_id} onClose={() => navigate(ROUTES.dashboards.list)}>
        <div
          id={'superset-container'}
          style={{ width: '90vw', height: '75vh', backgroundColor: '#F5F5F5' }}
        />

        <div className='mt-4 flex w-full justify-end gap-1'>
          <Button
            onClick={() => navigate(ROUTES.dashboards.list)}
            label='Fechar'
            variant='primary'
          />
        </div>
      </Modal>
    </Content>
  )
}

export default DashboardsPage
