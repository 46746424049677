/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import AnamnesisService from '../../../../services/anamnesis.service'
import AnamnesisQuestionsSpecialty from '../../anamnesis/specialty'
import AnamnesisQuestionsDefault from '../../anamnesis/default'
import Tag from '../../../../components/tag'
import debounce from 'lodash.debounce'
import { useGetAnamnesisTemplateByConsultation } from '../../../../hooks/useAnamnesis'
import { useAnamnesis } from '../../../../contexts/anamnesis'
import Button from '../../../../components/button'
import { Check, PencilSimple } from '@phosphor-icons/react'

export const AnamneseTemplate = ({ id, is_finished }: { id: string; is_finished?: boolean }) => {
  const { t } = useTranslation('consultation_details')
  const [forceEdit, setForceEdit] = useState(false)
  const [selectedType, setSelectedType] = useState<'default' | 'specialty'>('default')
  const { defaultValues, setDefaultValues, specialtyValues, setSpecialtyValues } = useAnamnesis()

  const { data: template } = useGetAnamnesisTemplateByConsultation(id)

  const handleSave = async (updatedValues: any, type: 'default' | 'specialty') => {
    try {
      if (Object.keys(updatedValues).length > 0) {
        await AnamnesisService.save({
          consultation_id: id,
          default_answers: type === 'default' ? updatedValues : defaultValues,
          specialty_answers: type === 'specialty' ? updatedValues : specialtyValues
        })
      }
    } catch (err) {
      console.error(err)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSave = useCallback(
    debounce((updatedValues: any, type: 'default' | 'specialty') => {
      handleSave(updatedValues, type)
    }, 500),
    [defaultValues, specialtyValues, id]
  )

  useEffect(() => {
    if (selectedType === 'default') {
      debounceSave(defaultValues, 'default')
    }
  }, [defaultValues, debounceSave, selectedType])

  useEffect(() => {
    if (selectedType === 'specialty') {
      debounceSave(specialtyValues, 'specialty')
    }
  }, [specialtyValues, debounceSave, selectedType])

  const handlePress = (type: 'default' | 'specialty') => {
    setSelectedType(type)
  }

  const renderContent = () => {
    if (!!template && selectedType === 'specialty') {
      return (
        <AnamnesisQuestionsSpecialty
          id={id}
          template={template}
          specialtyValues={specialtyValues}
          readonly={is_finished && !forceEdit}
          setSpecialtyValues={setSpecialtyValues}
          specialty_name={template.specialty?.name || ''}
        />
      )
    } else {
      return (
        <AnamnesisQuestionsDefault
          id={id}
          defaultValues={defaultValues}
          setDefaultValues={setDefaultValues}
          readonly={is_finished && !forceEdit}
        />
      )
    }
  }

  return (
    <>
      <div className='col-span-2 mb-3 flex items-center gap-2'>
        <Tag
          label={t('anamnese_tab.default')}
          color={selectedType === 'default' ? 'blue' : 'neutral'}
          size='md'
          variant='secondary'
          className='cursor-pointer hover:opacity-60'
          onClick={() => handlePress('default')}
        />
        {!!template && (
          <Tag
            label={t('anamnese_tab.specialty')}
            color={selectedType === 'specialty' ? 'blue' : 'neutral'}
            size='md'
            className='cursor-pointer hover:opacity-60'
            variant='secondary'
            onClick={() => handlePress('specialty')}
          />
        )}
        {is_finished && (
          <>
            {!forceEdit ? (
              <Button
                label='Editar'
                className='ml-auto'
                variant='secondary'
                iconLeft={<PencilSimple size={16} />}
                onClick={() => setForceEdit(true)}
              />
            ) : (
              <Button
                label='Finalizar'
                className='ml-auto'
                iconLeft={<Check size={16} />}
                onClick={() => setForceEdit(false)}
              />
            )}
          </>
        )}
      </div>

      {renderContent()}
    </>
  )
}
