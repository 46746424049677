import { Scale, ScaleForm } from '../types'
import api from './api.service'

export type ListScaleParams = {
  date?: string
  client_id?: string
  end_date?: string
  start_date?: string
}

class ScaleService {
  static async listScales({ client_id, ...params }: ListScaleParams) {
    return (await api.get<Scale[]>('/scale/client/' + client_id, { params })).data
  }

  static async listScalesFromUnitAndSpecialty(unit_id: string, specialty_id: string) {
    const response = await api.get<Scale[]>(`/scale/${unit_id}/${specialty_id}`)
    return response.data
  }

  static async getById(id: string) {
    const response = await api.get<Scale>(`/scale/${id}`)
    return response.data
  }

  static async create(agenda: ScaleForm) {
    const response = await api.post<Scale>('/scale', agenda)
    return response.data
  }

  static async update(id: string, agenda: ScaleForm) {
    const response = await api.put<Scale>(`/scale/${id}`, agenda)
    return response.data
  }

  static async publish(id: string) {
    const response = await api.put<Scale>(`/scale/${id}/publish`)
    return response.data
  }

  static delete(id: string) {
    return api.delete<void>('/scale/' + id)
  }

  static async publishMultiple(ids: string[]) {
    const response = await api.put<Scale[]>('/scales/publish', { ids })
    return response.data
  }
}

export default ScaleService
