import InputField from '../../../../../components/input'
import Radio from '../../../../../components/radio'
import { AnyQuestionProps } from '../any-questions'

const DURATION_TYPE = {
  day: 'dia(s)',
  month: 'mês(es)',
  year: 'ano(s)'
}

export const DurationQuestion: React.FC<AnyQuestionProps> = ({
  handleValue,
  question,
  values,
  readonly
}) => {
  const handleDurationType = (value: 'day' | 'month' | 'year') => {
    handleValue({
      ...values,
      [`${question.id!}_duration_type`]: value
    })
  }

  const handleDurationValue = (value: string) => {
    handleValue({
      ...values,
      [`${question.id!}_duration_value`]: value
    })
  }

  if (readonly) {
    return (
      <>
        {values[question.id! + '_duration_value']
          ? `${values[question.id! + '_duration_value']} ${DURATION_TYPE[values[question.id! + '_duration_type'] as 'day' | 'month' | 'year']}`
          : '-'}
      </>
    )
  }

  return (
    <div className='flex items-center gap-4'>
      <div className='w-20'>
        <InputField
          type='number'
          maxLength={4}
          onChange={e => handleDurationValue(e.target.value)}
          value={(values[question.id! + '_duration_value'] as string) || ''}
        />
      </div>

      <div className='flex flex-1 items-center gap-4'>
        <div className='mt-2 flex items-center gap-2 space-x-2'>
          <Radio
            size='md'
            state='enabled'
            onChange={() => handleDurationType('day')}
            selected={values[`${question.id!}_duration_type`] === 'day'}
          />
          dia(s)
        </div>
        <div className='mt-2 flex items-center gap-2 space-x-2'>
          <Radio
            size='md'
            state='enabled'
            onChange={() => handleDurationType('month')}
            selected={values[`${question.id!}_duration_type`] === 'month'}
          />
          mês(es)
        </div>
        <div className='mt-2 flex items-center gap-2 space-x-2'>
          <Radio
            size='md'
            state='enabled'
            onChange={() => handleDurationType('year')}
            selected={values[`${question.id!}_duration_type`] === 'year'}
          />
          ano(s)
        </div>
      </div>
    </div>
  )
}
