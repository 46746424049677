import { WarningCircle } from '@phosphor-icons/react'
import React, { FocusEvent, useEffect, useMemo, useRef } from 'react'
import Select, { SelectInstance } from 'react-select'
import { getDropdownStyles } from './styles'

export type DropdownOption = { value: string; label: string }

type DropdownProps = {
  name: string
  label?: string
  hint?: string
  value: string | number
  error?: boolean
  disabled?: boolean
  placeholder?: string
  options: DropdownOption[]
  loadMoreOptions?: () => void
  onChangeValue: (value: DropdownOption | null) => void
  onBlur?: (e: FocusEvent<unknown, Element>) => void
  onFocus?: (e: FocusEvent<unknown, Element>) => void
  labelClassName?: string
  className?: string
  onInputChange?: (inputValue: string) => void
  noOptionsMessage?: () => string
  onMenuOpen?: () => void
}

const Dropdown: React.FC<DropdownProps> = ({
  hint,
  name,
  value,
  error,
  label,
  options,
  className,
  onChangeValue,
  labelClassName,
  loadMoreOptions,
  disabled = false,
  placeholder = 'Selecione uma opção',
  onInputChange,
  noOptionsMessage,
  onMenuOpen,
  onBlur,
  onFocus,
  ...rest
}) => {
  const ref = useRef<SelectInstance<DropdownOption>>(null)

  const handleChange = (selectedOption: DropdownOption | null) => {
    if (options.length > 0) onChangeValue(selectedOption)
  }

  const currentOption = useMemo(
    () => options.find(option => option?.value?.toString() === value?.toString()) || null,
    [options, value]
  )

  useEffect(() => {
    if (value && !currentOption) {
      ref.current?.clearValue()
    }
  }, [value, currentOption])

  return (
    <div className={`flex w-full flex-1 flex-col gap-2  ${className}`}>
      {label && (
        <label
          htmlFor={name}
          className={`label-md ${disabled ? 'text-neutralContent-disabled' : 'text-neutralContent-primary'} ${labelClassName}`}
        >
          {label}
        </label>
      )}
      <Select
        {...rest}
        ref={ref}
        id={name}
        inputId={name}
        name={name}
        isClearable
        isSearchable
        options={options}
        value={currentOption}
        isDisabled={disabled}
        onChange={handleChange}
        placeholder={placeholder}
        onMenuScrollToBottom={loadMoreOptions}
        styles={getDropdownStyles(disabled, error)}
        noOptionsMessage={() => noOptionsMessage?.() || 'Nenhuma opção encontrada'}
        onMenuOpen={() => onMenuOpen?.()}
        onInputChange={(inputValue, { action }) => {
          if (action === 'input-change' && onInputChange) {
            onInputChange(inputValue)
          }
        }}
        classNames={{
          input: () => 'p-0 m-0',
          valueContainer: () => 'py-0',
          clearIndicator: () => 'py-[2px] cursor-pointer',
          dropdownIndicator: () => 'py-[2px] cursor-pointer'
        }}
        onBlur={onBlur}
        onFocus={onFocus}
      />

      {hint && error && (
        <div
          className={`body-sm flex items-center ${hint && error ? 'text-highlightRed-pure' : 'text-neutralContent-secondary'}`}
        >
          <WarningCircle size={16} />
          <span className='ml-2'>{hint}</span>
        </div>
      )}
    </div>
  )
}

export default Dropdown
