import { format } from 'date-fns'
import DatePicker from '../../../../../components/datepicker'
import { AnyQuestionProps } from '../any-questions'

export const DateQuestion: React.FC<AnyQuestionProps> = ({
  handleValue,
  question,
  values,
  readonly
}) => {
  if (readonly) {
    return (
      <>
        {values[question.id!]
          ? format(new Date(values[question.id!] as string), 'dd/MM/yyyy')
          : '-'}
      </>
    )
  }

  return (
    <div className='mt-2 w-[300px]'>
      <DatePicker
        name={question.id!}
        placeholder='Selecione uma data'
        value={values[question.id!] ? new Date(values[question.id!] as string) : null}
        onChange={date => handleValue({ ...values, [question.id!]: date?.toISOString() })}
      />
    </div>
  )
}
