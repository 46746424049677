import { useAuth } from '../../contexts/auth'
import { useListClients } from '../../hooks/useClient'
import { Client } from '../../types'
import PermissionEnum from '../../types/permissions'
import Avatar from '../avatar'
import Checkbox from '../checkbox'
import Radio from '../radio'

type Props = {
  multiple?: boolean
  selectedClients: Client[]
  handleSelectedClients: (clients: Client[]) => void
}

const ClientList: React.FC<Props> = ({ multiple, handleSelectedClients, selectedClients }) => {
  const { hasAccess, user } = useAuth()

  const {
    data: clients,
    isLoading,
    isFetching
  } = useListClients(
    { filter: '', page: 0, pageSize: 100 },
    { getFromUser: !hasAccess(PermissionEnum.list_clients), user }
  )

  const handleSelect = (toSelect: boolean, client: Client) => {
    if (toSelect) {
      return handleSelectedClients(multiple ? [...selectedClients, client] : [client])
    }

    return handleSelectedClients(
      multiple ? selectedClients.filter(item => item.id !== client.id) : []
    )
  }

  const handleSelectAll = (toSelect: boolean) => {
    if (!clients?.data) return

    if (toSelect) {
      const newClients = clients.data.filter(
        client => !selectedClients.some(selected => selected.id === client.id)
      )
      handleSelectedClients([...selectedClients, ...newClients])
    } else {
      handleSelectedClients([])
    }
  }

  const areAllSelected = clients?.data?.every(client =>
    selectedClients.some(selected => selected.id === client.id)
  )

  const isIndeterminate =
    !areAllSelected &&
    clients?.data?.some(client => selectedClients.some(selected => selected.id === client.id))

  return (
    <div className='flex flex-col gap-2'>
      {isLoading || isFetching ? (
        <Skeleton />
      ) : (
        <>
          {multiple && (
            <div className='flex items-center justify-between py-4'>
              <span className='text-label-md'>Selecionar todos</span>
              <Checkbox
                isChecked={Boolean(areAllSelected)}
                isIndeterminate={isIndeterminate}
                onClick={() => handleSelectAll(!areAllSelected)}
              />
            </div>
          )}
          {clients?.data?.map(client => (
            <div className='flex w-full items-center py-4' key={client.id}>
              <Avatar client={client} className='mr-4' size='sm' />
              <div className='mr-auto text-label-md text-neutralContent-primary'>{client.name}</div>
              {multiple ? (
                <Checkbox
                  isChecked={selectedClients.some(item => item.id === client.id)}
                  onClick={toSelect => handleSelect(toSelect, client)}
                />
              ) : (
                <Radio
                  size='md'
                  state='enabled'
                  selected={selectedClients.some(item => item.id === client.id)}
                  onChange={() =>
                    handleSelect(!selectedClients.some(item => item.id === client.id), client)
                  }
                />
              )}
            </div>
          ))}
        </>
      )}
    </div>
  )
}

const Skeleton: React.FC = () => {
  return (
    <div className='flex w-full flex-col'>
      {Array.from({ length: 6 }, (_, index_row) => (
        <div key={index_row} className='flex w-full items-center py-4'>
          <div className='mr-4 h-9 w-9 animate-pulse rounded-full bg-gray-200' />
          <div className='mr-auto h-5 w-36 animate-pulse bg-gray-200' />
          <div className='h-5 w-5 animate-pulse rounded-md bg-gray-200' />
        </div>
      ))}
    </div>
  )
}

export default ClientList
