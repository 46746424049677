import Dropdown, { DropdownOption } from '../../../../../components/dropdown'
import { AnyQuestionProps } from '../any-questions'

export const DropdownQuestion: React.FC<AnyQuestionProps> = ({ handleValue, question, values }) => {
  const handleDropdownChange = (option: DropdownOption | null) => {
    handleValue({
      ...values,
      [`${question.id!}_dropdown`]: option?.value
    })
  }

  return (
    <Dropdown
      name={question.id || ''}
      onChangeValue={handleDropdownChange}
      options={question.options?.map(item => ({ label: item, value: item })) || []}
      value={
        values[`${question.id!}_dropdown`] ? (values[`${question.id!}_dropdown`] as string) : ''
      }
    />
  )
}
