import { AnyQuestionProps } from '../any-questions'
import Radio from '../../../../../components/radio'

export const YesNoQuestion: React.FC<AnyQuestionProps> = ({
  handleValue,
  question,
  values,
  readonly
}) => {
  const handleYesNoChange = (value: 'yes' | 'no' | undefined) => {
    handleValue({
      ...values,
      [`${question.id!}_yes_no`]: value
    })
  }

  if (readonly) {
    return <>{values[`${question.id!}_yes_no`] === 'yes' ? 'Sim' : 'Não'}</>
  }

  return (
    <div className='mt-2 flex flex-wrap items-center gap-2'>
      <div className='mt-2 flex items-center gap-2 space-x-2'>
        <Radio
          size='md'
          state='enabled'
          selected={values[`${question.id!}_yes_no`] === 'yes'}
          onChange={() => handleYesNoChange('yes')}
        />
        Sim
      </div>
      <div className='mt-2 flex items-center gap-2 space-x-2'>
        <Radio
          size='md'
          state='enabled'
          selected={values[`${question.id!}_yes_no`] === 'no'}
          onChange={() => handleYesNoChange('no')}
        />
        Não
      </div>
    </div>
  )
}
