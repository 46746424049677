/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import { useGetAnamnesisByConsultation } from '../../../../hooks/useAnamnesis'
import BlankCard from '../../../../components/blank-card'
import { AnamnesisTemplate } from '../../../../types'
import AnyQuestion from '../default/any-questions'

const AnamnesisQuestionsSpecialty: React.FC<{
  specialty_name: string
  template: AnamnesisTemplate
  id: string
  specialtyValues: any
  readonly?: boolean
  setSpecialtyValues: (values: any) => void
}> = ({ template, id, specialtyValues, readonly, specialty_name, setSpecialtyValues }) => {
  const { data: answers, isFetching: isLoadingAnswers } = useGetAnamnesisByConsultation(id)

  useEffect(() => {
    setSpecialtyValues(answers?.specialty_answers || {})
  }, [answers?.specialty_answers, setSpecialtyValues])

  return (
    <>
      {isLoadingAnswers ? (
        <span>Carregando</span>
      ) : (
        <BlankCard className='mb-4'>
          <span className='block text-label-lg font-semibold'>{specialty_name}</span>
          {template.questions?.map(question => (
            <AnyQuestion
              key={question.id}
              question={question}
              readonly={readonly}
              values={specialtyValues}
              handleValue={setSpecialtyValues}
            />
          ))}
        </BlankCard>
      )}
    </>
  )
}

export default AnamnesisQuestionsSpecialty
