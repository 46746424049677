import React, { FocusEvent } from 'react'
import { WarningCircle } from '@phosphor-icons/react'
import Dropdown, { DropdownOption } from '.'
import { useTranslation } from 'react-i18next'
import { ClosureReason } from '../../types'

type Props = {
  name: string
  label: string
  value: string
  error?: boolean
  disabled?: boolean
  placeholder?: string
  hint?: string
  onChangeValue: (value: string) => void
  onBlur?: (e: FocusEvent<unknown, Element>) => void
}

export const useClosureReasonOptions = (): DropdownOption[] => {
  const { t } = useTranslation('consultation_details')

  return Object.values(ClosureReason).map(reason => ({
    label: t(`closure_reason.${reason.toLowerCase()}`),
    value: reason
  }))
}

const ClosureReasonConsultation: React.FC<Props> = ({
  hint,
  name,
  label,
  value,
  error,
  disabled,
  onBlur,
  onChangeValue,
  ...rest
}) => {
  const options = useClosureReasonOptions()

  const handleDropdownChange = (option: DropdownOption | null) => {
    if (option) {
      onChangeValue(option.value)
    } else {
      onChangeValue(ClosureReason.REALIZED)
    }
  }

  return (
    <div className='flex flex-col gap-2'>
      <label
        htmlFor={name}
        className={`label-md mb-2 block ${disabled ? 'text-neutralContent-disabled' : 'text-neutralContent-primary'}`}
      >
        {label}
      </label>

      <Dropdown
        label=''
        name={name}
        value={value}
        error={error}
        disabled={disabled}
        placeholder={''}
        labelClassName='mb-[-12px]'
        options={options}
        onChangeValue={handleDropdownChange}
        {...rest}
      />

      {hint && error && (
        <div
          className={`body-sm flex items-center ${error ? 'text-highlightRed-pure' : 'text-neutralContent-secondary'}`}
        >
          {!!error && <WarningCircle size={16} />}
          <span className='ml-2'>{hint}</span>
        </div>
      )}
    </div>
  )
}

export default ClosureReasonConsultation
