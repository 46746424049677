const agendav2_list_ptBR = {
  title: 'Agendas',

  search_placeholder: 'Buscar por um cliente',
  empty_scale: 'Nenhuma escala cadastrada para este dia',

  without_specialist: 'Sem especialista definido',
  without_units: 'Sem unidades definida',
  new_scale: 'Nova escala',
  select_a_specialty: 'Selecione uma especialidade',
  start: 'Início',
  end: 'Término',
  status: {
    DRAFT: 'Rascunho',
    PUBLISHED: 'Publicada'
  },
  has_specialist: 'Possui especialista',
  specialist_pending: 'Especialista pendente',
  actions: {
    access_full_scale: 'Acessar escala completa',
    new_scale: 'Nova escala',
    update_scale: 'Salvar',
    select: 'Selecionar',
    create_scale: 'Criar escala',
    close: 'Fechar',
    update: 'Editar',
    delete: 'Excluir',
    publish_scales: 'Publicar escalas'
  }
}

export default agendav2_list_ptBR
