export const allergy = {
  'Alergias Alimentares': [
    'Leite e derivados',
    'Ovos',
    'Amendoim',
    'Nozes (castanhas, avelãs, nozes, etc.)',
    'Peixes e frutos do mar (camarão, lagosta, caranguejo)',
    'Soja e derivados',
    'Trigo e glúten',
    'Outras Alergias Alimentares'
  ],
  'Alergias a Medicamentos': [
    'Penicilina e outros antibióticos (amoxicilina, cefalosporinas)',
    'AINEs (anti-inflamatórios não esteroidais), como ibuprofeno e naproxeno',
    'Aspirina (ácido acetilsalicílico)',
    'Sulfonamidas',
    'Anticonvulsivantes (por exemplo, carbamazepina)',
    'Vacinas (componentes específicos, como gelatina ou látex em tampas de frascos)',
    'Anestésicos locais (lidocaína, por exemplo)',
    'Opioides (morfina, codeína)',
    'Outras Alergias a Medicamentos'
  ],
  'Alergias a Substâncias Químicas e Produtos de Uso Comum': [
    'Látex (comum em luvas, preservativos)',
    'Níquel (bijuterias, alguns utensílios)',
    'Conservantes (como parabenos em cosméticos e produtos de higiene)',
    'Fragâncias artificiais',
    'Corantes (amarelo tartrazina, carmim)',
    'Metais pesados (mercúrio, chumbo)',
    'Outras Alergias a Substâncias Químicas e Produtos de Uso Comum'
  ],
  'Alergias Respiratórias e Ambientais': [
    'Pólen (gramíneas, árvores)',
    'Poeira e ácaros',
    'Mofo',
    'Pelo de animais (cães, gatos)',
    'Baratas',
    'Fumaça de cigarro',
    'Outras Alergias Respiratórias e Ambientais'
  ],
  'Alergias a Picadas de Insetos': [
    'Picadas de abelhas',
    'Picadas de vespas',
    'Picadas de formigas',
    'Picadas de mosquitos (reação alérgica a proteínas na saliva)',
    'Outras Alergias a Picadas de Insetos'
  ],
  'Alergias a Alérgenos Cutâneos e Dermatológicos': [
    'Dermografismo (hipersensibilidade à pressão na pele)',
    'Fotossensibilidade (reação à luz solar ou luz ultravioleta)',
    'Dermatite de contato',
    'Outras Alergias a Alérgenos Cutâneos e Dermatológicos'
  ]
}

export const chronicDiseases = {
  'Doenças Cardiovasculares': [
    'Hipertensão arterial (pressão alta)',
    'Insuficiência cardíaca',
    'Doença arterial coronariana (DAC)',
    'Arritmia cardíaca',
    'Cardiomiopatia',
    'Doença vascular periférica',
    'Outras Doenças Cardiovasculares'
  ],
  'Doenças Respiratórias': [
    'Asma',
    'Doença pulmonar obstrutiva crônica (DPOC)',
    'Bronquite crônica',
    'Fibrose cística',
    'Apneia do sono',
    'Fibrose pulmonar',
    'Outras Doenças Respiratórias'
  ],
  'Doenças Endócrinas e Metabólicas': [
    'Diabetes tipo 1 e tipo 2',
    'Hipotireoidismo',
    'Hipertireoidismo',
    'Síndrome metabólica',
    'Obesidade',
    'Osteoporose',
    'Outras Doenças Endócrinas e Metabólicas'
  ],
  'Doenças Renais': [
    'Insuficiência renal crônica',
    'Nefropatia diabética',
    'Doença renal policística',
    'Glomerulonefrite crônica',
    'Litíase renal crônica (pedras nos rins recorrentes)',
    'Outras Doenças Renais'
  ],
  'Doenças Gastrointestinais e Hepáticas': [
    'Doença de Crohn',
    'Retocolite ulcerativa',
    'Hepatite crônica (B ou C)',
    'Cirrose hepática',
    'Síndrome do intestino irritável (SII)',
    'Gastrite crônica',
    'Outras Doenças Gastrointestinais e Hepáticas'
  ],
  'Doenças Neurológicas e Neurodegenerativas': [
    'Epilepsia',
    'Doença de Parkinson',
    'Doença de Alzheimer',
    'Esclerose múltipla',
    'Esclerose lateral amiotrófica (ELA)',
    'Enxaqueca crônica',
    'Outras Doenças Neurológicas e Neurodegenerativas'
  ],
  'Doenças Autoimunes': [
    'Lúpus eritematoso sistêmico',
    'Artrite reumatoide',
    'Psoríase',
    'Doença celíaca',
    'Síndrome de Sjögren',
    'Outras Doenças Autoimunes'
  ],
  'Doenças Musculoesqueléticas e Reumatológicas': [
    'Osteoartrite',
    'Gota',
    'Fibromialgia',
    'Espondilite anquilosante',
    'Lumbalgia crônica',
    'Distrofia muscular',
    'Outras Doenças Musculoesqueléticas e Reumatológicas'
  ],
  'Doenças Psiquiátricas e Transtornos Mentais': [
    'Depressão',
    'Transtorno bipolar',
    'Esquizofrenia',
    'Transtorno de ansiedade generalizada',
    'Transtorno obsessivo-compulsivo (TOC)',
    'Transtorno de estresse pós-traumático (TEPT)',
    'Outras Doenças Psiquiátricas e Transtornos Mentais'
  ]
}

export const surgicalHistory = {
  Abdominais: [
    'Apendicectomia',
    'Colecistectomia',
    'Hernioplastia',
    'Cirurgia bariátrica',
    'Cirurgia para úlcera péptica',
    'Outras Abdominais'
  ],
  Ortopédicas: [
    'Artroscopia',
    'Prótese de quadril ou joelho',
    'Fixação de fraturas',
    'Correção de escoliose',
    'Cirurgia para hérnia de disco',
    'Outras Ortopédicas'
  ],
  'Cardiovasculares e Torácicas': [
    'Ponte de safena',
    'Colocação de marca-passo',
    'Cateterismo cardíaco',
    'Lobectomia pulmonar',
    'Valvuloplastia',
    'Outras Cardiovasculares e Torácicas'
  ],
  'Ginecológicas e Urológicas': [
    'Histerectomia',
    'Laqueadura',
    'Prostatectomia',
    'Vasectomia',
    'Cirurgia para cálculo renal',
    'Outras Ginecológicas e Urológicas'
  ],
  'Oftalmológicas e Otorrinolaringológicas': [
    'Cirurgia de catarata',
    'Cirurgia refrativa',
    'Septoplastia',
    'Amigdalectomia',
    'Implante coclear',
    'Outras Oftalmológicas e Otorrinolaringológicas'
  ],
  'Dermatológicas e Plásticas': [
    'Remoção de lesão cutânea',
    'Cirurgia para melanoma',
    'Rinoplastia',
    'Mamoplastia',
    'Abdominoplastia',
    'Outras Dermatológicas e Plásticas'
  ],
  Neurocirúrgicas: [
    'Cirurgia para aneurisma cerebral',
    'Ressecção de tumor cerebral',
    'Colocação de derivação ventricular',
    'Cirurgia de descompressão para nervos',
    'Microcirurgia para epilepsia',
    'Outras Neurocirúrgicas'
  ]
}

export const healthConditions = {
  'Metabólicas e Hormonais': [
    'Hiperlipidemia',
    'Resistência à insulina',
    'Hiperuricemia',
    'SOP',
    'Deficiência de vitamina D',
    'Outras Metabólicas e Hormonais'
  ],
  Respiratórias: [
    'Rinite alérgica',
    'Sinusite crônica',
    'Tuberculose latente',
    'Bronquiectasia',
    'Apneia obstrutiva do sono leve',
    'Outras Respiratórias'
  ],
  Gastrointestinais: [
    'Gastrite',
    'DRGE',
    'Intolerância à lactose',
    'Doença celíaca',
    'SII',
    'Outras Gastrointestinais'
  ],
  'Neurológicas e Psiquiátricas': [
    'TDAH',
    'Transtornos de ansiedade leves',
    'Depressão leve a moderada',
    'Insônia crônica',
    'Síndrome das pernas inquietas',
    'Outras Neurológicas e Psiquiátricas'
  ],
  Musculoesqueléticas: [
    'Bursite',
    'Tendinite',
    'Hérnia de disco',
    'Osteoartrose em estágio inicial',
    'Síndrome do túnel do carpo',
    'Outras Musculoesqueléticas'
  ],
  Dermatológicas: [
    'Acne crônica',
    'Dermatite atópica',
    'Rosácea',
    'Urticária crônica',
    'Vitiligo',
    'Outras Dermatológicas'
  ],
  'Oftalmológicas e Otorrinolaringológicas': [
    'Miopia',
    'Hipermetropia',
    'Glaucoma em estágio inicial',
    'Tontura crônica',
    'Labirintite',
    'Outras Oftalmológicas e Otorrinolaringológicas'
  ]
}
