/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react'
import Calendar from 'react-calendar'
import { CalendarDots, WarningCircle, X } from '@phosphor-icons/react'
import { Value } from 'react-calendar/dist/cjs/shared/types'
import { format, isValid, parse } from 'date-fns'
import './theme.css'
import { useToastContext } from '../../contexts/toast'

interface Props {
  name: string
  label?: string
  error?: string
  touched?: boolean
  value: Date | null
  disabled?: boolean
  className?: string
  placeholder?: string
  onChange: (val: Date | null) => void
  onBlur?: (e: React.FocusEvent<any>) => void
  limit?: { minDate?: Date; maxDate?: Date }
  showClearIcon?: boolean
}

const maskDate = (value: string): string => {
  const v = value.replace(/\D/g, '').slice(0, 8)
  if (v.length >= 5) {
    return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`
  } else if (v.length >= 3) {
    return `${v.slice(0, 2)}/${v.slice(2)}`
  }
  return v
}

const validateDate = (dateStr: string): boolean => {
  const [day, month, year] = dateStr.split('/').map(Number)

  if (!day || !month || !year) return false

  const date = parse(dateStr, 'dd/MM/yyyy', new Date())

  return (
    isValid(date) &&
    date.getDate() === day &&
    date.getMonth() + 1 === month &&
    date.getFullYear() === year
  )
}

const DatePickerWithCalendar: React.FC<Props> = ({
  name,
  label,
  error,
  value,
  touched,
  disabled,
  className,
  placeholder,
  onChange,
  onBlur,
  limit,
  showClearIcon = true
}) => {
  const [distances, setDistances] = useState({ left: 0, right: 0 })
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const [text, setText] = useState<string>()
  const ref = useRef<HTMLInputElement>(null)

  const { toast } = useToastContext()

  const handleDateChange = (newDate: Value) => {
    if (newDate === null) return onChange(null)

    const selectedDate = Array.isArray(newDate) ? newDate[0] : newDate

    if (selectedDate instanceof Date) {
      if (limit?.minDate && selectedDate < limit.minDate) {
        toast.error('A data não pode ser anterior ao limite mínimo')
        return
      }

      if (limit?.maxDate && selectedDate > limit.maxDate) {
        toast.error('A data não pode ser no futuro')
        return
      }

      onChange(selectedDate)
    }
  }

  const openCalendar = () => {
    setShowCalendar(true)
  }

  const closeCalendar = () => {
    setShowCalendar(false)
  }

  const formatDate = (date: Date | null): string => {
    return date ? format(date, 'dd/MM/yyyy') : ''
  }

  const handleInputBlur = (e: React.FocusEvent<any>) => {
    if (text && validateDate(text)) {
      handleDateChange(parse(text, 'dd/MM/yyyy', new Date()))
    }

    onBlur?.(e)
    setText(undefined)

    if (!e.relatedTarget || !e.relatedTarget?.classList.toString().includes('react-calendar')) {
      closeCalendar()
    } else {
      ref.current?.focus()
    }
  }

  const onType = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 10 && validateDate(e.target.value)) {
      handleDateChange(parse(e.target.value, 'dd/MM/yyyy', new Date()))
      setText(undefined)
    } else {
      setText(maskDate(e.target.value))
    }
  }

  const getBorderClasses = () => {
    if (disabled) return 'border-2 border-neutralBackground-disabled bg-neutralBackground-disabled'

    if (touched && error) return 'border-2 border-highlightRed-pure'

    return 'border-2 border-neutralBorder-default focus-within:border-2 focus-within:border-primaryBrand-dark'
  }

  const getTextClasses = () => {
    if (disabled) return 'text-neutralContent-disabled'
    return 'text-neutralContent-primary'
  }

  const getPlaceholderClasses = () => {
    if (disabled) return 'text-neutralContent-disabled'
    return 'text-neutralContent-quartenary'
  }

  const calculateDistances = () => {
    if (ref.current) {
      const element = ref.current
      const rect = element.getBoundingClientRect()

      const distanceFromLeft = rect.left
      const distanceFromRight = window.innerWidth - rect.right

      setDistances({ left: distanceFromLeft, right: distanceFromRight })
    }
  }

  useEffect(() => {
    calculateDistances()

    window.addEventListener('resize', calculateDistances)

    return () => {
      window.removeEventListener('resize', calculateDistances)
    }
  }, [])

  return (
    <div className={`flex w-full flex-1 flex-col gap-2 ${className}`}>
      {label && (
        <label htmlFor={name} className='label-md text-neutralContent-primary'>
          {label}
        </label>
      )}
      <div
        className={`relative flex h-12 items-center gap-[16px] rounded-[12px] bg-white px-[12px] py-[16px] ${getBorderClasses()}`}
      >
        <CalendarDots size={20} className='text-neutralContent-primary' />
        <input
          ref={ref}
          id={name}
          name={name}
          type='text'
          onChange={onType}
          onFocus={openCalendar}
          onBlur={handleInputBlur}
          placeholder={placeholder}
          className={`body-md flex-grow bg-transparent ${value ? getTextClasses() : getPlaceholderClasses()} ${
            !disabled && 'focus:outline-none'
          }`}
          value={text !== undefined ? text : formatDate(value)}
        />
        {showCalendar && (
          <div
            className={`absolute top-[calc(100%+4px)] z-10 ${distances.left > distances.right ? 'right-0' : 'left-0'}`}
          >
            <Calendar
              onChange={handleDateChange}
              value={value}
              locale='pt-BR'
              minDate={limit?.minDate}
              maxDate={limit?.maxDate}
            />
          </div>
        )}
        {value && showClearIcon && (
          <button
            type='button'
            onClick={() => handleDateChange(null)}
            className='absolute  right-2 top-3 cursor-pointer rounded-full border-[1px] p-1 transition-all hover:opacity-50'
          >
            <X size={12} />
          </button>
        )}
      </div>
      {touched && error && (
        <div className='mt-1 flex items-center text-sm text-highlightRed-pure'>
          <WarningCircle size={16} />
          <span className='ml-2'>{error}</span>
        </div>
      )}
    </div>
  )
}

export default DatePickerWithCalendar
