import { useToastContext } from '../../contexts/toast'
import { validateCNPJ } from '../../helpers/validadeCNPJ'
import { validateCPF } from '../../helpers/validateCPF'
import { Client } from '../../types'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import * as Yup from 'yup'

export const useClientGeneralInformationFormik = (
  onSubmit: (values: Partial<Client>) => void,
  initialValues?: Partial<Client>
) => {
  const validationSchema = Yup.object({
    name: Yup.string().required('O nome é obrigatório'),
    type: Yup.string()
      .oneOf(['PF', 'PJ'], 'Selecione um tipo válido')
      .required('O tipo é obrigatório'),
    cpf: Yup.string().when('type', {
      is: 'PF',
      then: schema =>
        schema
          .required('CPF é obrigatório')
          .test('is-valid-cpf', 'CPF inválido', value => validateCPF(value)),
      otherwise: schema => schema.optional()
    }),
    cnpj: Yup.string().when('type', {
      is: 'PJ',
      then: schema =>
        schema
          .required('CNPJ é obrigatório')
          .test('is-valid-cpf', 'CNPJ inválido', value => validateCNPJ(value)),
      otherwise: schema => schema.optional()
    }),
    email: Yup.string().email('Email inválido').required('O email é obrigatório'),
    contract_start: Yup.string().optional(),
    contract_end: Yup.string().optional(),
    cepUnknown: Yup.boolean(),
    cep: Yup.string().when('cepUnknown', {
      is: false,
      otherwise: schema => schema.optional(),
      then: schema => schema.required('CEP é obrigatório')
    }),
    address: Yup.string().required('Endereço é obrigatório'),
    address_number: Yup.string().required('Número é obrigatório'),
    district: Yup.string().required('Bairro é obrigatório'),
    state: Yup.string().required('Estado é obrigatório'),
    city: Yup.string().required('Cidade é obrigatório'),
    complement: Yup.string().optional(),
    phone: Yup.string().required('O telefone é obrigatório')
  })

  const initialFormValues: Partial<Client> = {
    ...initialValues,
    type: initialValues?.type || 'PJ',
    cpf: initialValues?.cpf || '',
    cnpj: initialValues?.cnpj || '',
    email: initialValues?.email || '',
    contract_start: initialValues?.contract_start || '',
    contract_end: initialValues?.contract_end || '',
    cep: initialValues?.cep || '',
    cepUnknown: !!initialValues?.id && !initialValues?.cep,
    address: initialValues?.address || '',
    address_number: initialValues?.address_number || '',
    district: initialValues?.district || '',
    state: initialValues?.state || '',
    city: initialValues?.city || '',
    complement: initialValues?.complement || '',
    phone: initialValues?.phone || ''
  }

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit
  })

  return formik
}

export const useClientProfileFormik = (
  onSubmit: (values: Partial<Client>) => void,
  initialValues?: Partial<Client>
) => {
  const { toast } = useToastContext()
  const validationSchema = Yup.object({
    units_limit: Yup.number().min(1, 'O limite de unidades deve ser maior que 0').required(),
    specialties: Yup.array()
      .of(
        Yup.object().shape({
          specialty_id: Yup.string().required()
        })
      )
      .min(1, 'Selecione ao menos uma especialidade'),
    domain: Yup.string().optional(),
    allow_profile_access_to_client: Yup.boolean().optional(),
    profiles: Yup.array()
      .of(
        Yup.object().shape({
          profile_id: Yup.string().required()
        })
      )
      .when('allow_profile_access_to_client', {
        is: true,
        then: schema => schema.min(1, 'Selecione ao menos um perfil'),
        otherwise: schema => schema.optional()
      }),
    primary_color: Yup.string().optional(),
    avatar: Yup.string().optional()
  })

  const initialFormValues = {
    units_limit: 1,
    profiles: [],
    specialties: [],
    domain: '',
    allow_profile_access_to_client: false,
    primary_color: '#007BBD',
    avatar: '',
    ...initialValues
  }

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: values => {
      try {
        const cleanedValues = {
          ...values,
          specialties: values.specialties?.filter(
            (value, index, self) =>
              index === self.findIndex(v => v.specialty_id === value.specialty_id)
          ),
          profiles: values.profiles?.filter(
            (value, index, self) => index === self.findIndex(v => v.profile_id === value.profile_id)
          )
        }

        onSubmit(cleanedValues)
      } catch (error) {
        toast.error(`Erro ao salvar as informações do cliente. Erro: ${error}`)
        formik.setSubmitting(false)
      }
    }
  })

  useEffect(() => {
    formik.setValues(initialFormValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  return formik
}
