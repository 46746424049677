import { Client, Scale, ScaleForm, Specialty, Unit, User } from '../../../../types'
import { scaleNumberToTime, scaleTimeToNumber } from '../../../../helpers/scaleTime'
import HourSelect from '../../../../components/dropdown/hour-select-v2'
import { Clock, MinusCircle, PlusCircle } from '@phosphor-icons/react'
import TimeSelect from '../../../../components/dropdown/time-dropdown'
import PriceInput from '../../../../components/price-input'
import { SelectedUnits } from './components/SelectedUnits'
import SelectedCard from './components/SelectedCard'
import { SelectCard } from './components/SelectCard'
import Button from '../../../../components/button'
import React, { useState, useEffect } from 'react'
import SpecialistModal from './modals/specialist'
import SpecialtyModal from './modals/specialty'
import { useTranslation } from 'react-i18next'
import UnitsModal from './modals/units'
import { FormikProps } from 'formik'

interface Props {
  client?: Client
  scaleData?: Scale
  formik: FormikProps<ScaleForm>
}

interface SpecialistInput {
  specialist: User
  price: number
  timeRangesSpecialist: { start_time: string; end_time: string }[]
}

const NewScaleForm: React.FC<Props> = ({ client, formik, scaleData }) => {
  const { t } = useTranslation('agendav2_list')
  const [timeRanges, setTimeRanges] = useState([{ start_time: '07:00', end_time: '19:00' }])

  const [isSpecialtyModalOpen, setSpecialtyModalOpen] = useState(false)
  const [isSpecialistModalOpen, setSpecialistModalOpen] = useState(false)
  const [isUnitModalOpen, setUnitModalOpen] = useState(false)

  const [selectedSpecialty, setSelectedSpecialty] = useState<Specialty | null>(null)
  const [selectedSpecialists, setSelectedSpecialists] = useState<SpecialistInput[]>([])
  const [selectedUnits, setSelectedUnits] = useState<Unit[]>([])

  useEffect(() => {
    if (scaleData) {
      setSelectedSpecialty(scaleData.specialty ?? null)
      formik.setFieldValue('specialty_id', scaleData.specialty_id)

      setSelectedUnits(
        scaleData.units.map(u => u.unit).filter((unit): unit is Unit => unit !== undefined)
      )

      setTimeRanges(
        scaleData.time_intervals.map(tr => ({
          start_time: scaleNumberToTime(tr.start_time),
          end_time: scaleNumberToTime(tr.end_time)
        }))
      )

      setSelectedSpecialists(
        scaleData.specialists.map(s => ({
          specialist: s.specialist,
          price: s.price,
          timeRangesSpecialist: s.time_intervals.map(tr => ({
            start_time: scaleNumberToTime(tr.start_time),
            end_time: scaleNumberToTime(tr.end_time)
          }))
        }))
      )

      formik.setFieldValue('duration', scaleData.duration)
      formik.setFieldValue('dates', [scaleData.date])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleData])

  useEffect(() => {
    formik.setFieldValue(
      'time_intervals',
      timeRanges.map(tr => ({
        start_time: scaleTimeToNumber(tr.start_time),
        end_time: scaleTimeToNumber(tr.end_time)
      }))
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRanges])

  useEffect(() => {
    formik.setFieldValue(
      'units',
      selectedUnits.map(unit => ({ unit_id: unit?.id }))
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnits])

  useEffect(() => {
    console.log(formik.values)
    formik.setFieldValue(
      'specialists',
      selectedSpecialists.map(s => ({
        specialist_id: s.specialist?.id,
        price: s.price,
        time_intervals: s.timeRangesSpecialist.map(tr => ({
          start_time: scaleTimeToNumber(tr.start_time),
          end_time: scaleTimeToNumber(tr.end_time)
        }))
      }))
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpecialists])

  const handleHourChange = (index: number, field: 'start_time' | 'end_time', value: string) => {
    setTimeRanges(prev => {
      const updated = [...prev]
      updated[index][field] = value
      return updated
    })
  }

  const handleUnitsModalSuccess = (units: Unit[]) => {
    setSelectedUnits(units)
    setUnitModalOpen(false)
  }

  const addTimeRange = (last_time?: string) => {
    setTimeRanges(prev => [
      ...prev,
      { start_time: last_time || '07:00', end_time: last_time || '19:00' }
    ])
  }

  const removeTimeRange = (index: number) => {
    setTimeRanges(prev => prev.filter((_, i) => i !== index))
  }

  const handleSpecialtyModalSuccess = (specialty: Specialty) => {
    formik.setFieldValue('specialists', [])
    formik.setFieldValue('units', [])
    formik.setFieldValue('specialty_id', specialty?.id)

    setSelectedUnits([])
    setSelectedSpecialists([])
    setSpecialtyModalOpen(false)
    setSelectedSpecialty(specialty)
  }

  const handleSpecialistModalSuccess = (specialist: User) => {
    setSelectedSpecialists(prev => {
      if (prev.some(s => s.specialist?.id === specialist?.id)) {
        return prev
      }
      return [
        ...prev,
        {
          specialist,
          price: 0,
          timeRangesSpecialist: [
            {
              start_time:
                selectedSpecialists.length === 1
                  ? selectedSpecialists[0].timeRangesSpecialist[0].end_time
                  : '07:00',
              end_time:
                selectedSpecialists.length === 1
                  ? selectedSpecialists[0].timeRangesSpecialist[0].end_time
                  : '19:00'
            }
          ]
        }
      ]
    })
    setSpecialistModalOpen(false)
  }

  const removeSpecialist = (specialistId: string) => {
    setSelectedSpecialists(prev => prev.filter(s => s.specialist?.id !== specialistId))
  }

  const handleTimeChange = (
    specialistId: string,
    index: number,
    field: 'start_time' | 'end_time',
    value: string
  ) => {
    console.log({ value })
    setSelectedSpecialists(prev =>
      prev.map(specialistWithTime => {
        if (specialistWithTime.specialist?.id === specialistId) {
          const updatedTimeRanges = [...specialistWithTime.timeRangesSpecialist]
          updatedTimeRanges[index][field] = value
          return { ...specialistWithTime, timeRangesSpecialist: updatedTimeRanges }
        }
        return specialistWithTime
      })
    )
  }

  const addTimeRangeToSpecialist = (specialistId: string, last_time?: string) => {
    setSelectedSpecialists(prev =>
      prev.map(specialistWithTime => {
        if (specialistWithTime.specialist?.id === specialistId) {
          return {
            ...specialistWithTime,
            timeRangesSpecialist: [
              ...specialistWithTime.timeRangesSpecialist,
              { start_time: last_time || '07:00', end_time: last_time || '19:00' }
            ]
          }
        }
        return specialistWithTime
      })
    )
  }

  const removeTimeRangeFromSpecialist = (specialistId: string, index: number) => {
    setSelectedSpecialists(prev =>
      prev.map(specialistWithTime => {
        if (specialistWithTime.specialist?.id === specialistId) {
          const updatedTimeRanges = specialistWithTime.timeRangesSpecialist.filter(
            (_, i) => i !== index
          )
          return { ...specialistWithTime, timeRangesSpecialist: updatedTimeRanges }
        }
        return specialistWithTime
      })
    )
  }

  return (
    <div className='grid grid-cols-1 gap-8 md:grid-cols-2'>
      <div className='flex flex-col gap-4'>
        <span className='text-heading-xs'>Especialidade</span>
        {selectedSpecialty ? (
          <SelectedCard
            label={selectedSpecialty.name ?? ''}
            avatar={selectedSpecialty.name ?? ''}
            onChange={() => setSpecialtyModalOpen(true)}
          />
        ) : (
          <SelectCard
            onChange={() => setSpecialtyModalOpen(true)}
            label={t('select_a_specialty')}
          />
        )}
        <SpecialtyModal
          client_id={client?.id}
          isOpen={isSpecialtyModalOpen}
          onClose={() => setSpecialtyModalOpen(false)}
          onSuccess={handleSpecialtyModalSuccess}
          selectedValue={selectedSpecialty}
        />
      </div>

      <div className='flex w-full flex-col gap-4'>
        <span className='text-heading-xs'>Período</span>
        {timeRanges.map((timeRange, index) => (
          <div key={index} className='flex w-full items-center gap-2'>
            <HourSelect
              label={t('start')}
              value={timeRange.start_time}
              min={index > 0 ? timeRanges[index - 1].end_time : undefined}
              onChange={value => handleHourChange(index, 'start_time', value)}
            />
            <HourSelect
              label={t('end')}
              value={timeRange.end_time}
              onChange={value => handleHourChange(index, 'end_time', value)}
              min={timeRange.start_time}
            />
            {index === 0 ? (
              timeRanges.length <= 1 && (
                <Button
                  variant='tertiary'
                  onClick={() => addTimeRange(timeRange.end_time)}
                  iconLeft={<PlusCircle size={22} />}
                />
              )
            ) : (
              <Button
                variant='tertiary'
                onClick={() => removeTimeRange(index)}
                iconLeft={<MinusCircle size={22} className='text-highlightRed-pure' />}
              />
            )}
          </div>
        ))}

        <div className='flex items-center gap-2'>
          <Clock size={20} className='text-neutralContent-primary' />
          <span className='text-label-sm text-neutralContent-secondary'>
            Duração dos atendimentos
          </span>
          <TimeSelect
            name='duration'
            label=' '
            value={formik.values.duration}
            onChangeValue={duration => {
              formik.setFieldValue('duration', duration)
            }}
          />
        </div>
      </div>

      <div className='flex flex-col gap-4'>
        <span className='text-heading-xs'>Especialistas</span>

        {selectedSpecialists.length === 0 ? (
          <SelectCard
            label={t('without_specialist')}
            onChange={() => setSpecialistModalOpen(true)}
            isDisabled={!selectedSpecialty || !selectedUnits.length}
          />
        ) : (
          <div>
            {selectedSpecialists.map((specialistWithTime, specialist_index) => (
              <div key={specialistWithTime.specialist?.id} className='my-4 border-b pb-4'>
                <SelectedCard
                  label={specialistWithTime.specialist.name ?? ''}
                  avatar={specialistWithTime.specialist.name ?? ''}
                  onChange={() => removeSpecialist(specialistWithTime.specialist?.id || '')}
                />
                {specialistWithTime.timeRangesSpecialist.map((timeRange, index) => (
                  <div key={index} className='my-4 flex items-center gap-4'>
                    <div className='flex items-center gap-3'>
                      <HourSelect
                        value={timeRange.start_time}
                        min={
                          selectedSpecialists.length > 1 && specialist_index > 0
                            ? selectedSpecialists[specialist_index - 1].timeRangesSpecialist[0]
                                .end_time
                            : index > 0
                              ? specialistWithTime.timeRangesSpecialist[index - 1].end_time
                              : undefined
                        }
                        onChange={value =>
                          handleTimeChange(
                            specialistWithTime.specialist?.id || '',
                            index,
                            'start_time',
                            value
                          )
                        }
                        padding={1}
                      />
                      <span className='text-label-md text-neutralContent-secondary'>às</span>

                      <HourSelect
                        padding={1}
                        min={timeRange.start_time}
                        value={timeRange.end_time}
                        onChange={value =>
                          handleTimeChange(
                            specialistWithTime.specialist?.id || '',
                            index,
                            'end_time',
                            value
                          )
                        }
                      />
                    </div>

                    {selectedSpecialists.length === 1 && (
                      <div>
                        {index === 0 ? (
                          <Button
                            iconLeft={<PlusCircle size={22} />}
                            variant='tertiary'
                            onClick={() =>
                              addTimeRangeToSpecialist(
                                specialistWithTime.specialist?.id || '',
                                timeRange.end_time
                              )
                            }
                          />
                        ) : (
                          <Button
                            iconLeft={<MinusCircle size={22} className='text-highlightRed-pure' />}
                            variant='tertiary'
                            onClick={() =>
                              removeTimeRangeFromSpecialist(
                                specialistWithTime.specialist?.id || '',
                                index
                              )
                            }
                          />
                        )}
                      </div>
                    )}
                  </div>
                ))}

                <div className='flex items-center gap-4'>
                  <PriceInput
                    value={specialistWithTime.price.toString()}
                    onChangeText={text => {
                      const price = parseFloat(text.replace(',', '.')) || 0
                      setSelectedSpecialists(prev =>
                        prev.map(specialist => {
                          if (specialist.specialist.id === specialistWithTime.specialist.id) {
                            return { ...specialist, price }
                          }
                          return specialist
                        })
                      )
                    }}
                    currency='R$'
                    unit='/hora'
                  />
                </div>
              </div>
            ))}

            {selectedSpecialists.length === 1 &&
              selectedSpecialists[0].timeRangesSpecialist.length === 1 && (
                <Button
                  label='Adicionar profissional'
                  iconLeft={<PlusCircle size={18} />}
                  variant='tertiary'
                  onClick={() => setSpecialistModalOpen(true)}
                />
              )}
          </div>
        )}

        {selectedSpecialty && (
          <SpecialistModal
            selectedValue={null}
            units={selectedUnits}
            specialty={selectedSpecialty}
            isOpen={isSpecialistModalOpen}
            onClose={() => setSpecialistModalOpen(false)}
            onSuccess={handleSpecialistModalSuccess}
          />
        )}
      </div>

      {/* Unidades */}
      <div className='flex flex-col gap-4'>
        <div className='flex items-center justify-between'>
          <span className='text-heading-xs'>Unidades</span>
          {selectedUnits.length > 0 && (
            <Button
              label='Adicionar Unidade'
              onClick={() => setUnitModalOpen(true)}
              size='sm'
              variant='secondary'
            />
          )}
        </div>
        {selectedUnits.length === 0 ? (
          <SelectCard
            onChange={() => setUnitModalOpen(true)}
            label={t('without_units')}
            isDisabled={!selectedSpecialty}
          />
        ) : (
          <SelectedUnits
            units={selectedUnits}
            onRemove={unitId => {
              setSelectedUnits(prevUnits => prevUnits.filter(unit => unit?.id !== unitId))
            }}
          />
        )}
        <UnitsModal
          client={client}
          isOpen={isUnitModalOpen}
          selectedValues={selectedUnits}
          onSuccess={handleUnitsModalSuccess}
          specialty_id={selectedSpecialty?.id}
          onClose={() => setUnitModalOpen(false)}
        />
      </div>
    </div>
  )
}

export default NewScaleForm
