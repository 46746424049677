import Radio from '../../../../../components/radio'
import { interpolateColor } from '../../../../../helpers/interpolateColor'
import { AnyQuestionProps } from '../any-questions'

export const ScaleQuestion: React.FC<AnyQuestionProps> = ({
  handleValue,
  question,
  values,
  readonly
}) => {
  const min = question.min || 1
  const max = question.max || 10
  const colors = ['#3E7952', '#F5A300', '#FF8333', '#B81435']

  if (readonly) {
    return <>{values[question.id!] ? values[question.id!] : '-'}</>
  }

  return (
    <div className='mt-2'>
      <div className='flex items-center gap-2'>
        {Array.from({ length: max - min + 1 }, (_, i) => min + i).map(value => (
          <div key={value} className='flex flex-col items-center px-2'>
            <Radio
              size='md'
              selected={values[question.id!] === String(value)}
              state='enabled'
              onChange={() => handleValue({ ...values, [question.id!]: String(value) })}
              borderColor={interpolateColor(value, min, max, colors)}
            />
            <span className='mt-1 text-label-md font-bold'>{value}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
