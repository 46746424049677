import { forwardRef, useEffect, useState } from 'react'
import InputField from '../../../../../components/input'
import { Props } from '../types'
import DatePickerWithCalendar from '../../../../../components/datepicker'
import Dropdown, { DropdownOption } from '../../../../../components/dropdown'
import { useTranslation } from 'react-i18next'
import { genders } from '../../../../users/user-form/user-personal-information-form'
import * as DocumentForms from './documents'
import { usePatientGeneralFormik } from '../../../../../hooks/forms/usePatientForm'
import { SpinnerGap } from '@phosphor-icons/react'

export const document_types = {
  rg: DocumentForms['GeneralRegister'],
  passport: DocumentForms['Passaport']
}
export const sexes = ['masculine', 'feminine', 'other']

const PatientGeneralForm = forwardRef<HTMLButtonElement, Props>(
  ({ handleSubmit, handleStateSubmit, value }, ref) => {
    const [_, setIsOtherGender] = useState(false)
    const formik = usePatientGeneralFormik(handleSubmit, value)
    const { t } = useTranslation('patient_form')

    const [isOtherSex, setIsOtherSex] = useState(
      !!formik.values.sex && !sexes.includes(formik.values.sex || '')
    )

    useEffect(() => {
      handleStateSubmit(!formik.isValid)
    }, [formik.isValid, handleStateSubmit])

    const handleGenderChange = (option?: DropdownOption | null) => {
      if (!!option?.value) formik.setFieldValue('gender', option?.value)
      setIsOtherGender(option?.label === t('other'))
    }

    const handleSexChange = (option: DropdownOption | null) => {
      if (option?.value === 'other') {
        formik.setFieldValue('sex', '')
        setIsOtherSex(genders.includes(option?.value || ''))
      } else {
        formik.setFieldValue('sex', option?.value || '')
        setIsOtherSex(false)
      }
    }

    return formik.isSubmitting ? (
      <div className='flex h-full w-full items-center justify-center'>
        <SpinnerGap size={20} className='animate-spin' />
      </div>
    ) : (
      <form onSubmit={formik.handleSubmit} className='flex flex-col gap-8'>
        <div className='flex gap-6'>
          <InputField
            id='cpf'
            name='cpf'
            type='cpf'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            label={t('fields.cpf')}
            placeholder={t('fields.enter')}
            value={formik.values.cpf || ''}
            className='max-w-[328px]'
            hint={formik.touched.cpf && formik.errors.cpf ? formik.errors.cpf : undefined}
            state={formik.touched.cpf && formik.errors.cpf ? 'error' : 'default'}
          />
          <InputField
            type='cns'
            id='cns_number'
            name='cns_number'
            placeholder={t('fields.cns_number')}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            label={t('fields.cns_number')}
            className='max-w-[375px]'
            value={formik.values.cns_number || ''}
            hint={
              formik.touched.cns_number && formik.errors.cns_number
                ? formik.errors.cns_number
                : undefined
            }
            state={formik.touched.cns_number && formik.errors.cns_number ? 'error' : 'default'}
          />
        </div>

        <InputField
          id='name'
          name='name'
          type='name'
          placeholder={t('fields.full_name')}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          label={t('fields.full_name')}
          value={formik.values.name || ''}
          hint={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
          state={formik.touched.name && formik.errors.name ? 'error' : 'default'}
        />

        <InputField
          type='name'
          id='social_name'
          name='social_name'
          placeholder={t('fields.full_name')}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          label={t('fields.social_name_optional')}
          value={formik.values.social_name || ''}
          hint={
            formik.touched.social_name && formik.errors.social_name
              ? formik.errors.social_name
              : undefined
          }
          state={formik.touched.social_name && formik.errors.social_name ? 'error' : 'default'}
        />

        <div className='flex gap-6'>
          <DatePickerWithCalendar
            name='birthdate'
            label={t('fields.birthdate')}
            placeholder={t('fields.birthdate')}
            onChange={val => formik.setFieldValue('birthdate', val)}
            onBlur={formik.handleBlur}
            className='max-w-[328px] '
            value={!!formik.values.birthdate ? new Date(formik.values.birthdate) : null}
            disabled={formik.isSubmitting}
            error={formik.errors.birthdate}
            touched={formik.touched.birthdate}
            limit={{ maxDate: new Date(), minDate: new Date(1900, 0, 1) }}
          />

          <Dropdown
            name='sex'
            label={t('fields.sex')}
            onBlur={formik.handleBlur}
            onChangeValue={handleSexChange}
            value={isOtherSex ? 'other' : formik.values.sex || ''}
            error={Boolean(formik.touched.sex && formik.errors.sex)}
            hint={formik.errors.sex ? formik.errors.sex?.toString() : undefined}
            options={sexes.map(value => ({
              value: value !== 'other' ? t(`selects.sexes.${value}`) : 'other',
              label: t(`selects.sexes.${value}`)
            }))}
          />

          {isOtherSex && (
            <InputField
              type='text'
              id='other_sex'
              name='other_sex'
              className='flex-1'
              onBlur={formik.handleBlur}
              label={t('fields.other_sex')}
              value={formik.values.sex || ''}
              onChange={e => formik.setFieldValue('sex', e.target.value)}
              state={formik.touched.sex && formik.errors.sex ? 'error' : 'default'}
              hint={formik.touched.sex && formik.errors.sex ? formik.errors.sex : undefined}
            />
          )}

          <Dropdown
            name='gender'
            label={t('fields.gender_optional')}
            onBlur={formik.handleBlur}
            value={formik.values.gender!}
            className='max-w-[328px] '
            placeholder={t('fields.gender')}
            onChangeValue={handleGenderChange}
            options={genders.map(value => ({
              value: (value !== 'other' ? t(`fields.selects.genders.${value}`) : null) as string,
              label: value !== 'other' ? t(`fields.selects.genders.${value}`) : t('other')
            }))}
          />
        </div>

        <InputField
          id='email'
          name='email'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          label={t('fields.email')}
          placeholder={t('placeholders.email')}
          value={formik.values.email || ''}
          hint={formik.touched.email && formik.errors.email ? formik.errors.email : undefined}
          state={formik.touched.email && formik.errors.email ? 'error' : 'default'}
        />

        <div className='flex gap-6'>
          <InputField
            type='phone'
            id='primary_phone'
            name='primary_phone'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            label={t('fields.phone')}
            placeholder={t('placeholders.phone')}
            className='max-w-[328px]'
            value={formik.values.primary_phone || ''}
            hint={
              formik.touched.primary_phone && formik.errors.primary_phone
                ? formik.errors.primary_phone
                : undefined
            }
            state={
              formik.touched.primary_phone && formik.errors.primary_phone ? 'error' : 'default'
            }
          />
          <InputField
            type='phone'
            id='secondary_phone'
            name='secondary_phone'
            className='max-w-[328px]'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            label={t('fields.secondary_phone')}
            placeholder={t('placeholders.secondary_phone')}
            value={formik.values.secondary_phone || ''}
            hint={
              formik.touched.secondary_phone && formik.errors.secondary_phone
                ? formik.errors.secondary_phone
                : undefined
            }
            state={
              formik.touched.secondary_phone && formik.errors.secondary_phone ? 'error' : 'default'
            }
          />
        </div>

        <button ref={ref} className='hidden' disabled={!formik.isValid} type='submit' />
      </form>
    )
  }
)

PatientGeneralForm.displayName = 'PatientGeneralForm'

export default PatientGeneralForm
