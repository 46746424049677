import {
  CARE_TYPE,
  Consultation,
  CONSULTATION_STATUS,
  PaginationParams,
  PaginationResponse
} from '../types'
import api from './api.service'

export type FollowUpProps = Partial<PaginationParams> & {
  unit_ids?: string[]
  care_types?: CARE_TYPE[]
  specialty_ids?: string[]

  status?: CONSULTATION_STATUS
}

export type FollowUpResponse = FollowUpProps & PaginationResponse<Consultation>

class FollowUpService {
  static async list(params: FollowUpProps) {
    const response = await api.get<FollowUpResponse>('/follow-up', {
      params
    })

    return response.data
  }
}

export default FollowUpService
