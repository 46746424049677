const consultation_details_ptBR = {
  title: 'Detalhes do atendimento',
  subtitle: 'Visualize e gerencie os atendimentos',
  video_conference: 'Videoconferência',
  information: 'Informações',
  clinical_history: 'Histórico Clínico',
  documents: 'Documentos',
  code: 'Código',
  consultation: 'Atendimento',
  date: 'Data',
  hour: 'Hora',
  specialty: 'Especialidade',
  specialist: 'Especialista',
  unit: 'Unidade',
  requester: 'Solicitante',
  links: 'Links',
  clinical_evolution: 'Evolução clínica',
  finished_consultation: 'Atendimento concluído',

  consultation_type: {
    elective: 'Eletivo',
    urgency: 'Pronto atendimento'
  },

  modal_confirmation: {
    title_SCHEDULED: 'Iniciar videoconferência?',
    description_SCHEDULED: 'Os participantes serão notificados sobre o início da consulta.',
    title_IN_ROOM: 'Entrar na videoconferência?',
    description_IN_ROOM: 'Os participantes serão notificados que você entrou na consulta.',
    title_WAITING: 'Iniciar atendimento?',
    description_WAITING: 'Os participantes serão notificados que você entrou na consulta.'
  },

  modal_confirmation_finish_call: {
    title: 'Finalizar o atendimento?',
    reason_label: 'Motivo',
    description:
      'O atendimento será encerrado e não será possível editar as informações da consulta.'
  },

  modal_nsp_modal: {
    title: 'Avalie o atendimento',
    description: 'Como você avalia o atendimento que acabou de receber?'
  },

  modal_confirmation_exit_call: {
    title: 'Sair do atendimento?',
    description:
      'O atendimento continuará disponível e os convidados serão mantidos na chamada até o fim do período agendado.'
  },

  modal_confirmation_start_chat: {
    title: 'Iniciar atendimento?',
    description: 'Os participantes serão notificados sobre o início da consulta.'
  },

  tabs: {
    anamnese: 'Anamnese',
    interactions: 'Interações',
    prescriptions: 'Prescrições Médicas',
    diagnosis: 'Diagnóstico',
    historic: 'Histórico',
    tcle: 'TCLE',
    chat: 'Chat da consulta',

    patient_identification: 'Identificação do Paciente',
    specialty: 'Especialidade',
    diagnosticHypothesis: 'Hipótese Diagnóstica, Planejamento Terapeutico e Prognóstico'
  },

  historic: {
    older_consultations: 'Atendimentos anteriores',
    medications_in_use: 'Medicamentos em uso',

    columns: {
      code: 'Código',
      date: 'Data',
      hour: 'Hora',
      specialty: 'Especialidade',
      care_type: 'Atendimento',
      unit: 'Unidade',

      name: 'Nome',
      dosage: 'Dosagem',
      dosage_desc: 'Posologia',
      treatment: 'Início do tratamento',
      duration: 'Duração'
    }
  },

  anamnese_tab: {
    default: 'Padrão',
    specialty: 'Especialidade'
  },

  chat: {
    title: 'Chat da consulta',
    textarea_placeholder: 'Digite sua mensagem...'
  },

  types: {
    chat: 'Chat',
    elective_care: 'Eletivo',
    emergency_care: 'Pronto atendimento'
  },

  interactions: {
    modal: {
      title: 'Anexar documento',
      from_my_computer: 'do meu computador',
      qr_code: 'via QR Code',
      only_image: ' (somente imagens)',
      description_qrcode:
        'Aponte a câmera do seu celular para o código abaixo e em seguida selecione o número exibido para prosseguir.'
    }
  },

  invite_patient_modal: {
    title: 'Convidar paciente',
    subtitle: ' O paciente receberá um e-mail com as instruções para acessar a videoconferência.'
  },
  invite_professional_modal: {
    title: 'Convidar profissional',
    subtitle:
      'O profissional receberá um e-mail com as instruções para acessar a videoconferência.',
    inputs: {
      name: 'Nome completo',
      email: 'E-mail',
      birthdate: 'Data de nascimento'
    }
  },

  closure_reason: {
    realized: 'Realizado',
    patient_to_show: 'Falta do paciente',
    canceled: 'Cancelado',
    refused: 'Recusado',
    deceased: 'Óbito'
  },

  actions: {
    edit: 'Editar',
    complete_consultation: 'Concluir atendimento',
    see_complete_sheet: 'Ver ficha completa',
    start_call: 'Iniciar videoconferência',
    enter_call: 'Entrar na videoconferência',
    start_chat: 'Iniciar chat',
    enter_chat: 'Abrir chat',
    anamnesis: 'Anamnesis',
    report: 'Report',
    attach_file: 'Anexar arquivo',
    invite_patient: 'Convidar paciente',
    invite_professional: 'Convidar profissional',
    attach_document: 'Anexar documento',
    insert_link: 'Inserir link',
    start: 'Iniciar',
    cancel: 'Cancelar',
    entry_call: 'Entrar na chamada',
    exit: 'Sair',
    exit_call: 'Sair do atendimento',
    finish_call: 'Finalizar atendimento',
    finish: 'Finalizar',
    sent: 'Enviar',
    attach: 'Anexar',
    invite: 'Enviar convite',
    select: 'Selecione'
  }
}

export default consultation_details_ptBR
