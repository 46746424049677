const my_account_ptBR = {
  title: 'Minha conta',
  sessions: {
    personal_info: {
      title: 'Informações pessoais',
      birthdate: 'Data de nascimento',
      mother_name: 'Nome completo da mãe',
      mother_unknown: 'Mãe desconhecida',
      sex: 'Sexo',
      gender: 'Gênero',
      race: 'Raça/Cor',
      nationality: 'Nacionalidade',
      birth_state: 'Estado de nascimento',
      birth_city: 'Município de nascimento',

      masculine: 'Masculino',
      feminine: 'Feminino',

      white: 'Branca',
      black: 'Preta',
      brown: 'Parda',
      yellow: 'Amarela',
      indigenous: 'Indígena',
      no_information: 'Sem Informação',

      cisgender_woman: 'Mulher cisgênero',
      transgender_woman: 'Mulher transgênero',
      cisgender_man: 'Homem cisgênero',
      transgender_man: 'Homem transgênero',
      non_binary: 'Não-binário',
      other: 'Outro'
    },
    clients: {
      title: 'Clientes'
    },
    documents: {
      title: 'Documentos',
      document: 'CPF',
      cns: 'Número do Cartão Nacional de Saúde - CNS'
    },
    contact: {
      title: 'Contato',
      email: 'E-mail principal',
      cell: 'Número de celular',
      phone: 'Número de telefone'
    },
    address: {
      title: 'Endereço'
    },
    professional_info: {
      title: 'Informações profissionais',
      specialties: 'Especialidades',
      records: 'Registros'
    },
    profile: {
      title: 'Perfis de acesso',
      no_profile_selected: 'Nenhum perfil selecionado'
    }
  },
  actions: {
    save: 'Salvar',
    cancel: 'Cancelar',
    update: 'Editar',
    update_profile: 'Atualizar perfil',
    update_image: 'Alterar foto'
  }
}

export default my_account_ptBR
