import user_form from './user-form/en-US'
import profiles from './profiles/en-US'
import client_form from './client-form/en-US'
import user_list from './user-list/en-US'
import permissions_units from './permissions-units/en-US'
import agenda_list from './agenda-list/en-US'
import agenda_form from './agenda-form/en-US'
import unit_form from './unit-form/en-US'
import unit_detail from './unit-detail/en-US'
import unit_list from './unit_list/en-US'
import client_list from './client-list/en-US'
import consultation_list from './consultation-list/en-US'
import consultation_form from './consultation-form/en-US'
import patient_list from './patient-list/en-US'
import patient_form from './patient-form/pt-BR'
import transfer_consultation from './transfer-consultation/en-US'
import anamnesis from './anamnesis/en-US'
import anamnesis_list from './anamnese-list/en-US'
import anamnesis_form from './anamnese_form/en-US'
import user_details from './user-details/en-US'
import consultation_details from './consultation-details/en-US'
import unit_type from './unit-type/en-US'
import consultation_follow_up from './consultation-follow-up/en-US'
import agendav2_list from './agenda-v2-list/en-US'
import patient_detail from './patient_detail/en-US'
import my_account from './my-account/en-US'

const enUS = {
  languages: {
    ptBR: 'Portuguese',
    enUS: 'English'
  },
  main_menu: {
    home: 'Home',
    agenda: 'Agenda',
    consultation: 'Consultations',
    chat_consultation: 'Chat Consultations',
    accesses: 'Accesses',
    clients: 'Clients',
    users: 'Users',
    patients: 'Patients',
    management: 'Management',
    profile_access: 'Access Profiles',
    unit_type: 'Unit Types',
    councils: 'Councils',
    specialties: 'Specialties',
    anamnesis_template: 'Anamnesis Templates',
    dashboards: 'Dashboards'
  },
  user_menu: {
    my_account: 'My Account',
    update_password: 'Update Password',
    logout: 'Logout'
  },
  home: {
    good_morning: 'Good morning {{name}}.',
    good_afternoon: 'Good afternoon {{name}}.',
    good_night: 'Good night {{name}}.'
  },
  calendar: {
    week_day: {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday'
    }
  },
  profiles,
  user_form,
  user_list,
  user_details,
  client_form,
  permissions_units,
  agenda_list,
  agenda_form,
  unit_form,
  unit_detail,
  unit_list,
  client_list,
  consultation_list,
  consultation_form,
  consultation_details,
  patient_list,
  patient_form,
  transfer_consultation,
  anamnesis,
  anamnesis_list,
  anamnesis_form,
  unit_type,
  consultation_follow_up,
  agendav2_list,
  patient_detail,
  my_account
}

export default enUS
