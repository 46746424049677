import React from 'react'
import Button from '../button'
import { CaretLeft, CaretRight } from '@phosphor-icons/react'
import { TableHeader } from './table-header'
import { TableRow } from './table-row'
import { TableProps } from './types'
import { BodySkeleton } from './table-skeeleton'

const Table: React.FC<TableProps> = ({
  data,
  columns,
  className,
  isLoading,
  sortedColumn,
  selectedRows,
  hasBackButton,
  totalPages = 0,
  currentPage = 0,
  selectable = false,
  emptyDataLabel = 'Sem dados encontrados',
  handleSort,
  onClickRow,
  onPageChange,
  handleAllCheck
}) => {
  const tableSize =
    className ||
    (hasBackButton
      ? 'max-h-[calc(100dvh-304px)] sm:max-h-[calc(100dvh-380px)]'
      : 'max-h-[calc(100dvh-260px)] sm:max-h-[calc(100dvh-298px)]')

  return (
    <div
      className={`mx-[-16px] flex flex-1 flex-col border-neutralBorder-default sm:mx-0  sm:rounded-xl sm:border sm:px-0 ${tableSize}`}
    >
      <TableHeader
        columns={columns}
        handleSort={handleSort}
        selectable={selectable}
        sortedColumn={sortedColumn}
        handleAllCheck={handleAllCheck}
        isSomeChecked={!!selectedRows?.length}
        isAllChecked={selectedRows?.length === data.length}
      />

      {isLoading ? (
        <BodySkeleton columns={columns} length={data.length} selectable={selectable} />
      ) : data.length === 0 ? (
        <div className='flex items-center justify-center p-4 text-center text-label-lg text-neutralContent-tertiary'>
          {emptyDataLabel}
        </div>
      ) : (
        <div className='flex flex-1 flex-col gap-3 overflow-y-auto px-2 pb-4 sm:gap-0 sm:px-0 sm:pb-0'>
          {data.map(row => (
            <TableRow
              row={row}
              key={row.id}
              columns={columns}
              selectable={selectable}
              onClickRow={onClickRow}
              handleSelectRow={onClickRow}
              isSelected={selectedRows?.some(selectedRow => selectedRow.id === row.id)}
            />
          ))}
        </div>
      )}

      {!!totalPages && (
        <div className='flex items-center justify-center border-t border-neutralBorder-default px-4 py-2'>
          <Button
            disabled={currentPage <= 0}
            onClick={() => onPageChange?.(currentPage - 1)}
            iconLeft={<CaretLeft />}
            size='sm'
            variant='secondary'
            state={currentPage <= 0 ? 'disable' : 'enable'}
          />
          <span className='mx-2 text-label-sm font-medium text-neutralContent-tertiary'>
            Página {currentPage + 1} de {totalPages}
          </span>
          <Button
            disabled={currentPage >= totalPages - 1}
            onClick={() => onPageChange?.(currentPage + 1)}
            iconLeft={<CaretRight />}
            size='sm'
            variant='secondary'
            state={currentPage >= totalPages - 1 ? 'disable' : 'enable'}
          />
        </div>
      )}
    </div>
  )
}

export default Table
