import { CaretDown, CaretUp } from '@phosphor-icons/react'
import Checkbox from '../checkbox'
import React, { useMemo } from 'react'
import { Column, SortedColumn, TableHeaderProps } from './types'

export const TableHeader: React.FC<TableHeaderProps> = ({
  columns,
  selectable,
  sortedColumn,
  isAllChecked,
  isSomeChecked,
  handleSort,
  handleAllCheck
}) => {
  return (
    <div className='hidden w-full border-b border-neutralBorder-default sm:flex'>
      {selectable && (
        <div className='flex w-[40px] p-4 text-left'>
          <Checkbox
            isChecked={!!isAllChecked}
            onClick={value => handleAllCheck?.(value)}
            isIndeterminate={!isAllChecked && !!isSomeChecked}
          />
        </div>
      )}

      {columns.map((column, index) => {
        return (
          <div
            key={index}
            className={`flex flex-1 items-center gap-1 overflow-hidden p-4 text-left text-label-sm font-medium text-neutralContent-tertiary ${column.className}`}
          >
            <span>{column.header}</span>
            {handleSort && (
              <SortColumnComponent
                column={column}
                handleSort={handleSort}
                sortedColumn={sortedColumn}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

const SortColumnComponent: React.FC<{
  column: Column
  sortedColumn?: SortedColumn
  handleSort: (sortedColumn: SortedColumn) => void
}> = ({ handleSort, sortedColumn, column }) => {
  const sorted = useMemo(() => {
    return column.order_key === sortedColumn?.order_key && sortedColumn?.order
  }, [column, sortedColumn])

  if (!column.order_key) return <></>

  return (
    <div className='flex flex-col'>
      <button
        type='button'
        onClick={() => handleSort({ order_key: column.order_key || '', order: 'DESC' })}
        className={`rounded-full border-white p-1 transition-all ${sorted === 'DESC' ? 'bg-primaryBrand-primary text-white' : 'hover:bg-primaryBrand-light'}`}
      >
        <CaretUp />
      </button>
      <button
        type='button'
        onClick={() => handleSort({ order_key: column.order_key || '', order: 'ASC' })}
        className={`rounded-full border-white p-1 transition-all  ${sorted === 'ASC' ? 'bg-primaryBrand-primary text-white' : 'hover:bg-primaryBrand-light'}`}
      >
        <CaretDown />
      </button>
    </div>
  )
}
