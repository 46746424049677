/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Modal from '../../../components/modal'
import InputField from '../../../components/input'
import Button from '../../../components/button'
import { Consultation } from '../../../types'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

interface Props {
  isLoading?: boolean
  isModalOpen: boolean
  closeModal: VoidFunction
  onSuccessUpdate: VoidFunction
  consultation: Consultation | null | undefined
}

const without_patient_key = 'Sem paciente'

const UpdateConsultationModal = ({
  isLoading,
  closeModal,
  isModalOpen,
  consultation,
  onSuccessUpdate
}: Props) => {
  const { t } = useTranslation('consultation_form')
  const [patientName, setPatientName] = useState('')
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    if (isModalOpen && consultation) setPatientName('')
  }, [isModalOpen, consultation])

  const handleSubmit = () => {
    if (
      (!!consultation?.patient?.name && patientName === consultation?.patient?.name) ||
      (!consultation?.patient?.name && patientName === without_patient_key)
    ) {
      onSuccessUpdate()
    } else {
      setHasError(true)
    }
  }

  const handlePatientName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPatientName(event.target.value)
    setHasError(false)
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={closeModal}
      title={t('modal_confirmation_update_consultation.title')}
    >
      <div className='w-[400px]'>
        <div className='mb-1'>
          <p className='text-body-md text-neutralContent-tertiary'>
            {t('modal_confirmation_update_consultation.decription')}
          </p>
          <br />
          <p className='text-body-md text-neutralContent-tertiary'>
            {!!consultation?.patient?.name ? (
              t('modal_confirmation_update_consultation.decription_patient')
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    t('modal_confirmation_update_consultation.decription_without_patient') || ''
                }}
              />
            )}
          </p>
        </div>

        <div className='my-4 flex w-[400px] justify-center'>
          <InputField
            name='patient.name'
            value={patientName}
            onChange={handlePatientName}
            state={hasError ? 'error' : 'default'}
            hint={hasError ? 'Nome incorreto' : ''}
            placeholder={t('modal_confirmation_update_consultation.input_placeholder')}
          />
        </div>

        <div className='mt-4 flex w-[400px] justify-between'>
          <Button
            size='sm'
            type='button'
            variant='tertiary'
            onClick={closeModal}
            label={t('actions.cancel')}
          />
          <Button
            size='sm'
            type='submit'
            variant='primary'
            isLoading={isLoading}
            onClick={handleSubmit}
            label={t('actions.confirm')}
            state={!hasError ? 'enable' : 'disable'}
          />
        </div>
      </div>
    </Modal>
  )
}

export default UpdateConsultationModal
