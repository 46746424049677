const unit_form_enUS = {
  fields: {
    name: 'Name',
    unit_type: 'Unit Type',
    cnpj: 'CNPJ',
    cnes: 'National Health Establishment Registration (CNES)',
    email: 'Email',
    phone: 'Phone',
    technical_manager: 'Technical Manager',
    address: 'Address',
    address_number: 'Number',
    district: 'District',
    cep: 'ZIP Code',
    state: 'State',
    city: 'City',
    specialties: 'Specialties',
    select_specialties: 'Select specialties',
    permissions: 'Care Functionality',
    permission_type_care: 'Types of Care',
    permission_type: 'Care Modalities',
    create_unit: 'Create Unit',
    edit_unit: 'Edit Unit',
    unit_details: 'Unit Details',
    unit_address: 'Unit Address',
    technical_manager_name: 'Name',
    technical_manager_council_doc: 'Council Number',
    technical_manager_doc: 'CPF'
  },
  actions: {
    save: 'Save',
    create: 'Create Unit',
    cancel: 'Cancel'
  }
}

export default unit_form_enUS
