import { io, Socket } from 'socket.io-client'
import StorageService from './storage.service'

class FollowUpSocketService {
  socket?: Socket

  constructor() {
    const token = StorageService.getToken()

    this.socket = io(process.env.REACT_APP_API_URL!, {
      auth: {
        token: token
      }
    })
    this.socket?.emit('joinFollowUp')
  }
}

export default FollowUpSocketService
