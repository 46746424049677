import React, { useState } from 'react'
import TopBar from '../../../components/top-bar'

import BirthdateValidator from './birthdate-validator'
import VideoConsultation from './video-consultation'
import { TclePerson } from '../../../components/connection/tcle-modal'
import { Patient } from '../../../types'

const ConsultationJoinPage: React.FC = () => {
  const [isBirthdateValidated, setIsBirthdateValidated] = React.useState(false)
  const [person, setPerson] = useState<TclePerson>()

  return (
    <div className='flex min-h-[100vh] w-[100vw] flex-col'>
      <TopBar isOpen={false} handleMenu={() => {}} user={null} onLogout={() => {}} />

      <div className='grid h-full flex-1 items-baseline rounded-2xl bg-white p-6'>
        {!isBirthdateValidated && (
          <BirthdateValidator
            onSuccess={(patient: Patient) => {
              setPerson(patient as TclePerson)
              setIsBirthdateValidated(true)
            }}
          />
        )}

        {isBirthdateValidated && <VideoConsultation person={person} />}
      </div>
    </div>
  )
}

export default ConsultationJoinPage
