import UnitPermissionsEnum from '../../../types/unit-permissions'

const permissions_units_enUS = {
  permissions: {
    [UnitPermissionsEnum.chat_consultation]: 'Chat',
    [UnitPermissionsEnum.elective_care_consultation]: 'Elective',
    [UnitPermissionsEnum.emergency_care_consultation]: 'Emergency Consultations',

    [UnitPermissionsEnum.teleconsultation_type]: 'Teleconsultation',
    [UnitPermissionsEnum.teleinterconsultation_type]: 'Teleinterconsultation',

    [UnitPermissionsEnum.write_permission]: 'Write Permission',
    [UnitPermissionsEnum.invite_patient]: 'Invite Patient',
    [UnitPermissionsEnum.invite_professional]: 'Invite Professional'
  }
}

export default permissions_units_enUS
