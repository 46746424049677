import { useMemo } from 'react'
import { AnamneseTemplate } from './AnamnesisTemplate'
import { useTranslation } from 'react-i18next'
import { Interactions } from './Interactions'
import Tab from '../../../../components/tabs'
import PrescriptionsTab from './prescriptions'
import PermissionEnum from '../../../../types/permissions'
import ProtectedComponent from '../../../../components/protected'
import { ChatHistory } from './ChatHistory'
import { History } from './History'
import { Diagnosis } from './Diagnosis'
import { CARE_TYPE } from '../../../../types'
import { TcleList } from './tcle'

const ConsultationTabs: React.FC<{
  id: string
  care_type?: CARE_TYPE
  patient_id: string
  cpf: string
  specialist_id?: string
  is_finished?: boolean
}> = ({ id, is_finished, care_type, patient_id, specialist_id, cpf }) => {
  const { t } = useTranslation('consultation_details')

  const tabs = useMemo(
    () => [
      {
        label: t('tabs.anamnese'),
        content: (
          <ProtectedComponent permission={PermissionEnum.service_do_screening}>
            <AnamneseTemplate id={id} is_finished={is_finished} />
          </ProtectedComponent>
        ),
        disabled: false
      },
      {
        label: t('tabs.interactions'),
        content: <Interactions consultation_id={id} />,
        disabled: false
      },
      ...(specialist_id
        ? [
            {
              label: t('tabs.prescriptions'),
              content: (
                <ProtectedComponent permission={PermissionEnum.service_do_screening}>
                  <PrescriptionsTab consultationId={id} />
                </ProtectedComponent>
              ),
              disabled: false
            }
          ]
        : []),
      {
        label: t('tabs.diagnosis'),
        content: (
          <ProtectedComponent permission={PermissionEnum.service_do_screening}>
            <Diagnosis consultationId={id} specialist_id={specialist_id} />
          </ProtectedComponent>
        ),
        disabled: false
      },
      ...(patient_id
        ? [
            {
              label: t('tabs.historic'),
              content: <History patient_id={patient_id} cpf={cpf} />,
              disabled: false
            }
          ]
        : []),
      ...(is_finished && care_type === CARE_TYPE.CHAT
        ? [
            {
              label: t('tabs.chat'),
              content: <ChatHistory consultation_id={id} />,
              disabled: false
            }
          ]
        : []),
      {
        label: t('tabs.tcle'),
        content: <TcleList id={id} />,
        disabled: false
      }
    ],
    [t, id, specialist_id, patient_id, cpf, is_finished, care_type]
  )
  return <Tab tabs={tabs} />
}

export default ConsultationTabs
