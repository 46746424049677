import { CheckboxQuestion } from './question_types/checkbox-question'
import { DropdownQuestion } from './question_types/dropdown-question'
import { YesNoQuestion } from './question_types/yes-no-question'
import { ScaleQuestion } from './question_types/scale-question'
import { RadioQuestion } from './question_types/radio-question'
import { DateQuestion } from './question_types/date-question'
import { TextQuestion } from './question_types/text-question'
import { QuestionOption } from '../../../../types'
import { InputType } from '../../../../components/input'
import { DurationQuestion } from './question_types/duration-question'

export type AnyQuestionProps = {
  question: QuestionOption
  readonly?: boolean
  values: { [key: string]: string[] | string | null | undefined }
  handleValue: (newValues: { [key: string]: string[] | string | null | undefined }) => void
}

const AnyQuestion: React.FC<AnyQuestionProps> = ({ question, handleValue, values, readonly }) => {
  if (question.depends_on && values[question.depends_on + '_yes_no'] !== 'yes') return <></>

  const renderQuestionByType = () => {
    if (question.type === 'checkbox') {
      return (
        <CheckboxQuestion
          values={values}
          question={question}
          readonly={readonly}
          handleValue={handleValue}
        />
      )
    }
    if (question.type === 'date') {
      return (
        <DateQuestion
          values={values}
          question={question}
          readonly={readonly}
          handleValue={handleValue}
        />
      )
    }
    if (question.type === 'scale') {
      return (
        <ScaleQuestion
          values={values}
          question={question}
          readonly={readonly}
          handleValue={handleValue}
        />
      )
    }
    if (question.type === 'radio') {
      return (
        <RadioQuestion
          values={values}
          question={question}
          readonly={readonly}
          handleValue={handleValue}
        />
      )
    }
    if (question.type === 'dropdown') {
      return (
        <DropdownQuestion
          values={values}
          question={question}
          readonly={readonly}
          handleValue={handleValue}
        />
      )
    }
    if (question.type === 'duration') {
      return (
        <DurationQuestion
          values={values}
          question={question}
          readonly={readonly}
          handleValue={handleValue}
        />
      )
    }
    if (question.type === 'yes_no') {
      return (
        <YesNoQuestion
          values={values}
          question={question}
          readonly={readonly}
          handleValue={handleValue}
        />
      )
    }

    return (
      <TextQuestion
        values={values}
        question={question}
        readonly={readonly}
        handleValue={handleValue}
        type={question.type as InputType}
      />
    )
  }

  return (
    <div className={`border-b pb-10 pt-10 first:pt-0 last:border-b-0 ${question.className}`}>
      <span className='mb-3 block text-label-md font-semibold'>{question.title}</span>

      {renderQuestionByType()}
    </div>
  )
}

export default AnyQuestion
