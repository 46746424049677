const user_list_enUS = {
  header: {
    name: 'Name',
    email: 'Email',
    health_professional: 'Health Professional',
    status: 'Status'
  },
  status: {
    active: 'Active',
    inactive: 'Inactive'
  }
}

export default user_list_enUS
