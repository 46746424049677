import { CaretDown, Password, SignOut, UserCircle } from '@phosphor-icons/react'
import { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ROUTES } from '../../routes/path'
import { User } from '../../types'
import { useTranslation } from 'react-i18next'
import Avatar from '../avatar'

const MENU_ITEMS = [
  { route: ROUTES.myAccount, name: 'my_account', Icon: UserCircle },
  { route: ROUTES.updatePassword, name: 'update_password', Icon: Password },
  { route: '.', name: 'logout', Icon: SignOut }
]

const UserMenu: React.FC<{ user: User; onLogout: () => void }> = ({ user, onLogout }) => {
  const { t } = useTranslation('user_menu')
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const onNavigate = (route: string) => {
    if (route === '.') {
      onLogout()
    }
    setIsOpen(false)
  }

  const getCustomLayout = (route: string, isActive: boolean) => {
    if (route === '.') {
      return 'text-highlightRed-pure hover:bg-highlightRed-light'
    }

    if (isActive) {
      return 'text-neutralContent-primary bg-primaryBrand-primary text-white'
    }

    return 'text-neutralContent-primary hover:bg-primaryBrand-light'
  }

  return (
    <div ref={dropdownRef} className='relative'>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className='flex cursor-pointer flex-row items-center rounded-lg py-4 pl-2 transition-all hover:bg-primaryBrand-light'
      >
        <Avatar imageID={user?.image_id} name={user?.name} size='sm' />

        {/* <div className='flex min-h-9 min-w-9 items-center justify-center rounded-full bg-primaryBrand-primary text-label-md text-white'>
          {user.name ? user.name[0] : ''}
        </div> */}
        <div className='ml-3 flex flex-col'>
          <span className='text-label-sm'> {user.name}</span>
          <span className='text-body-sm text-neutralContent-secondary'> {user.profile?.name}</span>
        </div>
        <div className='ml-2 p-2'>
          <CaretDown />
        </div>
      </div>
      <div
        className={`absolute  right-0 mt-2 flex w-56 origin-top-right transform flex-col gap-2 rounded-md bg-white px-2 py-4 shadow-lg ring-1 ring-black ring-opacity-5 transition duration-200 ease-out focus:outline-none ${isOpen ? 'scale-100 opacity-100' : 'pointer-events-none scale-95 opacity-0'}`}
      >
        {MENU_ITEMS.map(item => (
          <NavLink
            key={item.route}
            to={item.route || '/'}
            onClick={() => onNavigate(item.route)}
            className={({ isActive }) =>
              `flex items-center overflow-hidden text-nowrap rounded-xl px-3 py-[10px] text-label-sm transition-all ${getCustomLayout(item.route, isActive)}`
            }
          >
            <div className='mr-3'>{item.Icon && <item.Icon size={20} />}</div>
            {t(item.name)}
          </NavLink>
        ))}
      </div>
    </div>
  )
}

export default UserMenu
