import React, { ReactNode } from 'react'

interface Props {
  label: string | ReactNode
  variant?: 'primary' | 'secondary' | 'tertiary'
  size?: 'lg' | 'md' | 'sm' | 'xs'
  color: 'neutral' | 'red' | 'blue' | 'yellow' | 'green' | 'gray' | 'orange'
  onClick?: () => void
  onClose?: () => void
  className?: string
  leftIcon?: ReactNode
  rightContent?: ReactNode
}

const Tag: React.FC<Props> = ({
  label,
  variant = 'primary',
  size = 'md',
  color,
  onClick,
  onClose,
  className,
  leftIcon,
  rightContent
}) => {
  const sizeClasses = {
    xs: 'width-8 px-1 py-0.5 text-xs ',
    sm: 'width-10 px-1.5 py-1 text-sm ',
    md: 'width-12 px-2 py-1.5 text-base ',
    lg: 'width-14 px-2.5 py-2 text-lg '
  }

  const colorClasses = {
    primary: {
      neutral: 'bg-neutralBackground-secondary text-neutralContent-primary ',
      red: 'bg-highlightRed-pure text-neutralContent-primaryInverse ',
      blue: 'bg-highlightBlue-pure text-neutralContent-primaryInverse ',
      yellow: 'bg-highlightYellow-pure text-neutralContent-primary ',
      green: 'bg-highlightGreen-pure text-neutralContent-primaryInverse ',
      gray: 'bg-neutralContent-secondary text-neutralContent-quartenary',
      orange: 'bg-highlightOrange-pure text-neutralContent-primary'
    },
    secondary: {
      neutral: 'bg-neutralBackground-secondary text-neutralContent-primary ',
      red: 'bg-highlightRed-light text-highlightRed-pure ',
      blue: 'bg-highlightBlue-light text-primaryBrand-primary ',
      yellow: 'bg-highlightYellow-light text-neutralContent-primary ',
      green: 'bg-highlightGreen-light text-highlightGreen-pure ',
      gray: 'bg-neutralContent-primary text-neutralContent-quartenary',
      orange: 'bg-highlightOrange-light text-neutralContent-primaryInverse'
    },
    tertiary: {
      neutral: 'border border-neutralBorder-default text-neutralContent-primary bg-transparent ',
      red: 'border border-highlightRed-pure text-highlightRed-pure bg-transparent ',
      blue: 'border border-highlightBlue-pure text-highlightBlue-pure bg-transparent ',
      yellow: 'border border-highlightYellow-pure text-neutralContent-primary bg-transparent ',
      green: 'border border-highlightGreen-pure text-highlightGreen-pure bg-transparent ',
      gray: 'border border-neutralBorder-default bg-white text-neutralContent-quartenary',
      orange: 'border border-highlightOrange-pure text-highlightOrange-pure bg-transparent'
    }
  }

  const badgeClasses = `inline-block ${sizeClasses[size]} ${colorClasses[variant][color]} rounded-lg`

  const handleClick = () => {
    onClick?.()
  }

  return (
    <div
      onClick={handleClick}
      className={`${badgeClasses} ${className} flex items-center gap-2 whitespace-nowrap`}
    >
      {leftIcon}
      {label}

      {rightContent}

      {onClose && (
        <button
          onClick={onClose}
          className={`ml-2 ${colorClasses[variant][color]} border-none focus:outline-none`}
          aria-label='Close'
        >
          &times;
        </button>
      )}
    </div>
  )
}

export default Tag
