/* eslint-disable @typescript-eslint/no-unused-vars */
import { Check, VideoCamera, PaperPlaneTilt, ArrowSquareOut, ChatDots } from '@phosphor-icons/react'
import { CARE_TYPE, Consultation, CONSULTATION_STATUS, CONSULTATION_TYPE } from '../../../../types'
import { ConfirmationStartCallModal } from '../DialogsConfirmationCall'
import { formatBirthDate } from '../../../../helpers/formatBirthDate'
import ProtectedComponent from '../../../../components/protected'
import { calculateAge } from '../../../../helpers/formatAge'
import PermissionEnum from '../../../../types/permissions'
import BlankCard from '../../../../components/blank-card'
import Avatar from '../../../../components/avatar'
import Button from '../../../../components/button'
import { useTranslation } from 'react-i18next'
import UnitPermissionsEnum from '../../../../types/unit-permissions'
import InviteProfessionalModal from './InviteProfessionalModal'
import InvitePatientModal from './InvitePatientModal'
import { useAuth } from '../../../../contexts/auth'
import { ROUTES } from '../../../../routes/path'
import { Link, useNavigate } from 'react-router-dom'
import Tag from '../../../../components/tag'
import { useMemo, useState } from 'react'
import { isToday } from 'date-fns'

type Props = {
  onStart: () => void
  isStarted?: boolean
  consultation: Consultation
}

const PatientDetails: React.FC<Props> = ({ onStart, consultation, isStarted }) => {
  const { t } = useTranslation('consultation_details')
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [inviteProfessional, setInviteProfessional] = useState(false)
  const [invitePatient, setInvitePatient] = useState(false)
  const navigate = useNavigate()
  const { user } = useAuth()

  const showButtonToStartChat = useMemo(() => {
    if (
      consultation.care_type === CARE_TYPE.CHAT &&
      consultation.status === CONSULTATION_STATUS.WAITING
    ) {
      return true
    }

    return false
  }, [consultation])

  const showButtonToEnterChat = useMemo(() => {
    if (isStarted) return false

    if (
      consultation.care_type === CARE_TYPE.CHAT &&
      consultation.status === CONSULTATION_STATUS.IN_ROOM
    ) {
      if (consultation.specialist_id === user?.id || !consultation.specialist_id) return true

      if (consultation.type === CONSULTATION_TYPE.TELEINTERCONSULTATION) {
        return user?.id === consultation.requester_id
      }
    }

    return false
  }, [user, consultation, isStarted])

  const showButtonToStartVideo = useMemo(() => {
    if (
      consultation.care_type === CARE_TYPE.ELECTIVE_CARE &&
      consultation.status === CONSULTATION_STATUS.SCHEDULED &&
      isToday(consultation.start_date!)
    ) {
      if (consultation.type === CONSULTATION_TYPE.TELEINTERCONSULTATION) {
        return user?.id === consultation.requester_id
      }

      return (
        consultation.specialist_id === user?.id || consultation?.temp_specialist?.id === user?.id
      )
    }

    if (
      consultation.care_type === CARE_TYPE.EMERGENCY_CARE &&
      consultation.status === CONSULTATION_STATUS.WAITING
    ) {
      if (consultation.type === CONSULTATION_TYPE.TELEINTERCONSULTATION) {
        return user?.id === consultation.requester_id || !consultation.specialist_id
      }

      if (!consultation.specialist_id) return true
    }

    return false
  }, [consultation, user])

  const showButtonToEnterVideo = useMemo(() => {
    if (
      isStarted ||
      consultation.status !== CONSULTATION_STATUS.IN_ROOM ||
      consultation.care_type === CARE_TYPE.CHAT
    )
      return false

    if (consultation.specialist_id === user?.id || !consultation.specialist_id) return true

    if (consultation.type === CONSULTATION_TYPE.TELEINTERCONSULTATION) {
      return user?.id === consultation.requester_id
    }

    return false
  }, [user, consultation, isStarted])

  const redirectToDetails = (id: string) => {
    navigate(ROUTES.patient.details.replace(':id', id))
  }

  return (
    <>
      <BlankCard className='flex w-full items-center justify-between p-4'>
        <div className='flex items-center gap-4'>
          {consultation.patient && (
            <>
              <Avatar user={consultation?.patient} size='lg' />
              <div>
                <Link
                  target='_blank'
                  rel='noopener noreferrer'
                  to={ROUTES.patient.details.replace(':id', consultation.patient?.cpf as string)}
                >
                  <h2 className='flex cursor-pointer items-center gap-2 text-label-lg text-primaryBrand-primary'>
                    {consultation?.patient?.name} <ArrowSquareOut size={16} />
                  </h2>
                </Link>
                <div className='mt-3'>
                  <span className='text-label-sm text-neutralContent-disabled'>
                    {!!consultation?.patient?.birthdate
                      ? formatBirthDate(consultation?.patient?.birthdate)
                      : ''}{' '}
                  </span>
                  <span className='text-label-sm text-neutralContent-disabled'>
                    {!!consultation?.patient?.birthdate
                      ? `• ${calculateAge(consultation?.patient?.birthdate)} anos • `
                      : ''}{' '}
                  </span>
                  <span className='text-label-sm text-neutralContent-disabled'>
                    {' '}
                    {!!consultation?.patient?.height ? consultation?.patient?.height + 'cm ' : ''}
                  </span>
                  <span className='text-label-sm text-neutralContent-disabled'>
                    {!!consultation?.patient?.weight ? `• ${consultation?.patient?.weight}kg` : ''}
                  </span>
                </div>
                <div className='text-body-sm text-neutralContent-secondary'>
                  {consultation?.patient?.cpf}
                </div>
              </div>
            </>
          )}
        </div>

        <div className='flex flex-col gap-3'>
          {showButtonToStartVideo && (
            <ProtectedComponent
              permissions={[PermissionEnum.service_requester, PermissionEnum.service_specialist]}
            >
              <Button
                size='sm'
                label={t('actions.start_call')}
                iconLeft={<VideoCamera size={16} />}
                onClick={() => setOpenConfirmationModal(true)}
              />
            </ProtectedComponent>
          )}

          {showButtonToEnterVideo && (
            <ProtectedComponent
              permissions={[PermissionEnum.service_requester, PermissionEnum.service_specialist]}
            >
              <Button
                size='sm'
                onClick={onStart}
                label={t('actions.enter_call')}
                iconLeft={<VideoCamera size={16} />}
              />
            </ProtectedComponent>
          )}

          {showButtonToStartChat && (
            <ProtectedComponent
              permissions={[PermissionEnum.service_requester, PermissionEnum.service_specialist]}
            >
              <Button
                size='sm'
                label={t('actions.start_chat')}
                iconLeft={<ChatDots size={16} />}
                onClick={() => setOpenConfirmationModal(true)}
              />
            </ProtectedComponent>
          )}

          {showButtonToEnterChat && (
            <ProtectedComponent
              permissions={[PermissionEnum.service_requester, PermissionEnum.service_specialist]}
            >
              <Button
                size='sm'
                onClick={onStart}
                label={t('actions.enter_chat')}
                iconLeft={<ChatDots size={16} />}
              />
            </ProtectedComponent>
          )}

          {consultation.care_type !== CARE_TYPE.CHAT &&
            consultation.unit?.permissions?.includes(UnitPermissionsEnum.invite_patient) && (
              <ProtectedComponent permissions={[PermissionEnum.service_invite]}>
                <Button
                  size='sm'
                  variant='tertiary'
                  className='border-[1px] border-primaryBrand-primary'
                  onClick={() => setInvitePatient(true)}
                  label={t('actions.invite_patient')}
                  iconLeft={<PaperPlaneTilt size={16} />}
                />
              </ProtectedComponent>
            )}

          {consultation.type === CONSULTATION_TYPE.TELEINTERCONSULTATION &&
            consultation.care_type !== CARE_TYPE.CHAT &&
            isStarted &&
            consultation.unit?.permissions?.includes(UnitPermissionsEnum.invite_professional) && (
              <ProtectedComponent
                permissions={[PermissionEnum.service_requester, PermissionEnum.service_specialist]}
              >
                <Button
                  size='sm'
                  variant='tertiary'
                  onClick={() => setInviteProfessional(true)}
                  label={t('actions.invite_professional')}
                  iconLeft={<PaperPlaneTilt size={16} />}
                />
              </ProtectedComponent>
            )}
          {consultation.status === CONSULTATION_STATUS.FINISHED && (
            <Tag
              size='lg'
              color='green'
              variant='secondary'
              leftIcon={<Check />}
              label={t('finished_consultation')}
            />
          )}
        </div>
      </BlankCard>

      <ConfirmationStartCallModal
        onContinue={onStart}
        status={consultation.status}
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
      />
      {consultation.patient && (
        <InvitePatientModal
          isModalOpen={invitePatient}
          closeModal={() => setInvitePatient(false)}
          consultation={consultation}
        />
      )}

      <InviteProfessionalModal
        consultation_id={consultation?.id as string}
        isModalOpen={inviteProfessional}
        closeModal={() => setInviteProfessional(false)}
      />
    </>
  )
}

export default PatientDetails
