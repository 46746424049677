/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import Button from '../../../../../components/button'
import MevoService from '../../../../../services/mevo.service'
import { Loading } from 'semente-js'
import Modal from '../../../../../components/modal'
import {
  Eye,
  FileArrowDown,
  Microscope,
  Pill,
  Plus,
  Signpost,
  Couch,
  Article,
  Envelope
} from '@phosphor-icons/react'
import { MevoFile } from '../../../../../types'
import '@react-pdf-viewer/core/lib/styles/index.css'
import FileService from '../../../../../services/file.service'
import { format, fromZonedTime } from 'date-fns-tz'
import { useToastContext } from '../../../../../contexts/toast'

type Props = {
  consultationId: string
}

type MevoEvent = {
  data: any
}

const FILE_ICON: Record<string, React.ReactElement> = {
  EXAME: <Microscope />,
  ATESTADO: <Couch />,
  ENCAMINHAMENTO: <Signpost />,
  RECEITA: <Pill />
}

const VALID_EVENTS = ['cancel', 'excluded', 'prescricao']

const PrescriptionsTab: React.FC<Props> = ({ consultationId }) => {
  const [modalUrl, setModalUrl] = useState<string>()
  const [isLoadingFiles, setIsLoadingFiles] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [files, setFiles] = useState<MevoFile[]>([])
  const [pdfURL, setPdfURL] = useState<string>()
  const [currentFile, setCurrentFile] = useState<MevoFile>()
  const [fileToSendEmailId, setFileToSendEmailId] = useState<string>()
  const [fileToDownloadId, setFileToDownloadId] = useState<string>()
  const { toast } = useToastContext()

  useEffect(() => {
    if (!showModal) setModalUrl(undefined)
  }, [showModal])

  useEffect(() => {
    MevoService.getFiles(consultationId)
      .then(setFiles)
      .finally(() => setIsLoadingFiles(false))

    const handleEvent = (event: MevoEvent) => {
      if (event.data?.type && VALID_EVENTS.includes(event.data.type)) {
        setShowModal(false)
        setIsLoadingFiles(true)
        MevoService.getFiles(consultationId)
          .then(setFiles)
          .finally(() => setIsLoadingFiles(false))
      }
    }

    window.addEventListener('message', handleEvent)

    return () => window.removeEventListener('message', handleEvent)
  }, [consultationId])

  const handleNewPrescription = async () => {
    setIsLoading(true)
    setShowModal(true)
    try {
      setModalUrl(await MevoService.start(consultationId))
    } catch (err) {
      toast.error((err as Error).message)
      setShowModal(false)
    }
    setIsLoading(false)
  }

  const downloadPdf = async (file: MevoFile) => {
    setFileToDownloadId(file.id)
    try {
      const url = await FileService.getMevoFile(file.id!)
      const link = document.createElement('a')
      link.href = url
      link.download = (file.category || '') + format(file.created_at!, '_dd_MM_yyyy_HH:mm')
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(url)
      toast.success('Download realizado com sucesso')
    } catch (err) {
      toast.error((err as Error).message)
    }
    setFileToDownloadId(undefined)
  }

  const loadPdf = async (file: MevoFile) => {
    setCurrentFile(file)
    const data = await FileService.getMevoFile(file.id!)
    setPdfURL(data)
  }

  const sendByEmail = async (mevoFile: MevoFile) => {
    setFileToSendEmailId(mevoFile.id)
    try {
      const { data } = await FileService.sendMevoFileByEmail(mevoFile.id!)
      toast.success(data)
    } catch (err) {
      toast.error((err as Error).message)
    }
    setFileToSendEmailId(undefined)
  }

  const closeModal = () => {
    setCurrentFile(undefined)
    setPdfURL(undefined)
  }

  if (isLoadingFiles)
    return (
      <div className='flex w-full items-center justify-center'>
        <Loading />
      </div>
    )

  return (
    <div className='w-full'>
      {files.length > 0 ? (
        <Button
          size='sm'
          variant='secondary'
          iconLeft={<Plus />}
          className='ml-auto'
          label='Nova prescrição'
          onClick={handleNewPrescription}
        />
      ) : (
        <div className='mt-8 flex w-full flex-col items-center'>
          <div className='text-heading-xs'>
            {'Nenhuma prescrição médica emitida para este atendimento'}
          </div>

          <Button
            size='sm'
            iconLeft={<Plus />}
            className='mt-6'
            label='Nova prescrição'
            onClick={handleNewPrescription}
          />
        </div>
      )}

      <div className='mt-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'>
        {files.map(mevoFile => (
          <div
            key={mevoFile.id}
            className='rounded-lg border-[1px] border-neutralBorder-default p-4'
          >
            <div className='mb-2 flex w-fit items-center justify-center gap-2 rounded-lg bg-primaryBrand-light px-2 py-1 text-label-md text-primaryBrand-primary'>
              {(mevoFile.category && FILE_ICON[mevoFile.category]) || <Article />}
              {mevoFile.category}
            </div>
            <div className='text-label-lg'>Emitido por {mevoFile.prescriber?.name}</div>
            <div className='mt-1 text-body-sm text-neutralContent-secondary'>
              {format(fromZonedTime(mevoFile.created_at!, 'UTC'), ' dd/MM/yyyy HH:mm')}
            </div>

            <div className='mt-2 flex justify-end gap-2'>
              <Button
                size='xs'
                label='Enviar'
                variant='secondary'
                iconLeft={<Envelope />}
                onClick={() => sendByEmail(mevoFile)}
                isLoading={fileToSendEmailId === mevoFile.id}
              />
              <Button
                size='xs'
                label='Download'
                variant='secondary'
                iconLeft={<FileArrowDown />}
                onClick={() => downloadPdf(mevoFile)}
                isLoading={fileToDownloadId === mevoFile.id}
              />
              <Button
                size='xs'
                label='Visualizar'
                iconLeft={<Eye />}
                variant='secondary'
                onClick={() => loadPdf(mevoFile)}
              />
            </div>
          </div>
        ))}
      </div>

      <Modal isOpen={showModal} title='' className='relative w-[calc(100vw-80px)]'>
        {isLoading && (
          <div className='flex w-full items-center justify-center'>
            <Loading />
          </div>
        )}
        {modalUrl && <iframe id='mevo' src={modalUrl} className='h-[calc(100vh-160px)] w-full' />}
        <div className='absolute bottom-0 left-[calc(50vw-100px)] z-50 h-[80px] w-[150px] bg-white' />
      </Modal>

      <Modal
        onClose={closeModal}
        isOpen={!!currentFile}
        title={currentFile?.category}
        className=' w-[calc(100vw-80px)]'
      >
        {pdfURL ? (
          <iframe src={pdfURL} className='h-[calc(100vh-100px)] w-full' />
        ) : (
          <p>Carregando PDF...</p>
        )}
      </Modal>
    </div>
  )
}

export default PrescriptionsTab
