import { ReactNode } from 'react'

type Props = { className?: string; onClick?: VoidFunction; children: ReactNode; isActive?: boolean }

const Card: React.FC<Props> = ({ className, children, onClick, isActive }) => {
  return (
    <div className={`${className}`} onClick={onClick}>
      <div className='relative flex items-center overflow-hidden rounded-lg border border-neutralBorder-default'>
        {isActive ? (
          <div className='absolute bottom-0 left-0 top-0 w-2  bg-highlightBlue-pure' />
        ) : (
          <div className=' absolute bottom-0 left-0 top-0 w-2 bg-highlightOrange-pure' />
        )}
        <div className='ml-4'>{children}</div>
      </div>
    </div>
  )
}

export default Card
