import React, { useState } from 'react'
import Avatar from '../../../../components/avatar'
import BlankCard from '../../../../components/blank-card'
import Button from '../../../../components/button'
import Checkbox from '../../../../components/checkbox'
import Modal from '../../../../components/modal'
import { scaleNumberToTime } from '../../../../helpers/scaleTime'
import { Scale } from '../../../../types'
import { usePublishMultipleScales } from '../../../../hooks/useScale'
import { format } from 'date-fns'

interface Props {
  open: boolean
  onClose: () => void
  scales: Scale[]
  onComplete: () => void
}

const ConfirmPublishMultipleScaleModal = ({
  open: isModalOpen,
  onClose: closeModal,
  scales,
  onComplete
}: Props) => {
  const [selectedScaleIds, setSelectedScaleIds] = useState<string[]>([]) // Default: none selected

  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false)

  const onCompleteScale = () => {
    onComplete()
    closeModal()
  }

  const { mutate: publishScales, isPending } = usePublishMultipleScales(
    selectedScaleIds,
    onCompleteScale
  )

  const toggleSelectAll = () => {
    if (isSelectAllChecked) {
      setSelectedScaleIds([]) // Deselect all
    } else {
      setSelectedScaleIds(scales.filter(scale => scale.status === 'DRAFT').map(scale => scale.id)) // Select all DRAFT
    }
    setIsSelectAllChecked(!isSelectAllChecked)
  }
  const toggleScaleSelection = (scaleId: string) => {
    const updatedSelection = selectedScaleIds.includes(scaleId)
      ? selectedScaleIds.filter(id => id !== scaleId)
      : [...selectedScaleIds, scaleId]

    setSelectedScaleIds(updatedSelection)
    setIsSelectAllChecked(
      updatedSelection.length === scales.filter(scale => scale.status === 'DRAFT').length
    )
  }

  return (
    <Modal
      title={'Publicar escalas'}
      isOpen={isModalOpen}
      onClose={() => {
        closeModal()
      }}
    >
      <div className='mt-4 h-[calc(100vh-450px)] w-96 gap-4 overflow-y-scroll'>
        {/* Select All Checkbox */}
        <div className='flex items-center gap-2'>
          <Checkbox
            isChecked={isSelectAllChecked}
            isIndeterminate={
              selectedScaleIds.length > 0 &&
              selectedScaleIds.length < scales.filter(scale => scale.status === 'DRAFT').length
            }
            size='md'
            onClick={toggleSelectAll}
          />
          <span>Selecionar todas</span>
        </div>

        <div>
          {scales
            .filter(scale => scale.status === 'DRAFT')
            .map(scale => (
              <BlankCard key={scale.id.toString()} className='mx-1 my-1 h-28'>
                <div className='flex gap-2'>
                  <div className='mr-2 mt-1'>
                    <Checkbox
                      isChecked={selectedScaleIds.includes(scale.id)}
                      size='sm'
                      onClick={() => toggleScaleSelection(scale.id)}
                    />
                  </div>
                  <div>
                    <span className='text-label-md'>{scale.specialty?.name}</span>

                    {scale.time_intervals.map(interval => (
                      <div
                        className='ml-2 flex gap-2 text-label-sm text-neutralContent-tertiary'
                        key={interval.id}
                      >
                        <span className='text-label-sm text-neutralContent-tertiary'>
                          {format(new Date(scale.date + 'T00:00:00'), 'dd/MM/yyyy')}
                        </span>
                        {scaleNumberToTime(interval.start_time)}-
                        {scaleNumberToTime(interval.end_time)}
                      </div>
                    ))}
                    <>
                      {scale.specialists.map(specialist => (
                        <div key={specialist.id} className='mx-1 my-1 flex items-center gap-2'>
                          <Avatar
                            name={specialist.specialist.name}
                            size='xs'
                            color='neutral'
                            imageID={specialist.specialist.image_id}
                          />
                          <span className='text-label-xs'>{specialist.specialist.name}</span>
                        </div>
                      ))}
                    </>
                  </div>
                </div>
              </BlankCard>
            ))}
        </div>
      </div>

      {/* Action Buttons */}
      <div className='mt-4 flex w-[400px] justify-between'>
        <div className='flex w-full items-center justify-end'>
          <Button
            type='button'
            label='Cancelar'
            size='sm'
            variant='tertiary'
            onClick={closeModal}
          />
          <Button
            onClick={() => publishScales()}
            label='Publicar escalas'
            size='sm'
            variant='primary'
            state={selectedScaleIds.length > 0 ? 'enable' : 'disable'}
            isLoading={isPending}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmPublishMultipleScaleModal
