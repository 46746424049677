import { format } from 'date-fns'
import Avatar from '../../../../components/avatar'
import { useAuth } from '../../../../contexts/auth'

interface ChatMessageProps {
  userId: string
  userName: string
  message: string
  hour: string
  image_id?: string
}

export const ChatMessage: React.FC<ChatMessageProps> = ({ userId, userName, message, hour }) => {
  const { user } = useAuth()

  const isSameLoggedUser = user?.id === userId

  const containerClasses = `my-4 flex flex-col ${isSameLoggedUser ? 'text-right' : 'text-left'}`
  const nameClasses = `text-label-xs font-bold ${isSameLoggedUser ? 'mr-14 text-right' : 'ml-14 text-left '}`
  const messageClasses = `rounded-lg p-2 text-body-xs w-full ${isSameLoggedUser ? 'bg-neutralBackground-secondary order-1' : 'bg-highlightGreen-light order-2'}`
  const avatarClasses = `flex-shrink-0 ${isSameLoggedUser ? 'order-2' : 'order-1'}`
  const flexClasses = `mt-2 w-full flex items-start gap-2 ${!!isSameLoggedUser ? 'flex-row justify-end' : 'justify-start'}`
  const hourClasses = `flex ${isSameLoggedUser ? 'justify-start' : 'justify-end'} text-body-xs text-neutralContent-disabled`

  return (
    <div className={containerClasses}>
      <span className={nameClasses}>{userName}</span>
      <div className={flexClasses}>
        <span className={messageClasses}>{message}</span>
        <Avatar
          imageID={user?.image_id}
          className={avatarClasses}
          name={userName}
          size='md'
          layout='circle'
        />
      </div>
      <span className={hourClasses}>{format(new Date(hour), 'HH:mm')}</span>
    </div>
  )
}
