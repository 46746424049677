import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useCallback
} from 'react'
import AuthService from '../../services/auth.service'
import StorageService from '../../services/storage.service'
import { User } from '../../types'
import { Button, Modal, Text } from 'semente-js'
import PermissionEnum from '../../types/permissions'
import ClientService from '../../services/client.service'
import chroma from 'chroma-js'
import FileService from '../../services/file.service'
import Logo from '../../assets/images/logo.png'
import UnitPermissionsEnum from '../../types/unit-permissions'

interface AuthContextType {
  logo?: string
  user: User | null
  logout: () => void
  client?: ClientDomain
  hasUnitPermission: (permission: UnitPermissionsEnum) => boolean
  reloadCurrentUser: () => Promise<void>
  hasAccess: (permission: PermissionEnum) => boolean
  login: (values: { email: string; password: string }) => Promise<User | undefined>
  isLoadingUser: boolean
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

interface AuthProviderProps {
  children: ReactNode
}

export type ClientDomain = {
  id: string
  name: string
  image_id?: string
  primary_color: string
}

export const ClientDomainProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoadingClientDomain, setIsLoadingClientDomain] = useState(true)
  const [isLoadingLocalUser, setIsLoadingLocalUser] = useState(true)
  const [user, setUser] = useState<User | null>(null)
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [client, setClient] = useState<ClientDomain>()
  const [logo, setLogo] = useState<string>()

  const reloadCurrentUser = useCallback(async () => {
    try {
      const { data } = await AuthService.getMyUser()
      setUser(data)
    } catch {
      StorageService.removeToken()
    }
    setIsLoadingLocalUser(false)
  }, [])

  useEffect(() => {
    const token = StorageService.getToken()
    if (token) {
      reloadCurrentUser()
    } else {
      setIsLoadingLocalUser(false)
    }
  }, [reloadCurrentUser])

  useEffect(() => {
    ClientService.getByDomain()
      .then(client_domain => {
        setClient(client_domain)
        if (client_domain.image_id)
          FileService.get(client_domain.image_id).then(setLogo).catch(console.log)
        else setLogo(Logo)

        if (client_domain.primary_color) {
          const light = chroma(client_domain.primary_color).brighten(4).hex()
          const dark = chroma(client_domain.primary_color).darken(1).hex()

          document.documentElement.style.setProperty('--color-primary', client_domain.primary_color)
          document.documentElement.style.setProperty('--color-primary-light', light)
          document.documentElement.style.setProperty('--color-primary-dark', dark)
        }
      })
      .catch(() => {
        setLogo(Logo)
      })
      .finally(() => setIsLoadingClientDomain(false))
  }, [])

  const login = async (values: { email: string; password: string }) => {
    const loginResponse = await AuthService.login(values, client?.id)
    StorageService.saveToken(loginResponse.data.token)
    const userResponse = await AuthService.getMyUser()
    setUser(userResponse.data)
    return userResponse.data
  }

  const logout = () => {
    setShowLogoutModal(true)
  }

  const cancelLogout = () => {
    setShowLogoutModal(false)
  }

  const confirmLogout = () => {
    setShowLogoutModal(false)
    StorageService.removeToken()
    setUser(null)
  }

  const hasUnitPermission = (permission: UnitPermissionsEnum) => {
    return !!user?.clients?.some(client =>
      client.units?.some(({ unit }) => unit?.permissions?.includes(permission))
    )
  }

  const hasAccess = (permission: PermissionEnum) => {
    return !!user?.profile?.permissions?.some(item => item === permission)
  }

  if (isLoadingLocalUser || isLoadingClientDomain) {
    return <>Loading...</>
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        client,
        logo,
        login,
        logout,
        hasAccess,
        hasUnitPermission,
        reloadCurrentUser,
        isLoadingUser: isLoadingLocalUser
      }}
    >
      {children}
      <Modal isOpen={showLogoutModal} ariaLabelledby='Logout'>
        <div className='flex w-full flex-col'>
          <Text className='heading-md'>Você quer sair?</Text>
          <div className='mt-auto flex w-full gap-4'>
            <Button
              label='Cancelar'
              className='flex-1 bg-gray-500 hover:bg-gray-400'
              onClick={cancelLogout}
            />
            <Button
              label='Sair'
              onClick={confirmLogout}
              className='hover:bg-primaryBrand-light400 flex-1 bg-primaryBrand-primary'
            />
          </div>
        </div>
      </Modal>
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
