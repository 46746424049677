const anamense_form_enUS = {
  title: 'Create New Template',

  fields: {
    client: 'Client',
    unit: 'Unit',
    specialty: 'Specialty',
    add_question: 'Add Question'
  },
  select_an_option: 'Select',
  banner_warn: {
    title: 'This form is just a preview',
    message:
      'Create the template so it will be visible to the specified clients, units, and specialties.'
  },
  actions: {
    save: 'Create Template',
    add: 'Add Question',
    select_an_option: 'Select an Option',
    cancel: 'Cancel',
    add_question: 'Add',
    preview_template: 'Preview Template'
  }
}

export default anamense_form_enUS
