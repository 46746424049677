import { useTranslation } from 'react-i18next'
import { CARE_TYPE, Consultation, CONSULTATION_STATUS } from '../../../../types'
import BlankCard from '../../../../components/blank-card'
import { format, isPast } from 'date-fns'
import { useGetConsultations } from '../../../../hooks/usePatient'
import Tag from '../../../../components/tag'
import { CalendarBlank, Chats, Hospital, IdentificationCard, Webcam } from '@phosphor-icons/react'
import { getTimeDiff } from '../../../../helpers/getTimeDiff'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../../routes/path'

const Consultations: React.FC<{
  id: string
}> = ({ id }) => {
  const { t } = useTranslation('patient_detail')
  const { data, isFetching } = useGetConsultations(id as string)
  const navigate = useNavigate()

  const handleDetail = (consultation: Consultation) => {
    const url = ROUTES.consultation.details
      .replace(':id', consultation?.id as string)
      .replace(':care_type', consultation.care_type!)

    const queryParams = new URLSearchParams({ patient: id })

    navigate(`${url}?${queryParams.toString()}`)
  }

  return (
    <div className=''>
      {isFetching ? (
        <div className='flex gap-3'>
          {Array.from({ length: 4 }, (_, index_row) => (
            <div
              key={index_row}
              className='h-36 w-80  animate-pulse space-y-4 rounded-xl border border-neutralBorder-default p-4'
            >
              <div className='h-3 w-3/4 rounded bg-neutralBorder-default' />
              <div className='h-3 w-3/4 rounded bg-neutralBorder-default' />
              <div className='h-3 w-3/4 rounded bg-neutralBorder-default' />
            </div>
          ))}
        </div>
      ) : (
        <div className=' mx-[-16px]  flex h-[calc(100dvh-380px)] flex-wrap  gap-2 overflow-y-auto overflow-x-hidden'>
          {!!data?.consultations && data?.consultations.length > 0 ? (
            data?.consultations?.map((consultation, index) => (
              <BlankCard
                onClick={() => handleDetail(consultation)}
                key={index.toString()}
                className='h-36 w-80 '
              >
                <div className='flex justify-between gap-3'>
                  <div className='flex gap-2'>
                    <div className='inline-flex h-6'>
                      <Tag
                        label={
                          consultation.care_type === CARE_TYPE.CHAT ? (
                            <Chats size={12} />
                          ) : (
                            <Webcam size={12} />
                          )
                        }
                        variant='secondary'
                        color={consultation.care_type === CARE_TYPE.ELECTIVE_CARE ? 'blue' : 'red'}
                      />
                    </div>
                    <div>
                      <span
                        className={`text-label-md ${consultation.care_type === CARE_TYPE.ELECTIVE_CARE ? 'text-primaryBrand-primary' : 'text-highlightRed-pure'}`}
                      >
                        {t(`types.${consultation.care_type}`)}
                      </span>
                    </div>
                    <div>
                      <Tag
                        size={'xs'}
                        color='gray'
                        variant='tertiary'
                        label={'#' + consultation.code}
                      />
                    </div>
                  </div>
                  <div className='inline-flex h-5'>
                    {consultation.status === CONSULTATION_STATUS.IN_ROOM ? (
                      <Tag label='Em andamento' color='green' size='xs' />
                    ) : isPast(new Date(consultation.start_date || consultation.created_at!)) ? (
                      <Tag
                        label={
                          getTimeDiff(
                            new Date(consultation.start_date || consultation.created_at!),
                            new Date()
                          ) + ' em espera'
                        }
                        color='red'
                        size='xs'
                      />
                    ) : (
                      <Tag
                        label={
                          'Iniciará em ' +
                          getTimeDiff(
                            new Date(consultation.start_date || consultation.created_at!),
                            new Date()
                          )
                        }
                        color='blue'
                        size='xs'
                      />
                    )}
                  </div>
                </div>

                <div className='mt-3 flex-col gap-2'>
                  <div className='flex items-center gap-2'>
                    <IdentificationCard size={16} />
                    <span className='text-body-sm'>{consultation.specialty?.name ?? 'n/a'}</span>
                  </div>

                  <div className='flex items-center gap-2'>
                    <CalendarBlank size={16} />
                    <span className='text-body-sm'>
                      {consultation.created_at
                        ? format(new Date(consultation.created_at), 'dd/MM/yyyy')
                        : 'n/a'}
                    </span>
                  </div>

                  <div className='flex items-center gap-2'>
                    <Hospital size={16} />
                    <span className='text-body-sm'>{consultation.unit?.name ?? 'n/a'}</span>
                  </div>
                </div>
              </BlankCard>
            ))
          ) : (
            <>{t('no_consultations')}</>
          )}
        </div>
      )}
    </div>
  )
}

export default Consultations
