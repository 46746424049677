import React from 'react'
import ClosureReasonConsultation from '../../../../components/dropdown/closure-reason-consultation'
import { ClosureReason, CONSULTATION_STATUS } from '../../../../types'
import { useFinishConsultation } from '../../../../hooks/useConsultation'
import { ArrowRight, Check, DoorOpen } from '@phosphor-icons/react'
import { useToastContext } from '../../../../contexts/toast'
import Button from '../../../../components/button'
import Modal from '../../../../components/modal'
import { useTranslation } from 'react-i18next'

interface ConfirmationFinishCallModalProps {
  open: boolean
  onClose: () => void
  setIsLoading: (value: boolean) => void
  onFinish?: () => void
  id: string
}

interface ConfirmationStartCallModalProps {
  open: boolean
  onClose: () => void
  onContinue: () => void
  status?: CONSULTATION_STATUS
}

interface ConfirmationExitCallModalProps {
  open: boolean
  onClose: () => void
  onExit: () => void
}

export const ConfirmationFinishCallModal: React.FC<ConfirmationFinishCallModalProps> = ({
  onClose,
  open,
  onFinish
}) => {
  const { t } = useTranslation('consultation_details')

  return (
    <Modal title={t('modal_confirmation_finish_call.title')} isOpen={open} onClose={onClose}>
      <div className='h-[500px] w-[380px] max-w-[90vw]'>
        <p className='text-body-md text-neutralContent-tertiary'>
          {t('modal_confirmation_finish_call.description')}
        </p>

        <div className='mt-4 flex justify-end'>
          <Button
            type='button'
            label={t('actions.cancel')}
            size='md'
            variant='tertiary'
            onClick={onClose}
          />
          <Button
            size='md'
            label={t('actions.finish')}
            variant='primary'
            state='error'
            onClick={() => {
              onFinish?.()
            }}
            iconLeft={<Check />}
          />
        </div>
      </div>
    </Modal>
  )
}

export const ConfirmationStartCallModal: React.FC<ConfirmationStartCallModalProps> = ({
  open,
  status,
  onClose,
  onContinue
}) => {
  const { t } = useTranslation('consultation_details')

  return (
    <Modal title={t(`modal_confirmation.title_${status}`)} isOpen={open} onClose={onClose}>
      <div className='w-[380px] max-w-[90vw]'>
        <p className='text-body-md text-neutralContent-tertiary'>
          {t(`modal_confirmation.description_${status}`)}
        </p>

        <div className='mt-4 flex justify-end'>
          <Button
            type='button'
            label={t('actions.cancel')}
            size='md'
            variant='tertiary'
            onClick={onClose}
          />
          <Button
            size='md'
            label={t('actions.start')}
            variant='primary'
            onClick={() => {
              onContinue()
              onClose()
            }}
            iconRight={<ArrowRight />}
          />
        </div>
      </div>
    </Modal>
  )
}

export const ConfirmationExitCallModal: React.FC<ConfirmationExitCallModalProps> = ({
  onClose,
  onExit,
  open
}) => {
  const { t } = useTranslation('consultation_details')

  return (
    <Modal title={t('modal_confirmation_exit_call.title')} isOpen={open} onClose={onClose}>
      <div className='w-[380px] max-w-[90vw]'>
        <p className='text-body-md text-neutralContent-tertiary'>
          {t('modal_confirmation_exit_call.description')}
        </p>

        <div className='mt-4 flex justify-end'>
          <Button
            type='button'
            label={t('actions.cancel')}
            size='md'
            variant='tertiary'
            onClick={onClose}
          />
          <Button
            size='md'
            label={t('actions.exit')}
            variant='primary'
            onClick={() => {
              onExit()
              onClose()
            }}
            iconLeft={<DoorOpen />}
          />
        </div>
      </div>
    </Modal>
  )
}

export const FinishConsultationModal: React.FC<ConfirmationFinishCallModalProps> = ({
  onClose,
  open,
  setIsLoading,
  onFinish,
  id
}) => {
  const { t } = useTranslation('consultation_details')
  const { toast } = useToastContext()
  const [closure_reason, setClosureReason] = React.useState<string>(ClosureReason.REALIZED)
  const finishConsultationMutation = useFinishConsultation(closure_reason)

  const onFinishCall = () => {
    setIsLoading(true)

    finishConsultationMutation.mutate(id!, {
      onSuccess: () => {
        setIsLoading(false)
        toast.success('Consulta finalizada com sucesso!')
        onFinish?.()
      },
      onError: () => {
        setIsLoading(false)
        toast.error(t('Erro ao finalizar a consulta'))
      }
    })
  }

  return (
    <Modal
      title={t('modal_confirmation_finish_call.title')}
      isOpen={open}
      onClose={onClose}
      className='overflow-visible'
    >
      <div className='w-[380px] max-w-[90vw]'>
        <p className='text-body-md text-neutralContent-tertiary'>
          {t('modal_confirmation_finish_call.description')}
        </p>

        <div className='mt-4'>
          <ClosureReasonConsultation
            name='closureReason'
            placeholder={t('actions.select')}
            label={t('modal_confirmation_finish_call.reason_label')}
            value={closure_reason}
            onChangeValue={setClosureReason}
          />
        </div>

        <div className='mt-4 flex justify-end'>
          <Button
            type='button'
            label={t('actions.cancel')}
            size='md'
            variant='tertiary'
            onClick={onClose}
          />
          <Button
            size='md'
            label={t('actions.finish')}
            variant='primary'
            state='error'
            onClick={() => {
              onFinishCall()
              onClose()
            }}
            iconLeft={<Check />}
          />
        </div>
      </div>
    </Modal>
  )
}
