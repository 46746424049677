import { Patient, User } from '../types'
import api from './api.service'

class AuthService {
  static login(body: { email: string; password: string }, client_id?: string) {
    console.log({ client_id })
    if (client_id) return api.post<{ token: string }>('/auth/login/' + client_id, body)
    else return api.post<{ token: string }>('/auth/login', body)
  }

  static patientLogin(body: { consultationId: string; birthdate: string }) {
    return api.post<{ token: string; patient: Patient }>('/auth/patient/validate', body)
  }

  static professionalLogin(body: { consultationId: string; birthdate: string; email: string }) {
    return api.post<{ token: string }>('/auth/professional/validate', body)
  }

  static sendValidationCode(body: { email: string }) {
    return api.post<void>('/auth/send-code', body)
  }

  static validateCode(body: { email: string; code: string }) {
    return api.post<{ token: string }>('/auth/validate-code', body)
  }

  static getMyUser() {
    return api.get<User>('/auth/me')
  }
}

export default AuthService
