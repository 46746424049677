/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next'
import Content from '../../../components/content'
import Avatar from '../../../components/avatar'
import { useAuth } from '../../../contexts/auth'
import Button from '../../../components/button'
import { useRef, useState } from 'react'
import { useToastContext } from '../../../contexts/toast'
import UserService from '../../../services/user.service'
import FileService from '../../../services/file.service'
import ProtectedComponent from '../../../components/protected'
import PermissionEnum from '../../../types/permissions'
import { MapPin, PencilSimple } from '@phosphor-icons/react'
import { USER_FORM_STEPS } from '../../users/user-form'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../routes/path'
import { InfoDetail } from '../../users/user-details'
import { genders, sexes } from '../../users/user-form/user-personal-information-form'
import { formatBirthDate } from '../../../helpers/formatBirthDate'
import ButtonSkeleton from '../../../components/button/skeleton'

const MyAccountPage = () => {
  const { t } = useTranslation('my_account')
  const { user, reloadCurrentUser, isLoadingUser } = useAuth()
  const [loadingImage, setLoadingImage] = useState(false)
  const [_, setFileImage] = useState<File | string>('')
  const { toast } = useToastContext()
  const navigate = useNavigate()
  const fileInputRef = useRef<HTMLInputElement>(null)

  const onEdit = (step: USER_FORM_STEPS) => {
    const url = ROUTES.user.edit.replace(':id', user?.id as string).replace(':form_step', step)

    const queryParams = new URLSearchParams({ goToMyAccount: 'true' })

    navigate(`${url}?${queryParams.toString()}`)
  }

  const sexFormatted = (): string => {
    if (user?.sex) {
      if (sexes.includes(user?.sex)) return t(`sessions.personal_info.${user?.sex}`)
      return user?.sex
    }
    return '-'
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp']
    if (!validImageTypes.includes(file.type)) {
      toast.error('Por favor, selecione um arquivo de imagem válido (JPEG, PNG, ou WEBP).')
      return
    }

    try {
      setLoadingImage(true)
      const formData = new FormData()
      formData.append('file', file)
      const uploadedFile = await FileService.upload(formData)
      if (user) {
        await UserService.update(user.id!, { ...user, image_id: uploadedFile.id })
      }
      toast.success('Imagem atualizada com sucesso!')
      reloadCurrentUser()
    } catch (_error) {
      toast.error('Erro ao atualizar a imagem.')
    } finally {
      setLoadingImage(false)
    }
  }

  return (
    <Content title={t('title')}>
      <div className='mx-[-16px] grid h-[calc(100dvh-280px)] grid-cols-1 content-start gap-x-12 gap-y-16 overflow-y-auto overflow-x-hidden px-4 pb-20 sm:grid-cols-2'>
        {/* Header Image */}
        <div className='flex items-center gap-3'>
          <div className='mb-[-20px]'>
            <Avatar
              imageID={user?.image_id}
              user={user}
              size='md'
              className='mb-6'
              setFileImage={setFileImage}
            />
          </div>
          <span className='text-heading-md'>{user?.name}</span>
          <input
            type='file'
            accept='image/*'
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <Button
            label={t('actions.update_image')}
            variant='secondary'
            size='sm'
            onClick={() => fileInputRef.current?.click()}
            isLoading={loadingImage}
          />
        </div>

        {/* User Info */}
        <div className='mt-10 w-full sm:col-span-2'>
          <div className='flex w-full items-center text-heading-sm text-neutralContent-primary '>
            {t('sessions.personal_info.title')}
            {isLoadingUser ? (
              <ButtonSkeleton />
            ) : (
              <ProtectedComponent permission={PermissionEnum.register_user}>
                <Button
                  size='sm'
                  variant='secondary'
                  className='ml-auto'
                  label={t('actions.update')}
                  onClick={() => onEdit(USER_FORM_STEPS.PERSONAL_INFORMATION)}
                  iconLeft={<PencilSimple size={16} />}
                />
              </ProtectedComponent>
            )}
          </div>

          <div className='mt-4 grid grid-cols-1 rounded-xl border px-4 sm:col-span-2 sm:grid-cols-2'>
            <InfoDetail
              isLoading={isLoadingUser}
              label={t('sessions.personal_info.birthdate')}
              value={user?.birthdate ? formatBirthDate(user.birthdate) : '-'}
            />
            <InfoDetail
              isLoading={isLoadingUser}
              label={t('sessions.personal_info.mother_name')}
              value={user?.mother_name || t('sessions.personal_info.mother_unknown')}
            />
            <InfoDetail
              isLoading={isLoadingUser}
              label={t('sessions.personal_info.sex')}
              value={sexFormatted()}
            />
            <InfoDetail
              isLoading={isLoadingUser}
              label={t('sessions.personal_info.gender')}
              value={
                genders.includes(user?.gender as string)
                  ? t('sessions.personal_info.gender')
                  : (user?.gender as string)
              }
            />
            <InfoDetail
              isLoading={isLoadingUser}
              label={t('sessions.personal_info.race')}
              value={!!user?.race ? t(`sessions.personal_info.${user?.race}`) : ''}
            />
            <InfoDetail
              isLoading={isLoadingUser}
              label={t('sessions.personal_info.nationality')}
              value={user?.nationality}
            />
            <InfoDetail
              isLoading={isLoadingUser}
              label={t('sessions.personal_info.birth_state')}
              value={user?.birth_state}
            />
            <InfoDetail
              isLoading={isLoadingUser}
              label={t('sessions.personal_info.birth_city')}
              value={user?.birth_city}
            />
          </div>
        </div>

        {/* CONTACT AND DOCUMENTS */}

        <div className='mt-10 w-full'>
          <div className='flex w-full items-center text-heading-sm text-neutralContent-primary'>
            {t('sessions.contact.title')}
            {isLoadingUser ? (
              <ButtonSkeleton />
            ) : (
              <ProtectedComponent permission={PermissionEnum.register_user}>
                <Button
                  size='sm'
                  variant='secondary'
                  className='ml-auto'
                  label={t('actions.update')}
                  onClick={() => onEdit(USER_FORM_STEPS.CONTACT)}
                  iconLeft={<PencilSimple size={16} />}
                />
              </ProtectedComponent>
            )}
          </div>
          <div className='mt-4 rounded-xl border px-4'>
            <InfoDetail
              isLoading={isLoadingUser}
              label={t('sessions.contact.email')}
              value={user?.email}
              className='border-b'
            />
            <InfoDetail
              isLoading={isLoadingUser}
              label={t(`sessions.contact.${user?.phone_type}`)}
              value={user?.phone}
            />
          </div>
        </div>
        <div className='mt-10 '>
          <div className='flex w-full items-center text-heading-sm text-neutralContent-primary'>
            {t('sessions.documents.title')}
            {isLoadingUser ? (
              <ButtonSkeleton />
            ) : (
              <ProtectedComponent permission={PermissionEnum.register_user}>
                <Button
                  size='sm'
                  variant='secondary'
                  className='ml-auto'
                  label={t('actions.update')}
                  onClick={() => onEdit(USER_FORM_STEPS.DOCUMENTS)}
                  iconLeft={<PencilSimple size={16} />}
                />
              </ProtectedComponent>
            )}
          </div>
          <div className='mt-4 rounded-xl border px-4'>
            <InfoDetail
              isLoading={isLoadingUser}
              label={t('sessions.documents.document')}
              value={user?.document}
              className='border-b'
            />
            <InfoDetail
              isLoading={isLoadingUser}
              label={t(`sessions.documents.cns`)}
              value={user?.cns}
            />
          </div>
        </div>

        {/* ADDRESS */}
        <div className='sm:col-span-2'>
          <div className='flex w-full items-center text-heading-sm text-neutralContent-primary '>
            {t('sessions.address.title')}
            {isLoadingUser ? (
              <ButtonSkeleton />
            ) : (
              <ProtectedComponent permission={PermissionEnum.register_user}>
                <Button
                  size='sm'
                  variant='secondary'
                  className='ml-auto'
                  label={t('actions.update')}
                  onClick={() => onEdit(USER_FORM_STEPS.ADDRESS)}
                  iconLeft={<PencilSimple size={16} />}
                />
              </ProtectedComponent>
            )}
          </div>
          <div className='mt-4 flex items-center rounded-xl border px-4'>
            <MapPin size={24} className='mr-4' />
            <InfoDetail
              isLoading={isLoadingUser}
              label={`${user?.address || '-'}, ${user?.address_number || '-'} ${user?.complement}`}
              value={`${user?.district || '-'}, ${user?.city || '-'} - ${user?.state || '-'}, ${user?.cep || '-'}`}
            />
          </div>
        </div>

        {/* PROFESSIONAL INFO */}
        <div className='sm:col-span-2'>
          <div className='flex w-full items-center text-heading-sm text-neutralContent-primary'>
            {t('sessions.professional_info.title')}
            {isLoadingUser ? (
              <ButtonSkeleton />
            ) : (
              <ProtectedComponent permission={PermissionEnum.register_user}>
                <Button
                  size='sm'
                  variant='secondary'
                  className='ml-auto'
                  label={t('actions.update')}
                  onClick={() => onEdit(USER_FORM_STEPS.PROFESSIONAL_INFORMATION)}
                  iconLeft={<PencilSimple size={16} />}
                />
              </ProtectedComponent>
            )}
          </div>

          <div className='mt-6 text-heading-xs text-neutralContent-primary'>
            {t('sessions.professional_info.specialties')}
          </div>

          {!isLoadingUser && (
            <div className='mt-4 rounded-xl border px-4'>
              {user?.specialties?.map(({ specialty }) => (
                <InfoDetail
                  isLoading={isLoadingUser}
                  key={specialty?.id}
                  className='border-b'
                  label={specialty?.name || ''}
                  value={`${specialty?.council?.name} (${specialty?.council?.document_name})`}
                />
              ))}
            </div>
          )}

          <div className='mt-9 text-heading-xs text-neutralContent-primary'>
            {t('sessions.professional_info.records')}
          </div>

          {!isLoadingUser && (
            <div className='mt-4 rounded-xl border px-4'>
              {user?.councils?.map(({ council, document, uf }) => (
                <InfoDetail
                  isLoading={isLoadingUser}
                  key={council?.id}
                  label={`${council?.name} (${council?.document_name})`}
                  value={`${document}/${uf ? uf : ''}`}
                  className='border-b'
                />
              ))}
              {user?.specialties
                ?.filter(item => item?.specialty?.document_name)
                .map(({ specialty, document }) => (
                  <InfoDetail
                    isLoading={isLoadingUser}
                    key={specialty?.id}
                    label={`${specialty?.name} (${specialty?.document_name})`}
                    value={`${document || 'Não informado'}`}
                    className='border-b'
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    </Content>
  )
}

export default MyAccountPage
