import UserFormProfessionalInformation from './user-professional-information-form'
import UserFormPersonalInformation from './user-personal-information-form'
import { useToastContext } from '../../../contexts/toast'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import UserService from '../../../services/user.service'
import UserAddressContact from './user-address-form'
import UserDocumentForm from './user-document-form'
import UserContactForm from './user-contact-form'
import UserProfileForm from './user-profile-form'
import Content from '../../../components/content'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../../routes/path'
import { useEffect, useState } from 'react'
import { User } from '../../../types'
import { validate } from 'uuid'
import FileService from '../../../services/file.service'
import { useAuth } from '../../../contexts/auth'

export enum USER_FORM_STEPS {
  CONTACT = 'CONTACT',
  ADDRESS = 'ADDRESS',
  PROFILE = 'PROFILE',
  DOCUMENTS = 'DOCUMENTS',
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  PROFESSIONAL_INFORMATION = 'PROFESSIONAL_INFORMATION'
}

const UserFormPage: React.FC = () => {
  const navigate = useNavigate()
  const { id, form_step } = useParams<{ id: string; form_step: USER_FORM_STEPS }>()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation('user_form')
  const { toast } = useToastContext()
  const { reloadCurrentUser } = useAuth()
  const goToMyAccount = searchParams.get('goToMyAccount')

  const isUpdate = validate(id || '')
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState<Partial<User>>({})
  const [step, setStep] = useState<USER_FORM_STEPS>(
    form_step || USER_FORM_STEPS.PERSONAL_INFORMATION
  )

  useEffect(() => {
    if (id) {
      setIsLoading(true)
      UserService.getById(id)
        .then(res => res && setUser(res))
        .finally(() => setIsLoading(false))
    }
  }, [id])

  const handleChange = async (values: Partial<User>) => {
    try {
      await UserService.validateFields({ id, ...user, ...values })

      switch (step) {
        case USER_FORM_STEPS.PERSONAL_INFORMATION:
          setStep(USER_FORM_STEPS.CONTACT)
          setUser({ ...user, ...values })
          break
        case USER_FORM_STEPS.CONTACT:
          setStep(USER_FORM_STEPS.ADDRESS)
          setUser({ ...user, ...values })
          break
        case USER_FORM_STEPS.ADDRESS:
          setStep(USER_FORM_STEPS.DOCUMENTS)
          setUser({ ...user, ...values })
          break
        case USER_FORM_STEPS.DOCUMENTS:
          setStep(USER_FORM_STEPS.PROFESSIONAL_INFORMATION)
          setUser({ ...user, ...values })
          break
        case USER_FORM_STEPS.PROFESSIONAL_INFORMATION:
          setStep(USER_FORM_STEPS.PROFILE)
          setUser({ ...user, ...values })
          break
        default:
          setUser({ ...user, ...values })
          break
      }
    } catch (e) {
      toast.error((e as Error).message)
    }
  }

  const onUpdate = async (values: Partial<User>) => {
    let file: { id?: string } = {}
    try {
      if (values.image) {
        const formData = new FormData()
        const response = await fetch(values.image)
        formData.append('file', await response.blob())
        file = await FileService.upload(formData)
      }

      if (id) {
        setIsSaving(true)
        await UserService.update(id, { ...user, ...values, image_id: file?.id })

        setIsSaving(false)
        toast.success('Usuário atualizado com sucesso!!')

        if (goToMyAccount === 'true') {
          return navigate(ROUTES.myAccount)
        }

        navigate(ROUTES.user.details.replace(':id', id))
      }
    } catch (error) {
      toast.error((error as Error).message)
    } finally {
      reloadCurrentUser()
    }
  }

  const onFinish = async () => {
    let file: { id?: string } = {}

    try {
      if (user.image) {
        const formData = new FormData()
        const response = await fetch(user.image)
        formData.append('file', await response.blob())
        file = await FileService.upload(formData)
      }

      if (isUpdate) {
        return onUpdate({})
      }
      setIsSaving(true)
      const {
        data: { id }
      } = await UserService.create({ ...user, image_id: file?.id })
      setIsSaving(false)
      if (id) {
        toast.success('Usuário cadastrado com sucesso!!')

        navigate(ROUTES.user.details.replace(':id', id))
      }
    } catch (error) {
      toast.error((error as Error).message)
    } finally {
      reloadCurrentUser()
    }
  }

  const handleBack = () => {
    if (id) {
      if (goToMyAccount === 'true') {
        return navigate(ROUTES.myAccount)
      }

      return navigate(ROUTES.user.details.replace(':id', id))
    } else {
      const stepsOrder = [
        USER_FORM_STEPS.PERSONAL_INFORMATION,
        USER_FORM_STEPS.CONTACT,
        USER_FORM_STEPS.ADDRESS,
        USER_FORM_STEPS.DOCUMENTS,
        USER_FORM_STEPS.PROFESSIONAL_INFORMATION,
        USER_FORM_STEPS.PROFILE
      ]

      const currentStepIndex = stepsOrder.indexOf(step)

      if (currentStepIndex > 0) {
        setStep(stepsOrder[currentStepIndex - 1])
      } else {
        navigate(ROUTES.user.list)
      }
    }
  }

  return (
    <Content onBack={handleBack} title={t(`${step}.title`)} subtitle={t(`${step}.subtitle`)}>
      {step === USER_FORM_STEPS.PERSONAL_INFORMATION && (
        <UserFormPersonalInformation
          user={user}
          onUpdate={onUpdate}
          isUpdate={isUpdate}
          onSave={handleChange}
          onCancel={handleBack}
          isLoading={isLoading}
        />
      )}
      {step === USER_FORM_STEPS.CONTACT && (
        <UserContactForm
          user={user}
          isUpdate={isUpdate}
          onUpdate={onUpdate}
          isLoading={isLoading}
          onSave={handleChange}
          onCancel={handleBack}
        />
      )}
      {step === USER_FORM_STEPS.ADDRESS && (
        <UserAddressContact
          user={user}
          isUpdate={isUpdate}
          isLoading={isLoading}
          onUpdate={onUpdate}
          onSave={handleChange}
          onCancel={handleBack}
        />
      )}
      {step === USER_FORM_STEPS.DOCUMENTS && (
        <UserDocumentForm
          user={user}
          isLoading={isLoading}
          isUpdate={isUpdate}
          onUpdate={onUpdate}
          onSave={handleChange}
          onCancel={handleBack}
        />
      )}
      {step === USER_FORM_STEPS.PROFESSIONAL_INFORMATION && (
        <UserFormProfessionalInformation
          user={user}
          isUpdate={isUpdate}
          isLoading={isLoading}
          onUpdate={onUpdate}
          onSave={handleChange}
          onCancel={handleBack}
        />
      )}
      {step === USER_FORM_STEPS.PROFILE && (
        <UserProfileForm
          user={user}
          isSaving={isSaving}
          isUpdate={isUpdate}
          isLoading={isLoading}
          onFinish={onFinish}
          onChange={handleChange}
          onCancel={handleBack}
        />
      )}
    </Content>
  )
}

export default UserFormPage
