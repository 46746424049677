export const ROUTES = {
  login: '/login',
  forgotPassword: '/forgot-password',

  home: '/',
  updatePassword: '/update-password',
  myAccount: '/my-account',

  notifications: '/notifications',

  agenda: {
    list: '/agenda',
    calendar: '/agenda/calendar/:client_id',
    scale_day: '/agenda/scale/:client_id/day',
    new_scale: '/agenda/scale/:client_id/new',
    scale_form: '/agenda/scale/:client_id/:scale_id',
    update_scale: '/agenda/scale/:client_id/update_scale/:scale_id',

    details: '/agenda/:id',
    new: '/agenda/new',
    new_fixed: '/agenda/new/fixed',
    edit_fixed: '/agenda/:id/fixed',
    new_custom: '/agenda/new/custom',
    edit_custom: '/agenda/:id/custom'
  },

  consultation: {
    list: '/consultation/:care_type',

    list_chat: '/consultation/chat',
    list_elective: '/consultation/elective_care',
    list_emergency: '/consultation/emergency_care',

    follow_up: '/consultation/follow_up/:status',
    follow_up_pending: '/consultation/follow_up/pending',
    follow_up_in_progress: '/consultation/follow_up/in_progress',

    new: '/consultation/:care_type/new',
    details: '/consultation/:care_type/:id',
    edit: '/consultation/:care_type/:id/form',
    join: '/consultation/:care_type/:id/join',
    professional_join: '/consultation/:care_type/:id/join/:email',

    transfer: '/consultation/transfer',

    interaction: {
      send_document: '/send-document'
    }
  },

  anamnesis_template: {
    list: '/anamnesis_template',
    form: '/anamnesis_form/:id',
    preview: '/anamnesis_preview'
  },

  user: {
    list: '/user',
    new: '/user/new',
    edit: '/user/:id/:form_step',
    details: '/user/:id'
  },

  client: {
    list: '/client',
    details: '/client/:id',
    form: '/client/form/:id',
    unit: {
      list: '/client/:client_id/unit',
      form: '/client/:client_id/unit/form/:id',
      detail: '/client/:client_id/unit/:id'
    }
  },

  patient: {
    list: '/patient',
    details: '/patient/:id',
    form: '/patient/form/:id',
    new: '/patient/form/new'
  },

  profile: {
    list: '/profile',
    detail: '/profile/:id',
    form: '/profile/form/:id'
  },

  unitType: {
    list: 'unit-type'
  },

  council: {
    list: 'council'
  },

  specialty: {
    list: 'specialty'
  },
  dashboards: {
    list: '/dashboards',
    detail: '/dashboards/:superset_id?'
  },

  sessions: '/sessions',
  showcase: '/showcase',
  connection: '/session/:id/connection'
}
