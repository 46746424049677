/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import PatientService from '../../services/patient.service'
import { useToastContext } from '../../contexts/toast'
import * as Yup from 'yup'
import { PaginationParams, Patient } from '../../types'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes/path'
import { validateCPF } from '../../helpers/validateCPF'

export const useListPatients = (params: PaginationParams) => {
  return useQuery({
    queryKey: ['listPatients', params],
    queryFn: () => PatientService.list(params),
    placeholderData: prev => prev
  })
}

export const useGetPatient = (document: string) => {
  console.log('useGetPatient ', { document })
  return useQuery({
    queryKey: ['getPatient', document],
    queryFn: () =>
      document.length === 11 || document.length === 15
        ? PatientService.getByDocument(document)
        : null,
    enabled: !!document,
    retry: false
  })
}

export const useAddPatient = (
  closeModal?: () => void,
  onSuccess?: (patient: Patient) => void,
  redirectToPatientList?: boolean
) => {
  const { toast } = useToastContext()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const validationSchema = Yup.object({
    name: Yup.string().required('Preencha o nome'),
    document: Yup.string()
      .required('Preencha o CPF')
      .test('is-valid-cpf', 'CPF inválido', value => validateCPF(value))
  })

  const initialValues = {
    name: '',
    document: ''
  }

  const mutation = useMutation({
    mutationFn: (newPatient: Partial<Patient>) => PatientService.create(newPatient),
    onSuccess: data => {
      toast.success('Paciente criado')
      queryClient.invalidateQueries({ queryKey: ['getPatient', 'listPatients'] })
      !!redirectToPatientList ? navigate(ROUTES.patient.list) : null
      formik.resetForm()
      closeModal?.()
      //@ts-expect-error
      onSuccess?.(data)
    },
    onError: error => {
      toast.error(error.message || 'Algo deu errado')
    }
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      mutation.mutate(values)
    },
    enableReinitialize: true
  })

  return formik
}

export const usePatientGeneralFormik = (
  onSubmit: (values: Partial<Patient>) => Promise<void>,
  patient: Partial<Patient>
) => {
  const { toast } = useToastContext()
  const validationSchema = Yup.object({
    id: Yup.string().optional(),
    cpf: Yup.string()
      .test('is-valid-cpf', 'CPF inválido', value => (value ? validateCPF(value) : true))
      .nullable(),
    primary_phone: Yup.string(),
    gender: Yup.string().optional(),
    social_name: Yup.string().optional(),
    secondary_phone: Yup.string().optional(),
    email: Yup.string().email('E-mail inválido'),
    cns_number: Yup.string().optional().nullable(),
    name: Yup.string().required('Preencha o nome'),
    sex: Yup.string().required('Sexo é obrigatório'),
    birthdate: Yup.date().required('Preencha a data de nascimento').nullable()
  })
    .test('cpfRequired', 'Preencha ao menos um dos documentos (CPF ou CNS)', function (values) {
      const { cpf, cns_number } = values
      if (!cpf && !cns_number) {
        return this.createError({
          path: 'cpf',
          message: 'Preencha ao menos um dos documentos (CPF ou CNS)'
        })
      }

      return true
    })
    .test('cnsRequired', 'Preencha ao menos um dos documentos (CPF ou CNS)', function (values) {
      const { cpf, cns_number } = values
      if (!cpf && !cns_number) {
        return this.createError({
          path: 'cns_number',
          message: 'Preencha ao menos um dos documentos (CPF ou CNS)'
        })
      }

      return true
    })

  const initialValues = {
    id: patient.id || '',
    cpf: patient.cpf || '',
    cns_number: patient.cns_number || '',
    name: patient.name || '',
    social_name: patient.social_name || '',
    birthdate: patient.birthdate || '',
    gender: patient.gender || '',
    sex: patient.sex || '',
    primary_phone: patient.primary_phone || '',
    email: patient.email || '',
    secondary_phone: patient.secondary_phone || ''
  } as Partial<Patient>

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async e => await handleSubmitForm(e)
  })

  const handleSubmitForm = async (e: Partial<Patient>) => {
    try {
      await PatientService.validateParams(e)
      await onSubmit(e)
    } catch (error) {
      toast.error((error as Error)?.message || 'Algo deu errado')
    }
  }

  return formik
}

export const usePatientContactFormik = (
  onSubmit: (values: Partial<Patient>) => Promise<void>,
  patient: Partial<Patient>
) => {
  const { toast } = useToastContext()
  const validationSchema = Yup.object({
    id: Yup.string().optional(),
    address_code: Yup.string().optional(),
    address: Yup.string().optional(),
    address_number: Yup.string().optional(),
    address_neighborhood: Yup.string().optional(),
    address_state: Yup.string().optional(),
    address_city: Yup.string().optional(),
    address_complement: Yup.string().optional()
  })

  const initialValues = {
    id: patient.id || '',
    email: patient.email,
    primary_phone: patient.primary_phone,
    secondary_phone: patient.secondary_phone,
    address_code: patient.address_code || '',
    address: patient.address || '',
    address_number: patient.address_number || '',
    address_neighborhood: patient.address_neighborhood || '',
    address_state: patient.address_state || '',
    address_city: patient.address_city || '',
    address_complement: patient.address_complement || ''
  } as Partial<Patient>

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async e => await handleSubmitForm(e)
  })

  const handleSubmitForm = async (e: Partial<Patient>) => {
    try {
      await PatientService.validateParams(e)
      await onSubmit(e)
    } catch (error) {
      toast.error((error as Error)?.message || 'Algo deu errado')
    }
  }

  return formik
}

export const usePatientDetailFormik = (
  onSubmit: (values: Partial<Patient>) => Promise<void>,
  patient: Partial<Patient>
) => {
  const { toast } = useToastContext()
  const validationSchema = Yup.object({
    id: Yup.string().optional(),
    mother_unknown: Yup.boolean(),
    mother_name: Yup.string().optional(),
    race: Yup.string().optional(),
    birth_city: Yup.string().optional(),
    representative_legal_unknown: Yup.boolean().optional(),
    guardian_name: Yup.string().optional(),
    guardian_cpf: Yup.string().optional(),
    guardian_state: Yup.string().optional()
  })

  const initialValues: Partial<Patient> & {
    mother_unknown: boolean
    representative_legal_unknown: boolean
  } = {
    id: patient.id || '',
    mother_unknown: (patient as Patient & { mother_unknown: boolean })?.mother_unknown || false,
    mother_name: patient?.mother_name || '',
    representative_legal_unknown:
      (patient as Patient & { representative_legal_unknown: boolean })
        ?.representative_legal_unknown || false,
    guardian_name: patient?.guardian_name || '',
    guardian_cpf: patient?.guardian_cpf || '',
    guardian_state: patient?.guardian_state || '',
    race: patient?.race || '',
    nationality: patient?.nationality || 'BR',
    birth_state: patient?.birth_state || '',
    birth_city: patient?.birth_city || ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async e => await handleSubmitForm(e)
  })

  const handleSubmitForm = async (e: Partial<Patient>) => {
    try {
      await PatientService.validateParams(e)
      await onSubmit(e)
    } catch (error) {
      toast.error((error as Error)?.message || 'Algo deu errado')
    }
  }

  return formik
}

export const usePatientMedicineFormik = (
  onSubmit: (values: Partial<Patient>) => Promise<void>,
  patient: Partial<Patient>
) => {
  const { toast } = useToastContext()
  const validationSchema = Yup.object({
    id: Yup.string().optional(),
    weight: Yup.string().optional(),
    height: Yup.string().optional(),
    allergies: Yup.array().optional(),
    chronic_diseases: Yup.array().optional(),
    health_conditions: Yup.array().optional(),
    surgical_history: Yup.array().optional(),
    medicines: Yup.array().optional()
  })

  const initialValues = {
    id: patient.id || '',
    weight: patient.weight,
    height: patient.height,
    allergies: patient.allergies || [],
    chronic_diseases: patient.chronic_diseases || [],
    health_conditions: patient.health_conditions || [],
    surgical_history: patient.surgical_history || [],
    medicines: patient.medicines || [{}]
  } as Partial<Patient>

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async e => await handleSubmitForm(e)
  })

  const handleSubmitForm = async (e: Partial<Patient>) => {
    try {
      await PatientService.validateParams(e)
      await onSubmit(e)
    } catch (error) {
      toast.error((error as Error)?.message || 'Algo deu errado')
    }
  }

  return formik
}
