import { Column } from './types'

export const BodySkeleton: React.FC<{
  columns: Column[]
  length?: number
  selectable?: boolean
}> = ({ columns, length, selectable = false }) => {
  return (
    <div className='mx-4 flex flex-1 flex-col gap-4 overflow-y-hidden sm:mx-0 sm:gap-0'>
      {Array.from({ length: length || 5 }, (_, index_row) => (
        <div
          className='flex items-center overflow-hidden rounded-xl border sm:h-[60px] sm:flex-row sm:rounded-none sm:border-x-0'
          key={index_row}
        >
          {selectable && (
            <div className='w-[40px] p-4'>
              <div className='flex h-6 w-6 animate-pulse rounded-md bg-gray-200' />
            </div>
          )}
          {columns.map((column, index_col) => (
            <div
              key={index_row + '_' + index_col}
              className={`flex h-40 flex-1 animate-pulse bg-gray-200 text-label-sm text-neutralContent-primary sm:mx-4 sm:h-6 sm:rounded ${column.className}`}
            />
          ))}
        </div>
      ))}
    </div>
  )
}
