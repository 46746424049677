/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, ReactNode, useContext, useState } from 'react'

interface AnamnesisContextProps {
  defaultValues: any
  setDefaultValues: React.Dispatch<React.SetStateAction<any>>
  specialtyValues: any
  setSpecialtyValues: React.Dispatch<React.SetStateAction<any>>
}

const AnamnesisContext = createContext<AnamnesisContextProps | undefined>(undefined)

export const AnamnesisProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [defaultValues, setDefaultValues] = useState({})
  const [specialtyValues, setSpecialtyValues] = useState({})

  return (
    <AnamnesisContext.Provider
      value={{ defaultValues, setDefaultValues, specialtyValues, setSpecialtyValues }}
    >
      {children}
    </AnamnesisContext.Provider>
  )
}

export const useAnamnesis = () => {
  const context = useContext(AnamnesisContext)

  if (!context) {
    throw new Error('useAnamnesis must be used within a AnamnesisProvider')
  }
  return context
}
