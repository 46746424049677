import { useEffect, useState } from 'react'
import { Client, Patient, User } from '../../types'
import { Camera } from '@phosphor-icons/react'
import FileService from '../../services/file.service'
import { useToastContext } from '../../contexts/toast'

type Props = {
  user?: User | Patient | null
  client?: Client
  name?: string
  className?: string
  layout?: 'circle' | 'square'
  size?: 'xxxl' | 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs'
  color?: 'default' | 'neutral'
  imageUrl?: string
  imageID?: string
  isEditable?: boolean
  setFileImage?: (image: string) => void
}

const getColorLayout = (color: Props['color']) => {
  switch (color) {
    case 'neutral':
      return 'bg-neutralBackground-tertiary border border-neutralBorder-default text-neutralContent-primary'
    default:
      return 'bg-primaryBrand-primary text-white'
  }
}

const getSizeLayout = (size: Props['size']) => {
  switch (size) {
    case 'xxxl':
      return 'w-28 h-28 text-display-md'
    case 'xxl':
      return 'w-24 h-24 text-display-sm'
    case 'xl':
      return 'w-20 h-20 text-heading-xl'
    case 'lg':
      return 'w-16 h-16 text-heading-md'
    case 'md':
      return 'w-12 h-12 text-heading-xs'
    case 'sm':
      return 'h-9 w-9 text-label-md'
    case 'xs':
      return 'h-6 w-6 text-label-xs'
    case 'xxs':
      return 'h-5 w-5 text-label-xs'
    default:
      return ''
  }
}

const getLayout = (layout: Props['layout']) => {
  switch (layout) {
    case 'square':
      return 'rounded-[25%]'
    default:
      return 'rounded-full'
  }
}

const Avatar: React.FC<Props> = ({
  client,
  user,
  className,
  layout,
  size = 'md',
  imageUrl,
  imageID,
  name,
  color = 'default',
  isEditable = false,
  setFileImage
}) => {
  const initialName = (user || client)?.name?.[0] || name?.[0] || ''
  const [image, setImage] = useState(imageUrl)
  const { toast } = useToastContext()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (imageID || client?.image_id) {
      setLoading(true)
      try {
        FileService.get(imageID || client!.image_id!).then(setImage)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }, [imageID, client])

  useEffect(() => {
    setImage(imageUrl)
  }, [imageUrl])

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp']
      if (!validImageTypes.includes(file.type)) {
        toast.error('Por favor, selecione um arquivo de imagem válido (JPEG, PNG, ou WEBP).')
        return
      }

      setFileImage?.(URL.createObjectURL(file))
    }
  }

  return (
    <>
      {!loading ? (
        <div
          className={`relative flex items-center justify-center ${getColorLayout(color)} ${getSizeLayout(size)} ${getLayout(layout)} ${className}`}
        >
          {image ? (
            <img
              src={image}
              alt='Avatar'
              className={`object-cover ${getLayout(layout)} ${getSizeLayout(size)}`}
            />
          ) : (
            initialName
          )}

          {isEditable && (
            <div className='absolute bottom-0 right-0'>
              <label htmlFor='avatar-upload' className='cursor-pointer'>
                <div className='flex h-7 w-7 items-center justify-center rounded-full bg-highlightBlue-pure text-white'>
                  <Camera size={22} />
                </div>
                <input
                  id='avatar-upload'
                  type='file'
                  accept='image/*'
                  className='hidden'
                  onChange={handleFileChange}
                />
              </label>
            </div>
          )}
        </div>
      ) : (
        <div
          className={`relative  animate-pulse ${getColorLayout(color)} ${getSizeLayout(size)} ${getLayout(layout)} `}
        />
      )}
    </>
  )
}

export default Avatar
