const consultation_details_enUS = {
  title: 'Consultation Details',
  subtitle: 'View and manage consultations',
  video_conference: 'Video Conference',
  information: 'Information',
  clinical_history: 'Clinical History',
  documents: 'Documents',
  code: 'Code',
  consultation: 'Consultation',
  date: 'Date',
  hour: 'Hour',
  specialty: 'Specialty',
  specialist: 'Specialist',
  unit: 'Unit',
  requester: 'Requester',
  links: 'Links',
  clinical_evolution: 'Clinical Evolution',
  finished_consultation: 'Finished Consultation',

  consultation_type: {
    elective: 'Elective',
    urgency: 'Emergency Care'
  },

  modal_confirmation: {
    title_SCHEDULED: 'Start video conference?',
    description_SCHEDULED: 'Participants will be notified about the start of the consultation.',
    title_IN_ROOM: 'Join the video conference?',
    description_IN_ROOM: 'Participants will be notified that you joined the consultation.',
    title_WAITING: 'Start consultation?',
    description_WAITING: 'Participants will be notified that you joined the consultation.'
  },

  modal_confirmation_finish_call: {
    title: 'Finish consultation?',
    description:
      'The consultation will be closed, and it will no longer be possible to edit its information.'
  },

  modal_nsp_modal: {
    title: 'Rate the consultation',
    description: 'How would you rate the consultation you just received?'
  },

  modal_confirmation_exit_call: {
    title: 'Leave the consultation?',
    description:
      'The consultation will remain available, and guests will stay in the call until the scheduled end time.'
  },

  modal_confirmation_start_chat: {
    title: 'Start consultation?',
    description: 'Participants will be notified about the start of the consultation.'
  },

  tabs: {
    anamnese: 'Anamnesis',
    interactions: 'Interactions',
    prescriptions: 'Medical Prescriptions',
    diagnosis: 'Diagnosis',
    historic: 'History',
    chat: 'Consultation Chat',

    patient_identification: 'Patient Identification',
    specialty: 'Specialty',
    diagnosticHypothesis: 'Diagnostic Hypothesis, Therapeutic Plan, and Prognosis'
  },

  historic: {
    older_consultations: 'Previous Consultations',
    medications_in_use: 'Medications in Use',

    columns: {
      code: 'Code',
      date: 'Date',
      hour: 'Hour',
      specialty: 'Specialty',
      care_type: 'Care Type',
      unit: 'Unit',

      name: 'Name',
      dosage: 'Dosage',
      dosage_desc: 'Dosage Instructions',
      treatment: 'Start of Treatment',
      duration: 'Duration'
    }
  },

  anamnese_tab: {
    default: 'Default',
    specialty: 'Specialty'
  },

  chat: {
    title: 'Consultation Chat',
    textarea_placeholder: 'Type your message...'
  },

  types: {
    chat: 'Chat',
    elective_care: 'Elective',
    emergency_care: 'Emergency Care'
  },

  interactions: {
    modal: {
      title: 'Attach Document',
      from_my_computer: 'from my computer',
      qr_code: 'via QR Code',
      only_image: ' (images only)',
      description_qrcode:
        'Point your phone camera at the code below and then select the displayed number to proceed.'
    }
  },

  invite_patient_modal: {
    title: 'Invite Patient',
    subtitle: 'The patient will receive an email with instructions to join the video conference.'
  },
  invite_professional_modal: {
    title: 'Invite Professional',
    subtitle:
      'The professional will receive an email with instructions to join the video conference.',
    inputs: {
      name: 'Full Name',
      email: 'Email',
      birthdate: 'Date of Birth'
    }
  },

  actions: {
    edit: 'Edit',
    complete_consultation: 'Complete Consultation',
    see_complete_sheet: 'View Full Record',
    start_call: 'Start Video Conference',
    enter_call: 'Join Video Conference',
    start_chat: 'Start Chat',
    enter_chat: 'Open Chat',
    anamnesis: 'Anamnesis',
    report: 'Report',
    attach_file: 'Attach File',
    invite_patient: 'Invite Patient',
    invite_professional: 'Invite Professional',
    attach_document: 'Attach Document',
    insert_link: 'Insert Link',
    start: 'Start',
    cancel: 'Cancel',
    entry_call: 'Join Call',
    exit: 'Exit',
    exit_call: 'Leave Consultation',
    finish_call: 'Finish Consultation',
    finish: 'Finish',
    sent: 'Send',
    attach: 'Attach',
    invite: 'Send Invitation'
  }
}

export default consultation_details_enUS
