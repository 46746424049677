import { useState } from 'react'
import { useConsultationFormik, useGetConsultation } from '../../../hooks/useConsultation'
import UnitSpecialtiesSelect from '../../../components/dropdown/unit-specialty-dropdown'
import RequesterSelect from '../../../components/dropdown/requester-dropdown'
import { IdentificationCard, Trash, UserCircle } from '@phosphor-icons/react'
import ClientSelect from '../../../components/dropdown/client-dropdown'
import UnitSelect from '../../../components/dropdown/unit-dropdown'
import PatientModal from '../../../components/modal/patient-modal'
import DeleteConsultationModal from './delete-consultation-modal'
import UnitPermissionsEnum from '../../../types/unit-permissions'
import UpdateConsultationModal from './update-confirmation-modal'
import SelectConsultationTime from './select-consulation-time'
import ContentFooter from '../../../components/content/footer'
import { PencilSimple } from '@phosphor-icons/react/dist/ssr'
import ContentForm from '../../../components/content/form'
import { useNavigate, useParams } from 'react-router-dom'
import { EventImpl } from '@fullcalendar/core/internal'
import PermissionEnum from '../../../types/permissions'
import { CARE_TYPE, Patient } from '../../../types'
import Checkbox from '../../../components/checkbox'
import ConsultationType from './consultation-type'
import InputField from '../../../components/input'
import Content from '../../../components/content'
import { useAuth } from '../../../contexts/auth'
import Button from '../../../components/button'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../../routes/path'
import { Loading } from 'semente-js'

const ConsultationFormPage: React.FC = () => {
  const { id, care_type } = useParams<{ id: string; care_type: CARE_TYPE }>()
  const { t } = useTranslation('consultation_form')
  const { hasAccess, user } = useAuth()
  const [selectedEvent, setSelectedEvent] = useState<EventImpl>()
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false)
  const [isPatientModalOpen, setPatientModalOpen] = useState(false)

  const navigate = useNavigate()

  const { data: consultation, isFetching } = useGetConsultation(id)

  const formik = useConsultationFormik(
    consultation || {
      care_type: care_type as CARE_TYPE,
      requester_id: !hasAccess(PermissionEnum.service_create_to_other_users) ? user?.id : undefined
    }
  )

  const handleSelectPatient = async (patient: Patient) => {
    await formik.setFieldValue('patient_id', patient.id)
    await formik.setFieldValue('patient', patient)

    setPatientModalOpen(false)
  }

  const handleSelectEvent = async (event: EventImpl) => {
    setSelectedEvent(event)
    await formik.setFieldValue('end_date', event.end!.toISOString())
    await formik.setFieldValue('start_date', event.start!.toISOString())
    await formik.setFieldValue('scale_id', event.extendedProps.scale_id)
  }

  const handleBack = () => {
    if (id) {
      navigate(ROUTES.consultation.details.replace(':id', id).replace(':care_type', care_type!))
    } else {
      navigate(ROUTES.consultation.list.replace(':care_type', care_type!))
    }
  }

  const openDeleteModal = () => {
    setDeleteModalOpen(true)
  }

  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const handleSubmit = () => {
    if (id && id !== 'new') {
      setUpdateModalOpen(true)
    } else {
      formik.submitForm()
    }
  }

  const handleWithoutPatient = async () => {
    formik.setFieldValue('patient', undefined)
    formik.setFieldValue('patient_id', undefined)
    formik.setFieldValue('without_patient', !formik.values.without_patient)
  }

  const getUnitPermissionByType = (care_type: CARE_TYPE) => {
    switch (care_type) {
      case CARE_TYPE.CHAT:
        return UnitPermissionsEnum.chat_consultation
      case CARE_TYPE.ELECTIVE_CARE:
        return UnitPermissionsEnum.elective_care_consultation
      case CARE_TYPE.EMERGENCY_CARE:
        return UnitPermissionsEnum.emergency_care_consultation
    }
  }

  if (isFetching) return <Loading />

  if (!care_type) return <div>{`Invalid CARE_TYPE: ${care_type}`}</div>

  return (
    <Content
      onBack={handleBack}
      subtitle={t('subtitle')}
      title={t(consultation ? 'title_update_' + consultation.care_type : 'title_' + care_type)}
      rightContent={
        id && (
          <Button
            size='sm'
            state='error'
            variant='secondary'
            label={t('actions.exclude')}
            onClick={openDeleteModal}
            iconLeft={<Trash size={16} />}
          />
        )
      }
    >
      <ContentForm hasBackButton hasSubtitle>
        <>
          <div className='md:col-span-2'>
            <ConsultationType
              id='type'
              care_type={care_type}
              value={formik.values.type || ''}
              onChangeValue={formik.setFieldValue}
            />
          </div>

          {formik.values.type && formik.values.care_type && (
            <>
              <div className='flex gap-4 md:col-span-2'>
                <ClientSelect
                  name='client_id'
                  label={t('fields.client')}
                  onBlur={formik.handleBlur}
                  value={formik.values.client_id || ''}
                  placeholder={t('actions.select_an_option')}
                  unit_permission={getUnitPermissionByType(formik.values.care_type)}
                  onChange={client => formik.setFieldValue('client_id', client ? client.id : '')}
                  hint={
                    formik.touched.client_id && formik.errors.client_id
                      ? formik.errors.client_id
                      : undefined
                  }
                />
                <UnitSelect
                  name='unit_id'
                  label={t('fields.unit')}
                  onBlur={formik.handleBlur}
                  value={formik.values.unit_id || ''}
                  client_id={formik.values?.client_id}
                  placeholder={t('actions.select_an_option')}
                  unit_permission={getUnitPermissionByType(formik.values.care_type)}
                  onChange={unit => formik.setFieldValue('unit_id', unit ? unit.id : '')}
                  hint={
                    formik.touched.unit_id && formik.errors.unit_id
                      ? formik.errors.unit_id
                      : undefined
                  }
                />
              </div>

              <div className='md:col-span-2'>
                <UnitSpecialtiesSelect
                  name='specialty_id'
                  onBlur={formik.handleBlur}
                  label={t('fields.specialty')}
                  unit_id={formik.values.unit_id}
                  value={formik.values.specialty_id || ''}
                  placeholder={t('actions.select_an_option')}
                  onChange={specialty =>
                    formik.setFieldValue('specialty_id', specialty ? specialty.id : '')
                  }
                  hint={
                    formik.touched.specialty_id && formik.errors.specialty_id
                      ? formik.errors.specialty_id
                      : undefined
                  }
                />
              </div>

              {formik.values.type === 'TELEINTERCONSULTATION' && (
                <div className='md:col-span-2'>
                  {hasAccess(PermissionEnum.service_create_to_other_users) ? (
                    <RequesterSelect
                      name='requester_id'
                      care_type={care_type!}
                      onBlur={formik.handleBlur}
                      label={t('fields.requester')}
                      unit_id={formik.values.unit_id}
                      disabled={!formik.values.unit_id}
                      value={formik.values.requester_id || ''}
                      placeholder={t('actions.select_an_option')}
                      onChange={option =>
                        formik.setFieldValue('requester_id', option ? option.id : '')
                      }
                    />
                  ) : (
                    <InputField
                      onChange={() => {}}
                      value={user?.name || ''}
                      label={t('fields.requester')}
                    />
                  )}
                </div>
              )}

              <div className='md:col-span-2'>
                {!consultation?.patient?.name && care_type !== CARE_TYPE.ELECTIVE_CARE && (
                  <div className='mb-2 flex gap-2'>
                    <Checkbox
                      onClick={handleWithoutPatient}
                      isChecked={!!formik.values.without_patient}
                    />
                    <div>Paciente não identificado</div>
                  </div>
                )}
                {!formik.values.without_patient && (
                  <>
                    <h2 className='text-heading-xs font-bold'>{t('fields.patient')}</h2>
                    {formik.values.patient?.id ? (
                      <div className='mb-4 mt-4 flex  justify-between rounded-xl  border p-4'>
                        <div>
                          <p className='text-body-md font-semibold'>
                            {formik.values.patient?.name}
                          </p>
                          <p className='flex items-center text-body-sm text-gray-600'>
                            <IdentificationCard size={16} className='mr-2' color='black' />
                            {formik.values.patient.cpf}
                          </p>
                        </div>
                        <Button
                          size='sm'
                          iconLeft={<PencilSimple size={22} />}
                          onClick={() => setPatientModalOpen(true)}
                        />
                      </div>
                    ) : (
                      <Button
                        size={'xs'}
                        type='button'
                        className='mt-4'
                        variant='secondary'
                        label={t('actions.select_pacient')}
                        iconLeft={<UserCircle size={22} />}
                        onClick={() => setPatientModalOpen(true)}
                      />
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </>

        {formik.values.care_type === CARE_TYPE.ELECTIVE_CARE && formik.values.specialty_id && (
          <div className='sm:md:col-span-2'>
            <SelectConsultationTime
              selectedEvent={selectedEvent}
              onSelectEvent={handleSelectEvent}
              unit_id={formik.values.unit_id || ''}
              specialty_id={formik.values.specialty_id || ''}
            />
          </div>
        )}

        <ContentFooter className='justify-end gap-2'>
          <Button
            size='md'
            type='button'
            state='enable'
            variant='secondary'
            onClick={handleBack}
            label={t('actions.cancel')}
            isLoading={formik.isSubmitting}
          />
          <Button
            size='md'
            type='button'
            onClick={handleSubmit}
            label={t('actions.save')}
            isLoading={formik.isSubmitting}
            state={!formik.isValid ? 'disable' : 'enable'}
          />
        </ContentFooter>
      </ContentForm>

      <PatientModal
        isOpen={isPatientModalOpen}
        title='Selecionar paciente'
        placeholder='123.456.789-12'
        onSelect={handleSelectPatient}
        label='Digite o CPF ou CNS do paciente'
        onClose={() => setPatientModalOpen(false)}
      />

      <DeleteConsultationModal
        consultation={consultation}
        closeModal={closeDeleteModal}
        isModalOpen={isDeleteModalOpen}
      />

      <UpdateConsultationModal
        consultation={formik.values}
        isModalOpen={isUpdateModalOpen}
        isLoading={formik.isSubmitting}
        onSuccessUpdate={formik.handleSubmit}
        closeModal={() => setUpdateModalOpen(false)}
      />
    </Content>
  )
}

export default ConsultationFormPage
