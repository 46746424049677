/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import Modal from '../../../components/modal'
import BlankCard from '../../../components/blank-card'
import { Client, Scale } from '../../../types'
import Avatar from '../../../components/avatar'
import MonthSelectCalendar from '../../../components/custom-week-calendar/month-select-calendar'
import Tag from '../../../components/tag'
import { useListScalesByClient } from '../../../hooks/useScale'
import {
  eachDayOfInterval,
  endOfMonth,
  format,
  isSameMonth,
  startOfWeek,
  endOfWeek,
  addMonths,
  subMonths,
  startOfMonth,
  parse,
  isSameDay
} from 'date-fns'
import { useTranslation } from 'react-i18next'
import { scaleNumberToTime } from '../../../helpers/scaleTime'
import { ptBR } from 'date-fns/locale'
import { X } from '@phosphor-icons/react'
import { DayColumn } from '../../../components/custom-week-calendar'

interface Props {
  isFetching?: boolean
  scales: Scale[]
  currentMonth: Date
  handleClickCard: (scale: Scale) => void
}

const daysOfWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']

const FullScale: React.FC<Props> = ({ isFetching, scales, currentMonth, handleClickCard }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>()

  const startDate = startOfWeek(startOfMonth(currentMonth), { weekStartsOn: 0 })
  const endDate = endOfWeek(endOfMonth(currentMonth), { weekStartsOn: 0 })

  const days = eachDayOfInterval({ start: startDate, end: endDate })

  const formatDate = (date: Date) => {
    const newDate = format(date, "EEEE, dd 'de' MMMM 'de' yyyy", { locale: ptBR })

    return newDate.charAt(0).toUpperCase() + newDate.slice(1)
  }

  const handleClickDay = (day?: Date) => {
    setSelectedDate(day)
  }

  return (
    <div className='flex gap-8'>
      <div className='mx-[-24px] mt-9 flex h-[calc(100vh-450px)] w-full flex-col gap-9 overflow-y-scroll px-6 '>
        {/* Calendar */}
        <div className='grid grid-cols-7'>
          {daysOfWeek.map(day => (
            <div key={day} className='mb-[-10px] border p-3 text-center '>
              <span className='text-body-md text-neutralContent-tertiary'>{day}</span>
            </div>
          ))}

          {days.map(day => {
            const dayScales = scales?.filter(scale =>
              isSameDay(parse(scale.date, 'yyyy-MM-dd', new Date()), day)
            )
            const isCurrentMonth = isSameMonth(day, currentMonth)

            const isSelected = selectedDate && isSameDay(selectedDate, day)

            return (
              <div
                key={day.toISOString()}
                className={`h-48  border p-2 
          ${isSelected ? 'bg-highlightBlue-light' : ''} 
          ${!isSelected && isCurrentMonth ? 'bg-white' : ''} 
          ${!isCurrentMonth ? ' bg-gray-100 text-neutralContent-disabled' : ' cursor-pointer'}`}
                onClick={() => handleClickDay(day)}
              >
                <div className='flex w-full justify-center'>
                  <span className='text-body-md text-neutralContent-primary'>
                    {format(day, 'd')}
                  </span>
                </div>
                <div className='mt-2 flex flex-col gap-1'>
                  {isFetching &&
                    isCurrentMonth &&
                    Array.from({ length: 2 }).map((_, index) => (
                      <div
                        key={index}
                        className='h-4 animate-pulse rounded bg-neutralBorder-default'
                      />
                    ))}
                  {!isFetching &&
                    dayScales?.map((scale, index) => (
                      <Tag
                        key={index}
                        label={`${scale.specialty?.name} - ${scale.time_intervals
                          .map(
                            interval =>
                              `${scaleNumberToTime(interval.start_time)} - ${scaleNumberToTime(interval.end_time)}`
                          )
                          .join(', ')}`}
                        color={scale.specialists.length > 0 ? 'blue' : 'orange'}
                        variant='primary'
                        className='truncate'
                        size='xs'
                      />
                    ))}
                </div>
              </div>
            )
          })}
        </div>
      </div>

      {selectedDate && (
        <div className='mt-6 h-[calc(100vh-96px)] w-[500px] overflow-y-scroll rounded-2xl  p-3 shadow-lg '>
          <div className='mb-2 flex   items-center justify-between'>
            <span className='text-heading-xs'>{formatDate(selectedDate)}</span>
            <X onClick={() => handleClickDay(undefined)} size={22} className='cursor-pointer' />
          </div>

          <div>
            <DayColumn
              scales={scales}
              date={selectedDate}
              showSpecialist
              onClickCard={scale => {
                handleClickCard(scale)
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default FullScale
