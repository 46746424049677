import InputField, { InputType } from '../../../../../components/input'
import Radio from '../../../../../components/radio'
import TextareaField from '../../../../../components/text-area'
import { AnyQuestionProps } from '../any-questions'

export const TextQuestion: React.FC<AnyQuestionProps & { type?: InputType }> = ({
  type,
  values,
  question,
  readonly,
  handleValue
}) => {
  const handleYesNoChange = (value: 'yes' | 'no' | undefined) => {
    handleValue({
      ...values,
      [`${question.id!}_yes_no`]: value
    })
  }

  const handleInputChange = (value: string) => {
    handleValue({
      ...values,
      [`${question.id!}_input`]: value
    })
  }

  if (readonly) {
    if (question.yes_no) {
      return (
        <>{`${values[`${question.id!}_yes_no`] === 'yes' ? 'Sim' : 'Não'} - ${values[`${question.id!}_input`] || ''}`}</>
      )
    } else {
      return (
        <>
          {values[`${question.id!}_input`] || '-'} {question.suffix || ''}
        </>
      )
    }
  }

  return (
    <div className='flex flex-col gap-4'>
      {question.yes_no && (
        <div className='flex gap-4'>
          <div className='mt-2 flex items-center gap-2 space-x-2'>
            <Radio
              size='md'
              state='enabled'
              selected={values[`${question.id!}_yes_no`] === 'yes'}
              onChange={() => handleYesNoChange('yes')}
            />
            Sim
          </div>
          <div className='mt-2 flex items-center gap-2 space-x-2'>
            <Radio
              size='md'
              state='enabled'
              selected={values[`${question.id!}_yes_no`] === 'no'}
              onChange={() => handleYesNoChange('no')}
            />
            Não
          </div>
        </div>
      )}
      {(values[`${question.id!}_yes_no`] === 'yes' || !question.yes_no) &&
        (question.type === 'textarea' ? (
          <TextareaField
            placeholder='Descreva'
            value={
              values[`${question.id!}_input`] ? (values[`${question.id!}_input`] as string) : ''
            }
            onChange={e => handleInputChange(e.target.value)}
          />
        ) : (
          <InputField
            type={type}
            suffix={question.suffix}
            placeholder={question.placeholder}
            onChange={e => handleInputChange(e.target.value)}
            value={
              values[`${question.id!}_input`] ? (values[`${question.id!}_input`] as string) : ''
            }
          />
        ))}
    </div>
  )
}
