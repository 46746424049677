/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { Patient } from '../../../../types'
import BlankCard from '../../../../components/blank-card'
import { format } from 'date-fns'
import { useGetCity, useGetState } from '../../../../hooks/useLocation'
import { formatBirthDate } from '../../../../helpers/formatBirthDate'

const getNationality = (nationality: string) => {
  if (nationality.includes('br'.toLocaleUpperCase())) {
    return 'Brasileira'
  }
  return 'Estrangeira'
}

const PersonalData: React.FC<{
  patientData: Patient | null | undefined
}> = ({ patientData }) => {
  const { t } = useTranslation('patient_detail')
  const { data: birthState } = useGetState(patientData?.birth_state)
  const { data: cityState } = useGetCity(patientData?.birth_city)
  const { data: stateAddress } = useGetState(patientData?.birth_state)
  const { data: cityAddress } = useGetCity(patientData?.birth_city)

  function formatAddress(): React.ReactNode {
    if (!patientData) {
      return t('not_informed')
    }

    if (
      !patientData.address &&
      !patientData.address_number &&
      !patientData.address_complement &&
      !patientData.address_neighborhood &&
      !cityAddress &&
      !stateAddress
    ) {
      return t('not_informed')
    }

    const addressParts = [
      patientData.address || 'Rua não informada',
      patientData.address_number ? `Número: ${patientData.address_number}` : 'Número não informado',
      patientData.address_complement
        ? `Complemento: ${patientData.address_complement}`
        : 'Complemento não informado',
      patientData.address_neighborhood
        ? `Bairro: ${patientData.address_neighborhood}`
        : 'Bairro não informado',
      stateAddress
        ? `${patientData?.birth_city || cityAddress || 'Cidade não informada'} - ${stateAddress.sigla}`
        : 'Estado não informados'
    ]

    return addressParts.filter(Boolean).map((part, index) => (
      <span key={index} className='block'>
        {part}
      </span>
    ))
  }

  return (
    <>
      <div className='mt-4 grid h-[calc(100vh-340px)] grid-cols-2 gap-4  overflow-y-scroll'>
        <div className=''>
          <span className='text-heading-sm'>{t('basic_identification')}</span>
          <BlankCard className='mt-3 flex flex-col gap-4'>
            <div className='flex flex-col gap-1'>
              <span className='text-label-md'>{t('birth_date')}</span>
              <span className='text-body-sm text-neutralContent-secondary'>
                {patientData?.birthdate
                  ? formatBirthDate(patientData.birthdate)
                  : t('not_informed')}
              </span>
            </div>

            <div className='flex gap-4'>
              <div className='flex flex-col gap-1'>
                <span className='text-label-md'>{t('sex')}</span>
                <span className='text-body-sm text-neutralContent-secondary'>
                  {patientData?.gender ? patientData?.sex : t('not_informed')}
                </span>
              </div>
              <div className='flex flex-col gap-1'>
                <span className='text-label-md'>{t('gender')}</span>
                <span className='text-body-sm text-neutralContent-secondary'>
                  {patientData?.gender ? patientData?.gender : t('not_informed')}
                </span>
              </div>
            </div>

            <div className='flex flex-col gap-1'>
              <span className='text-label-md'>{t('cpf')}</span>
              <span className='text-body-sm text-neutralContent-secondary'>
                {patientData?.cpf ? patientData?.cpf : t('not_informed')}
              </span>
            </div>

            <div className='flex flex-col gap-1'>
              <span className='text-label-md'>{t('cns_number')}</span>
              <span className='text-body-sm text-neutralContent-secondary'>
                {patientData?.cns_number ? patientData?.cns_number : t('not_informed')}
              </span>
            </div>
          </BlankCard>
        </div>

        <div className=''>
          <span className='text-heading-sm'>{t('demographic_information')}</span>
          <BlankCard className='mt-3 flex flex-col gap-4'>
            <div className='flex flex-col gap-1'>
              <span className='text-label-md'>{t('birth_date')}</span>
              <span className='text-body-sm text-neutralContent-secondary'>
                {patientData?.birthdate
                  ? formatBirthDate(patientData.birthdate)
                  : t('not_informed')}
              </span>
            </div>

            <div className='flex flex-col gap-1'>
              <span className='text-label-md'>{t('race')}</span>
              <span className='text-body-sm text-neutralContent-secondary'>
                {patientData?.race ? patientData?.race : t('not_informed')}
              </span>
            </div>

            <div className='flex flex-col gap-1'>
              <span className='text-label-md'>{t('nationality')}</span>
              <span className='text-body-sm text-neutralContent-secondary'>
                {patientData?.nationality
                  ? getNationality(patientData?.nationality)
                  : t('not_informed')}
              </span>
            </div>

            <div className='flex flex-col gap-1'>
              <span className='text-label-md'>{t('naturalness')}</span>
              <span className='text-body-sm text-neutralContent-secondary'>
                {patientData?.birth_state && cityState && birthState
                  ? `${cityState.nome} - ${birthState.sigla}`
                  : t('not_informed')}
              </span>
            </div>
          </BlankCard>
        </div>

        <div className=''>
          <span className='text-heading-sm'>{t('contact_information')}</span>
          <BlankCard className='mt-3 flex flex-col gap-4'>
            <div className='flex flex-col gap-1'>
              <span className='text-label-md'>{t('principal_email')}</span>
              <span className='text-body-sm text-neutralContent-secondary'>
                {patientData?.email ? patientData?.email : t('not_informed')}
              </span>
            </div>

            <div className='flex flex-col gap-1'>
              <span className='text-label-md'>{t('principal_phone')}</span>
              <span className='text-body-sm text-neutralContent-secondary'>
                {patientData?.primary_phone ? patientData?.primary_phone : t('not_informed')}
              </span>
            </div>

            <div className='flex flex-col gap-1'>
              <span className='text-label-md'>{t('alternative_phone')}</span>
              <span className='text-body-sm text-neutralContent-secondary'>
                {patientData?.secondary_phone ? patientData?.secondary_phone : t('not_informed')}
              </span>
            </div>
          </BlankCard>
        </div>

        <div className=''>
          <span className='text-heading-sm'>{t('documentation')}</span>
          <BlankCard className='mt-3 flex flex-col gap-4'>
            <div className='flex flex-col gap-1'>
              <span className='text-label-md'>{t('rg')}</span>
              <span className='text-body-sm text-neutralContent-secondary'>
                {patientData?.document_number ? patientData?.document_number : t('not_informed')}
              </span>
            </div>

            <div className='flex flex-col gap-1'>
              <span className='text-label-md'>{t('rg_issuer')}</span>
              <span className='text-body-sm text-neutralContent-secondary'>
                {patientData?.document_agency ? patientData?.document_agency : t('not_informed')}
              </span>
            </div>

            <div className='flex flex-col gap-1'>
              <span className='text-label-md'>{t('issuer_date')}</span>
              <span className='text-body-sm text-neutralContent-secondary'>
                {patientData?.document_date
                  ? format(new Date(patientData?.document_date), 'dd/MM/yyyy')
                  : t('not_informed')}
              </span>
            </div>
          </BlankCard>
        </div>

        <div className=''>
          <span className='text-heading-sm'>{t('address')}</span>
          <BlankCard className='mt-3 flex flex-col gap-4'>
            <div className='flex flex-col gap-1'>
              <span className='text-label-md'>{t('address_complete')}</span>
              <span className='text-body-sm text-neutralContent-secondary'>{formatAddress()}</span>
            </div>
          </BlankCard>
        </div>
      </div>
    </>
  )
}

export default PersonalData
