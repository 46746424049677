import interactionPlugin from '@fullcalendar/interaction'
import ptBrLocale from '@fullcalendar/core/locales/pt-br'
import { EventImpl } from '@fullcalendar/core/internal'
import timeGridPlugin from '@fullcalendar/timegrid'
import FullCalendar from '@fullcalendar/react'
import { useTranslation } from 'react-i18next'
import Radio from '../../radio'
import {
  EventInput,
  EventClickArg,
  DidMountHandler,
  DayCellMountArg,
  EventContentArg,
  DayHeaderContentArg,
  CustomContentGenerator
} from '@fullcalendar/core'

type Props = {
  events: EventInput[]
  eventSelected?: EventImpl
  allowEventSelect?: boolean
  onEventClick: (event: EventClickArg) => void
}

const WeekCalendar: React.FC<Props> = ({
  events,
  onEventClick,
  eventSelected,
  allowEventSelect
}) => {
  const { t } = useTranslation('calendar')

  const headerContent: CustomContentGenerator<DayHeaderContentArg> = header => {
    return (
      <div className='px-2 py-4'>
        <div
          className={`text-body-lg text-neutralContent-primary ${header.isToday && 'flex h-8 w-8 items-center justify-center rounded-full bg-primaryBrand-primary p-0 text-white'}`}
        >
          {header.date.getUTCDate()}
        </div>
        <div className='text-body-sm text-neutralContent-tertiary'>
          {t(`week_day.${header.dow}`)}
        </div>
      </div>
    )
  }

  const eventContent: CustomContentGenerator<EventContentArg> = ({ event, timeText }) => {
    if (allowEventSelect) {
      const isSelected =
        event.id === eventSelected?.id && event.start?.getDate() === eventSelected?.start?.getDate()

      return (
        <div
          className={`flex h-full w-full flex-wrap items-center  justify-between overflow-hidden rounded-xl  px-2 py-1 transition-all ${isSelected ? 'cursor-default bg-primaryBrand-light text-neutralBorder-brand' : 'cursor-pointer bg-neutralBackground-disabled text-neutralContent-tertiary hover:bg-neutralBackground-tertiary'}`}
        >
          <div className='text-label-xs'>{timeText}</div>
          <Radio selected={isSelected} size='sm' state='enabled' />
        </div>
      )
    }

    return (
      <div
        className={`flex h-full w-full cursor-pointer flex-col flex-wrap overflow-hidden  rounded-xl bg-neutralBackground-disabled p-2 text-neutralContent-tertiary transition-all hover:bg-neutralBackground-tertiary`}
      >
        <div className='text-label-xs'>{timeText}</div>
        <div className='text-body-xs'>{event.title}</div>
      </div>
    )
  }

  const handleDayCellDidMount: DidMountHandler<DayCellMountArg> = arg => {
    if (arg.isToday) {
      arg.el.classList.add('bg-primaryBrand-light')
    }
  }

  return (
    <FullCalendar
      height={1024}
      nowIndicator
      events={events}
      allDaySlot={false}
      selectable={false}
      locale={ptBrLocale}
      eventClick={onEventClick}
      initialView='timeGridWeek'
      slotMinTime={{ hour: 6 }}
      slotMaxTime={{ hour: 23 }}
      slotDuration={{ minutes: 30 }}
      timeZone='America/Sao_Paulo'
      dayHeaderContent={headerContent}
      dayCellDidMount={handleDayCellDidMount}
      plugins={[interactionPlugin, timeGridPlugin]}
      viewClassNames={'border rounded-b-3xl overflow-hidden'}
      eventContent={eventContent}
      eventClassNames='bg-transparent border-none p-0 m-0'
      slotLabelFormat={{
        hour: 'numeric',
        minute: 'numeric',
        hourCycle: 'h23'
      }}
      headerToolbar={{
        left: 'prev,title,next',
        center: '',
        right: 'today'
      }}
    />
  )
}
export default WeekCalendar
