import { useEffect, useState, FocusEvent } from 'react'
import InputSkeleton from '../input/skeleton'
import { UnitType } from '../../types'
import Dropdown from '.'
import { useUnitTypes } from '../../hooks/useUnitType'

type Props = {
  label: string
  name: string
  placeholder?: string
  disabled?: boolean
  hint?: string
  value: string
  onChange: (unit?: UnitType) => void
  onBlur: (e: FocusEvent<unknown, Element>) => void
  error?: boolean
}

const UnitTypeSelect: React.FC<Props> = ({ onChange, ...props }) => {
  const [hasMore, setHasMore] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [options, setOptions] = useState<{ value: string; label: string }[]>([])
  const [searchTerm, setSearchTerm] = useState('')

  const { data: unitTypes, isLoading } = useUnitTypes(currentPage, 10, searchTerm)

  useEffect(() => {
    if (unitTypes && unitTypes.data) {
      setOptions(current => [
        ...(unitTypes.page >= 1 ? current : []),
        ...unitTypes.data.map((unit: UnitType) => ({
          value: unit.id || '',
          label: unit.name || ''
        }))
      ])
      setHasMore(unitTypes.totalItems > (unitTypes.page + 1) * unitTypes.pageSize)
    }
  }, [unitTypes])

  const loadMoreOptions = () => {
    if (hasMore) {
      setCurrentPage(prev => prev + 1)
    }
  }

  const handleInputChange = (inputValue: string) => {
    setSearchTerm(inputValue)
    setCurrentPage(0)
  }

  const handleSelect = (option: { value: string; label: string } | null) => {
    if (!option) onChange(undefined)

    const unit = unitTypes?.data.find(item => item.id === option?.value)
    onChange(unit)
  }

  if (isLoading) return <InputSkeleton showLabel className='flex-1' />

  return (
    <Dropdown
      options={options}
      onChangeValue={handleSelect}
      loadMoreOptions={loadMoreOptions}
      onInputChange={handleInputChange}
      {...props}
    />
  )
}

export default UnitTypeSelect
