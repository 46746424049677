import Checkbox from '../checkbox'
import React from 'react'
import { TableRowProps } from './types'

const renderCell = (cellData: React.ReactNode | (() => React.ReactNode)) => {
  return typeof cellData === 'function' ? cellData() : cellData
}

export const TableRow: React.FC<TableRowProps> = ({
  row,
  isSelected,
  columns,
  selectable,
  onClickRow,
  handleSelectRow
}) => {
  return (
    <div
      onClick={onClickRow ? () => onClickRow(row) : undefined}
      className={`flex flex-col flex-wrap items-center gap-4 rounded-lg border p-4 transition-all sm:flex-row sm:items-center sm:gap-0 sm:rounded-none sm:border-x-0 sm:border-t-0 sm:p-0 ${
        onClickRow && 'cursor-pointer hover:bg-gray-50'
      }`}
    >
      {selectable && (
        <div className='w-[40px] p-4'>
          <Checkbox isChecked={!!isSelected} onClick={() => handleSelectRow?.(row)} />
        </div>
      )}
      {columns.map(column => (
        <div
          key={row.id + '_' + column.accessor}
          className={`flex w-full flex-1 flex-col overflow-hidden text-label-sm text-neutralContent-primary sm:flex-row sm:p-4 ${column.className}`}
        >
          {column.header && (
            <label className='mb-1 text-label-sm font-semibold text-neutralContent-tertiary sm:hidden'>
              {column.header}
            </label>
          )}
          {renderCell(row[column.accessor])}
        </div>
      ))}
    </div>
  )
}
