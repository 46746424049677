const agendav2_list_enUS = {
  title: 'Schedules',

  search_placeholder: 'Search for a client',
  empty_scale: 'No schedule registered for this day',

  without_specialist: 'No specialist defined',
  without_units: 'No units defined',
  new_scale: 'New Schedule',
  select_a_specialty: 'Select a specialty',
  start: 'Start',
  end: 'End',
  status: {
    DRAFT: 'Draft',
    PUBLISHED: 'Published'
  },
  has_specialist: 'Has specialist',
  specialist_pending: 'Specialist pending',
  actions: {
    access_full_scale: 'Access full schedule',
    new_scale: 'New Schedule',
    update_scale: 'Save',
    select: 'Select',
    create_scale: 'Create Schedule',
    close: 'Close',
    update: 'Edit',
    delete: 'Delete'
  }
}

export default agendav2_list_enUS
