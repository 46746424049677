const consultation_follow_up_enUS = {
  title_pending: 'Pending Consultations',
  title_in_progress: 'Consultations In Progress',

  search_input_placeholder: 'Search',

  contact_info: 'Contact Information',
  principal_email: 'Primary Email',
  cell_number: 'Cell Phone Number',
  no_email: 'Email not provided',
  no_phone: 'Phone not provided',

  modal_consultation_detail: {
    consultation_title: 'Consultation #{{code}}',
    detail: 'Details',
    participants: 'Participants',
    toast: {
      success: 'Consultation successfully completed',
      error: 'Error finishing consultation'
    }
  },

  in_progress: 'In progress',
  in_waiting: ' in waiting',
  start_in: 'Started ',
  start_in_waiting: 'Starts in ',

  date_not_available: 'Date not available',
  actions: {
    full_screen: 'Full Screen',
    clear_filters: 'Clear All',
    finish_consultation: 'Finish Consultation',
    send_whatsapp: 'Send WhatsApp'
  },
  types: {
    chat: 'Chat',
    elective_care: 'Elective',
    emergency_care: 'Emergency Care'
  },
  person: {
    requester: 'Requester',
    specialist: 'Specialist',
    patient: 'Patient'
  }
}

export default consultation_follow_up_enUS
