/* eslint-disable react-hooks/exhaustive-deps */

import { useTranslation } from 'react-i18next'
import Content from '../../../components/content'
import ContentForm from '../../../components/content/form'
import ClientSelect from '../../../components/dropdown/client-dropdown'
import UnitSelect from '../../../components/dropdown/unit-dropdown'
import { useAnamnesisTemplate } from '../../../hooks/forms/useAnamnesisForm'
import UnitSpecialtiesSelect from '../../../components/dropdown/unit-specialty-dropdown'
import Button from '../../../components/button'
import { useEffect, useState } from 'react'
import QuestionModal from './question-modal'
import QuestionPreview from './question-preview'
import ContentFooter from '../../../components/content/footer'
import { Eye } from '@phosphor-icons/react'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../../routes/path'
import { QuestionOption } from '../../../types'
import AnamnesisTemplatePreview from './preview'
import { useGetAnamnesisTemplate } from '../../../hooks/useAnamnesis'
import { v4 as uuid } from 'uuid'

const AnamnesisForm: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const formik = useAnamnesisTemplate(id)
  const { t } = useTranslation('anamnesis_form')
  const [showPreview, setShowPreview] = useState(false)

  const { data } = useGetAnamnesisTemplate(id)

  const [isOpenModal, setIsOpenModal] = useState(false)

  const [questionToEdit, setQuestionToEdit] = useState<{
    index: number
    question: QuestionOption
  }>()

  const handleCloseModal = () => {
    setIsOpenModal(false)
    setQuestionToEdit(undefined)
  }

  const handleAddQuestion = async (newQuestion: QuestionOption) => {
    if (!!questionToEdit) {
      const updatedQuestions = [...formik.values.questions!]
      updatedQuestions[questionToEdit.index] = newQuestion
      await formik.setFieldValue('questions', updatedQuestions)
    } else {
      await formik.setFieldValue('questions', [
        ...formik.values.questions!,
        { ...newQuestion, id: uuid() }
      ])
    }

    handleCloseModal()
  }

  const moveQuestionUp = async (index: number) => {
    if (index > 0) {
      const questions = [...formik.values.questions!]
      const temp = questions[index - 1]

      questions[index - 1] = questions[index]
      questions[index] = temp

      await formik.setFieldValue('questions', questions)
    }
  }

  const moveQuestionDown = async (index: number) => {
    if (formik.values.questions && index < formik.values.questions.length - 1) {
      const questions = [...formik.values.questions]
      const temp = questions[index + 1]

      questions[index + 1] = questions[index]
      questions[index] = temp

      await formik.setFieldValue('questions', questions)
    }
  }

  const handleEditQuestion = (index: number) => {
    const question = formik.values.questions![index]
    setQuestionToEdit({ index, question })
    setIsOpenModal(true)
  }

  const handleDeleteQuestion = async (index: number) => {
    const questions = formik.values.questions?.filter((_, i) => i !== index)
    await formik.setFieldValue('questions', questions)
  }

  const handlePreviewTemplate = () => {
    setShowPreview(true)
  }

  useEffect(() => {
    if (data) formik.setValues(data)
  }, [data])

  return !showPreview ? (
    <Content
      title={t('title')}
      onBack={() => navigate(ROUTES.anamnesis_template.list)}
      rightContent={
        <Button
          size='sm'
          variant='secondary'
          iconLeft={<Eye size={22} />}
          onClick={handlePreviewTemplate}
          label={t('actions.preview_template')}
        />
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <ContentForm hasBackButton>
          <div className='col-span-2 flex gap-4'>
            <ClientSelect
              name='client_id'
              label={t('fields.client')}
              onBlur={formik.handleBlur}
              value={formik.values.client_id || ''}
              placeholder={t('actions.select_an_option')}
              error={Boolean(formik.touched.client_id && formik.errors.client_id)}
              onChange={client => {
                formik.setFieldValue('client_id', client ? client.id : '')
              }}
              hint={
                formik.touched.client_id && formik.errors.client_id
                  ? formik.errors.client_id
                  : undefined
              }
            />
            <UnitSelect
              name='unit_id'
              label={t('fields.unit')}
              onBlur={formik.handleBlur}
              disabled={!formik.values.client_id}
              value={formik.values.unit_id || ''}
              client_id={formik.values?.client_id}
              placeholder={t('actions.select_an_option')}
              error={Boolean(formik.touched.unit_id && formik.errors.unit_id)}
              onChange={unit => {
                formik.setFieldValue('unit_id', unit ? unit.id : '')
              }}
              hint={
                formik.touched.unit_id && formik.errors.unit_id ? formik.errors.unit_id : undefined
              }
            />
          </div>
          <div className='col-span-2'>
            <UnitSpecialtiesSelect
              name='specialty_id'
              onBlur={formik.handleBlur}
              label={t('fields.specialty')}
              unit_id={formik.values.unit_id}
              value={formik.values.specialty_id || ''}
              placeholder={t('actions.select_an_option')}
              error={Boolean(formik.touched.specialty_id && formik.errors.specialty_id)}
              onChange={specialty => {
                formik.setFieldValue('specialty_id', specialty ? specialty.id : '')
              }}
              hint={
                formik.touched.specialty_id && formik.errors.specialty_id
                  ? formik.errors.specialty_id
                  : undefined
              }
            />
          </div>
          <div className='col-span-2'>
            {formik.values.questions?.map((question, index) => (
              <QuestionPreview
                key={index}
                options={question.options}
                title={question.title || ''}
                onMoveUp={() => moveQuestionUp(index)}
                onEdit={() => handleEditQuestion(index)}
                onMoveDown={() => moveQuestionDown(index)}
                onDelete={() => handleDeleteQuestion(index)}
                type={question.type as 'textarea' | 'checkbox' | 'radio' | 'dropdown'}
              />
            ))}
          </div>
          <div className='col-span-2'>
            <Button
              type='button'
              variant='secondary'
              label={t('actions.add')}
              onClick={() => setIsOpenModal(true)}
            />
          </div>
          <ContentFooter className='flex justify-end'>
            <Button type='submit' label={t('actions.save')} variant='primary' />
          </ContentFooter>
        </ContentForm>
      </form>

      <QuestionModal
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        onAddQuestion={handleAddQuestion}
        questionToEdit={questionToEdit?.question}
      />
    </Content>
  ) : (
    <AnamnesisTemplatePreview template={formik.values} onBack={() => setShowPreview(false)} />
  )
}

export default AnamnesisForm
