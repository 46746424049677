import Radio from '../../../../../components/radio'
import { AnyQuestionProps } from '../any-questions'

export const RadioQuestion: React.FC<AnyQuestionProps> = ({
  handleValue,
  question,
  values,
  readonly
}) => {
  if (readonly) {
    return <>{values[question.id!] || '-'}</>
  }

  return (
    <div className='mt-2 flex'>
      {question.options?.map((option, index) => (
        <div key={index} className='flex items-center gap-2 px-2'>
          <Radio
            size='md'
            state='enabled'
            selected={values[question.id!] === option}
            onChange={() => handleValue({ ...values, [question.id!]: option })}
          />
          <span className='text-label-md font-bold'>{option}</span>
        </div>
      ))}
    </div>
  )
}
