const consultation_list_enUS = {
  title_elective_care: 'Elective Consultations',
  title_emergency_care: 'Emergency Consultations',
  title_chat: 'Chat Consultations',
  subtitle: 'View and schedule consultations with specialists',
  columns: {
    code: 'Code',
    date: 'Date',
    patient: 'Patient',
    specialty: 'Specialty',
    consultation: 'Consultation',
    requester: 'Requester',
    created_by: 'Created by',
    specialist: 'Specialist',
    consultation_type: {
      elective_care: 'Elective',
      emergency_care: 'Emergency'
    },
    status: 'Status',
    status_type: {
      SCHEDULED: 'Scheduled',
      WAITING: 'Waiting',
      FINISHED: 'Finished',
      IN_ROOM: 'In Progress'
    },
    unit: 'Unit'
  },
  actions: {
    new_consultation: 'New Consultation',
    transfer: 'Transfer Consultations'
  }
}

export default consultation_list_enUS
