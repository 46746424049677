/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import MonthSelectCalendar from '../../../components/custom-week-calendar/month-select-calendar'
import CustomWeekCalendar from '../../../components/custom-week-calendar'
import PublishScale from '../scale_day/scale-list/modals/publish-scale'
import { useListScalesByClient } from '../../../hooks/useScale'
import { ArrowSquareOut, Plus } from '@phosphor-icons/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetClient } from '../../../hooks/useClient'
import BlankCard from '../../../components/blank-card'
import Content from '../../../components/content'
import FullScale from './full-scale'
import Avatar from '../../../components/avatar'
import Button from '../../../components/button'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../../routes/path'
import { useMemo, useState } from 'react'
import { Scale } from '../../../types'
import {
  addDays,
  addMonths,
  endOfWeek,
  startOfWeek,
  startOfMonth,
  isWithinInterval,
  format,
  subMonths,
  endOfMonth
} from 'date-fns'
import Tag from '../../../components/tag'
import DatePicker from '../../../components/datepicker'
import SegmentedButton from '../../../components/segmented-button'
import ConfirmPublishMultipleScaleModal from './modals/publish-multiple-scales'

export const filtersMonthOrWeek = [
  { label: 'Semanal', value: 'week' },
  { label: 'Mensal', value: 'month' }
]

const CalendarPage: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('agendav2_list')
  const { client_id } = useParams<{ client_id: string }>()
  const { data: clientData } = useGetClient(client_id || '')

  const [openPublishModal, setOpenPublishModal] = useState({
    isOpen: false,
    scale: {} as Scale
  })

  const [isOpenPublishMultipleScalesModal, setIsOpenPublishMultipleScalesModal] = useState(false)

  const [lastDayOfTheWeek, setLastDayOfTheWeek] = useState(
    endOfWeek(new Date(), { weekStartsOn: 1 })
  )
  const [selectedView, setSelectedView] = useState<'week' | 'month'>('week')
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [currentMonth, setCurrentMonth] = useState(new Date())

  const {
    data: scales,
    isFetching,
    refetch: reloadScales
  } = useListScalesByClient({
    client_id,
    end_date: format(
      selectedView === 'week' ? lastDayOfTheWeek : endOfMonth(currentMonth),
      'yyyy-MM-dd'
    ),
    start_date: format(
      selectedView === 'week'
        ? addDays(selectedDate || lastDayOfTheWeek, -7)
        : startOfMonth(currentMonth),
      'yyyy-MM-dd'
    )
  })

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date)
      setLastDayOfTheWeek(endOfWeek(date, { weekStartsOn: 1 }))

      setCurrentMonth(startOfMonth(date))
    }
  }

  const handleViewChange = (index: number) => {
    setSelectedView(filtersMonthOrWeek[index].value as 'week' | 'month')
  }

  const isCurrentWeek = useMemo(() => {
    return isWithinInterval(new Date(), {
      start: startOfWeek(lastDayOfTheWeek, { weekStartsOn: 1 }),
      end: lastDayOfTheWeek
    })
  }, [lastDayOfTheWeek])

  const handleBack = () => {
    navigate(ROUTES.agenda.list)
  }

  const goToPreviousMonth = () => {
    const newDate = addMonths(selectedDate, -1)
    setSelectedDate(newDate)
    setLastDayOfTheWeek(endOfWeek(newDate, { weekStartsOn: 1 }))

    setCurrentMonth(subMonths(currentMonth, 1))
  }

  const goToNextMonth = () => {
    const newDate = addMonths(selectedDate, 1)
    setSelectedDate(newDate)
    setLastDayOfTheWeek(endOfWeek(newDate, { weekStartsOn: 1 }))

    setCurrentMonth(addMonths(currentMonth, 1))
  }

  const goToCurrentWeek = (e?: React.MouseEvent) => {
    e?.preventDefault()
    const today = new Date()
    setSelectedDate(today)
    setLastDayOfTheWeek(endOfWeek(today, { weekStartsOn: 1 }))
  }

  const goToDayList = (date: Date) => {
    navigate(
      `${ROUTES.agenda.scale_day.replace(':client_id', client_id!)}?date=${date.toISOString()}`
    )
  }

  const handleNewScale = () => {
    navigate(`${ROUTES.agenda.new_scale.replace(':client_id', client_id!)}`)
  }

  const rightHeaderContent = () => (
    <div className='flex items-center gap-2'>
      <SegmentedButton
        isLoading={isFetching}
        labels={filtersMonthOrWeek.map(filter => filter.label)}
        onChange={handleViewChange}
        selectedIndex={selectedView === 'week' ? 0 : 1}
      />
      <Button
        size='sm'
        variant='secondary'
        iconRight={<ArrowSquareOut />}
        label={t('actions.publish_scales')}
        onClick={() => setIsOpenPublishMultipleScalesModal(true)}
        state={
          scales?.length && scales.find(scale => scale.status === 'DRAFT') ? 'enable' : 'disable'
        }
      />
      <Button
        size='sm'
        iconLeft={<Plus />}
        label={t('actions.new_scale')}
        onClick={() => handleNewScale()}
      />
    </div>
  )

  const handleEditScale = (scale: Scale) => {
    navigate(
      ROUTES.agenda.scale_form.replace(':client_id', client_id!).replace(':scale_id', scale?.id)
    )
  }

  return (
    <Content onBack={handleBack} rightContent={rightHeaderContent()}>
      <div className='p-4'>
        <div className='mb-12 flex items-center justify-between'>
          <div className='flex items-center gap-6'>
            <MonthSelectCalendar
              date={lastDayOfTheWeek}
              onClickNextMonth={goToNextMonth}
              onClickPreviousMonth={goToPreviousMonth}
            />

            <Button
              label='Hoje'
              state={!isCurrentWeek ? 'enable' : 'disable'}
              onClick={!isCurrentWeek ? goToCurrentWeek : undefined}
            />

            <div className='w-1/4'>
              <DatePicker
                name={'date'}
                value={selectedDate}
                onChange={handleDateChange}
                showClearIcon={false}
              />
            </div>
          </div>

          <BlankCard className='h-14'>
            <div className='mt-[-6px] flex items-center justify-center gap-3'>
              <Avatar name={clientData?.name} size='sm' />
              <span className='text-label-md text-neutralContent-primary'>
                {clientData?.name || ''}
              </span>
            </div>
          </BlankCard>
        </div>
        <div className='mb-4 flex items-center gap-2'>
          <Tag size='md' label={t('has_specialist')} color='blue' variant='primary' />
          <Tag size='md' label={t('specialist_pending')} color='orange' variant='primary' />
        </div>
        {/* ESCALA SEMANAL */}
        {selectedView === 'week' ? (
          <CustomWeekCalendar
            data={scales || []}
            isLoading={isFetching}
            handleClickDay={goToDayList}
            lastDayOfTheWeek={lastDayOfTheWeek}
            onChangeLastDayOfTheWeek={setLastDayOfTheWeek}
            handleClickCard={scale => setOpenPublishModal({ scale, isOpen: true })}
            selectedDate={selectedDate}
            onChangeSelectedDate={setSelectedDate}
          />
        ) : (
          <FullScale
            isFetching={isFetching}
            scales={scales || []}
            currentMonth={currentMonth}
            handleClickCard={scale => setOpenPublishModal({ scale, isOpen: true })}
          />
        )}
      </div>

      <PublishScale
        scale={openPublishModal.scale}
        isOpen={openPublishModal.isOpen}
        redirectToEdit={(scale: Scale) => handleEditScale(scale)}
        onClose={() => {
          setOpenPublishModal({
            isOpen: false,
            scale: {} as Scale
          })
          if (selectedView === 'week') reloadScales()
        }}
      />

      <ConfirmPublishMultipleScaleModal
        open={isOpenPublishMultipleScalesModal}
        onClose={() => setIsOpenPublishMultipleScalesModal(false)}
        scales={scales || []}
        onComplete={() => reloadScales()}
      />
    </Content>
  )
}

export default CalendarPage
