const agenda_form_enUS = {
  choose_agenda_type: 'What type of schedule do you want to create?',
  fixed: {
    title: 'Fixed Schedule',
    description: `Choose the days of the week and fixed times when the professional will be available for appointments.<br /><br />Ideal for professionals who maintain a regular weekly appointment routine`
  },
  custom: {
    title: 'Custom Schedule',
    description:
      'Choose specific days and times, without following a fixed routine, when the professional will be available for appointments.<br /><br />Ideal for professionals with variable appointment hours that change from week to week'
  },
  event_form: {
    from: 'From',
    until: 'To',
    unit: 'Unit',
    select: 'Select',
    duration: 'Appointment Duration',
    empty: '‎'
  },
  fixed_form: {
    title: 'Create Fixed Schedule',
    subtitle:
      'Choose the days of the week and fixed times when the professional will be available for appointments',
    accordion_tag: '{{start}} to {{end}} | {{unit}}',
    fields: {
      professional: 'Professional',
      period_label: 'Appointment Period',
      empty: '‎',
      period_start: 'Start Date',
      period_end: 'End Date',
      title_availability: 'Define Appointment Availability',
      subtitle_availability:
        'Days and times will be made available for scheduling according to the defined configuration',
      add_period: 'Add Period'
    }
  },
  custom_form: {
    title: 'Create Custom Schedule',
    subtitle:
      'Choose specific days and times, without following a fixed routine, when the professional will be available for appointments',
    modal: {
      title: 'Available Period on {{date}}',
      alert:
        'The professional will only be available for appointments at this unit during the defined period.'
    }
  },
  actions: {
    select_an_option: 'Select an option',
    create_agenda: 'Create Schedule',
    save: 'Save',
    cancel: 'Cancel',
    select: 'Select'
  },
  days_of_week: {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    0: 'Sunday'
  }
}

export default agenda_form_enUS
